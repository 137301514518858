import React from "react";
import { Table, Layout, Space, Tag } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { ServiceTag } from "../../components/ServiceTag";

const { Content } = Layout;

export const AdminArchived = () => {
    const history = useHistory();
    const firestore = useFirestore();

    const ordersRef = useFirestore().collectionGroup("orders");

    const orderQuery = ordersRef
        .where("isArchived", "==", true)
        .orderBy("dateOrdered", "desc");

    const { data: ordersData, status: ordersStatus } =
        useFirestoreCollectionData(orderQuery, {
            idField: "id",
        });

    const archiveOrder = async (data) => {
        if (window.confirm("Unarchive order?"))
            firestore
                .collection("users")
                .doc(data.orderedBy_id)
                .collection("orders")
                .doc(data.id)
                .update({
                    isArchived: false,
                });
    };
    const deleteOrder = async (data) => {
        if (window.confirm("Delete order?"))
            firestore
                .collection("users")
                .doc(data.orderedBy_id)
                .collection("orders")
                .doc(data.id)
                .delete();
    };

    const redirect = (service) => {
        if (service === "samplesForwarding") return "samples-forwarding";
        if (service === "preShipmentInspection")
            return "pre-shipment-inspection";
        if (service === "sourcing") return "sourcing";
        return "shipping";
    };

    const tag = (tag, record) => (
        <ServiceTag tag={tag} service={record.serviceType} />
    );
    const renderText = (text, record) => (
        <Link
            to={{
                pathname: `/admin/${redirect(record.serviceType)}/${
                    record.order_id
                }`,
                state: { record },
            }}
        >
            {text}
        </Link>
        // <a
        //   onClick={() =>
        //     history.push(
        //       `/admin/${redirect(record.serviceType)}/${record.order_id}`,
        //       state: { record }
        //     )
        //   }
        // >
        //   {text}
        // </a>
    );

    const renderName = (text, record) => (
        <Link
            to={{
                pathname: `/admin/users/${record.orderedBy_id}`,
            }}
        >
            {text}
        </Link>
        // <a onClick={() => history.push(`/admin/${redirect()}/${record.id}`)}>
        //   {text}
        // </a>
    );
    const actions = (text, record) => (
        <Space>
            <InboxOutlined
                onClick={() => archiveOrder(text)}
                style={{ fontSize: "24px" }}
            />
            <DeleteOutlined
                onClick={() => deleteOrder(text)}
                style={{ fontSize: "22px" }}
            />
        </Space>
    );
    // const renderText = (text) => <a>{text}</a>;
    const date = (date) => <a>{date.toDate().toDateString()}</a>;
    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            render: renderText,
        },
        {
            title: "Customer Name",
            dataIndex: "orderedBy_name",
            render: renderName,
        },

        {
            title: "Date Ordered",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Status",
            dataIndex: "orderStatus",
            render: tag,
        },
        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];
    return (
        <div
            className="samples-layout"
            style={{
                padding: 24,
                minHeight: 360,
            }}
        >
            <Content>
                <Table
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onClick: (event) => {
                    //       history.push("/admin/samples-forwarding/orderid");
                    //     }, // click row
                    //   };
                    // }}
                    bordered
                    columns={columns}
                    dataSource={ordersData}
                    loading={ordersStatus === "loading" ? true : false}
                />
            </Content>
        </div>
    );
};
