import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FirebaseAppProvider } from "reactfire";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA3biRSkoiw5OmhpStAMheErpwx3-Lwqm4",
  authDomain: "b2c-system.firebaseapp.com",
  projectId: "b2c-system",
  storageBucket: "b2c-system.appspot.com",
  messagingSenderId: "1094827117219",
  appId: "1:1094827117219:web:aaf216485b8fed9ac0cd16",
  measurementId: "G-40TLNC0097",
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
