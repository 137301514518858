import React, { useState } from "react";
import {
    Layout,
    Descriptions,
    Card,
    Spin,
    Button,
    Row,
    Col,
    Modal,
    Form,
    Upload,
    Space,
    DatePicker,
    Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useStorage,
} from "reactfire";
import {
    AdminInvoiceTable,
    AdminInvoicePaymentsTable,
    AdminInvoiceForm,
    UserInfoCard,
} from "./components";
import { UploadOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { UserContext } from "../../context/authContext";
import CommentsSection from "../../components/CommentsSection";

const { Content } = Layout;

const tag = (tag) => (
    <Tag
        color={
            tag === "quotationPending"
                ? "volcano"
                : tag === "quotationIssued"
                ? "yellow"
                : tag === "proceed"
                ? "processing"
                : tag === "invoiceIssued"
                ? "gold"
                : tag === "invoicePaid"
                ? "green"
                : "processing"
        }
    >
        {tag === "quotationPending"
            ? "Quotation Pending"
            : tag === "quotationIssued"
            ? "Quotation Issued"
            : tag === "invoiceIssued"
            ? "Invoice(s) Issued"
            : tag === "invoicePaid"
            ? "Invoice(s) Paid"
            : "Shipping Scheduled"}
    </Tag>
);

export const AdminSourcing = () => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record.orderedBy_id;
    const [editData, setEditData] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [loading, setLoading] = useState();
    const [uploads, setUploads] = useState([]);
    const fieldValue = useFirestore.FieldValue;
    const firestore = useFirestore();
    const storage = useStorage();
    const { user } = useContext(UserContext);

    const orderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd");
    const { data: orderData, status: orderStatus } = useFirestoreDocData(
        orderRef,
        {
            idField: "id",
        },
    );
    const orderDetails = orderData?.orderDetails;

    const userInvoiceRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const userOrderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid);

    const paymentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd")
        .collection("payments");

    const adminRef = useFirestore().collection("admins").doc(user.uid);

    const commentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("comments");

    const { data: commentsData, status: commentsStatus } =
        useFirestoreCollectionData(commentsRef.orderBy("timeStamp", "asc"), {
            idField: "id",
        });

    const { data: adminData, status: adminStatus } = useFirestoreDocDataOnce(
        adminRef,
        {
            idField: "id",
        },
    );

    const { data: invoiceData, status: invoiceStatus } =
        useFirestoreCollectionData(userInvoiceRef, {
            idField: "id",
        });

    const { data: paymentsData, status: paymentsStatus } =
        useFirestoreCollectionData(paymentsRef, {
            idField: "id",
        });

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleUpload = (e) => {
        setUploads(e.file.originFileObj);
    };

    const uploadFile = async (e) => {
        setLoading(true);

        let downloadURL = "";
        let fileName = Date.now().toString() + "_" + uploads.name;

        await storage
            .ref("fileUploads/" + fileName)
            .put(uploads)
            .then((snapshot) => {
                downloadURL = snapshot.ref.getDownloadURL();
                // return snapshot.ref.getDownloadURL();
            })

            .catch((error) => {
                console.log(error);
            });
        return downloadURL;
    };

    if (paymentsStatus === "loading" || invoiceStatus === "loading") {
        return (
            <div className="loader">
                <Spin></Spin>
            </div>
        );
    }

    const confirmPayment = async (data) => {
        await userInvoiceRef.doc(data.id).update({
            paymentStatus: "paid",
        });

        await userOrderRef.update({
            orderStatus: "invoicePaid",
        });
    };

    const deleteInvoice = async (data) => {
        await userInvoiceRef.doc(data.id).delete();
    };

    const issueQuotationFile = async (data) => {
        const url = await uploadFile();
        // await setLoading(true);
        await userOrderRef.set(
            {
                orderStatus: "quotationIssued",
                quotationFile: url,
                quotationFileName: uploads.name,
            },
            { merge: true },
        );
        await window.alert("Quotation File Issued!");
        await form.resetFields();
        await setLoading(false);
    };

    const issueDates = async (data) => {
        const etd = data.etd.toDate().toString();
        const eta = data.eta.toDate().toString();
        await setLoading(true);
        await userOrderRef.set(
            {
                orderStatus: "shippingScheduled",
                shippingDetails: {
                    etd: etd,
                    eta: eta,
                },
            },
            { merge: true },
        );
        await window.alert("ETD and ETA issued!");
        await form.resetFields();
        await setLoading(false);
    };

    return (
        <>
            <Content>
                <UserInfoCard orderData={orderData} tag={tag} />

                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 0 }}
                    title={"Order Details"}
                >
                    <Descriptions bordered>
                        <Descriptions.Item label="Product Name">
                            {orderDetails?.productName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Photo(optional)">
                            <a
                                href={orderData?.pictureLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {orderData?.pictureFilename}
                            </a>
                        </Descriptions.Item>

                        <Descriptions.Item label="Customize-detailed sketch diagram">
                            <a
                                href={orderData?.sketchLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {orderData?.sketchFilename}
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label=" Order Quantity">
                            {orderDetails?.orderQuantity}
                        </Descriptions.Item>
                        <Descriptions.Item label="Customize Requirements">
                            {orderDetails?.customizeRequirements}
                        </Descriptions.Item>
                        <Descriptions.Item label="Reference Listing Link">
                            {orderDetails?.referenceListingLink}
                        </Descriptions.Item>
                        <Descriptions.Item label="Others">
                            {orderDetails?.others}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col
                        style={{ marginTop: "20px" }}
                        span={12}
                        sm={24}
                        xl={24}
                        xxl={24}
                    >
                        <Card
                            title="Invoice Details"
                            bodyStyle={{ padding: "0" }}
                            bordered
                            extra={
                                <Button
                                    onClick={() => {
                                        setIsModalVisible(true);
                                        setEditStatus(false);
                                    }}
                                >
                                    Add Invoice
                                </Button>
                            }
                        >
                            <AdminInvoiceTable
                                invoiceData={invoiceData}
                                setIsModalVisible={setIsModalVisible}
                                setEditStatus={setEditStatus}
                                setEditData={setEditData}
                                confirmPayment={confirmPayment}
                                deleteInvoice={deleteInvoice}
                            />
                        </Card>
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={24}>
                        <AdminInvoicePaymentsTable paymentData={paymentsData} />
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
                        <Card title={"Quotation File"} bordered>
                            <Form onFinish={issueQuotationFile} form={form}>
                                <Space>
                                    <Form.Item
                                        name={"quotationFile"}
                                        label="Issue Quotation File"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Upload onChange={handleUpload}>
                                            <Button icon={<UploadOutlined />}>
                                                Click to upload
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : false}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                            <div>
                                Quotation File:{" "}
                                <a
                                    onClick={() =>
                                        openNewTab(orderData?.quotationFile)
                                    }
                                >
                                    {orderData?.quotationFileName}
                                </a>
                            </div>
                        </Card>
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
                        <Card title={"Shipping Schedule"} bordered>
                            <Form onFinish={issueDates} form={form2}>
                                <Space>
                                    <Form.Item
                                        name={"etd"}
                                        label="ETD"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker />
                                    </Form.Item>

                                    <Form.Item
                                        name={"eta"}
                                        label="ETA"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : false}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                            <div>
                                ETD:{" "}
                                {orderData?.shippingDetails &&
                                    orderData?.shippingDetails.etd}
                            </div>
                            <div>
                                ETA:{" "}
                                {orderData?.shippingDetails &&
                                    orderData?.shippingDetails.eta}
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 20 }}
                    title={"Comments"}
                >
                    <CommentsSection
                        commentsData={commentsData}
                        orderData={orderData}
                        authorName={adminData?.name}
                    />
                </Card>
            </Content>

            <Modal
                title={editStatus ? "Edit Invoice" : "Add Invoice"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <AdminInvoiceForm
                    orderData={orderData}
                    orderid={orderid}
                    edit={editStatus}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
};
