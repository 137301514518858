import React, { useState } from "react";
import {
    Col,
    Space,
    Card,
    Row,
    Modal,
    InputNumber,
    Upload,
    Button,
    Form,
    Input,
    PageHeader,
    Breadcrumb,
    Layout,
    AutoComplete,
    Pagination as AntPagination,
} from "antd";
import {
    UploadOutlined,
    EditOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {
    useFirestore,
    useFirestoreCollectionData,
    useStorage,
} from "reactfire";
import {
    BrowserRouter as Router,
    Link,
    useParams,
    useHistory,
} from "react-router-dom";
import {
    InstantSearch,
    connectAutoComplete,
    Hits,
    Stats,
    Configure,
    Highlight,
    connectSearchBox,
    connectHits,
    Pagination,
} from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: "Vp6nlI6zyUXbCSO5VBXjZ8eMHHbzS5RA", // Be sure to use a Search API Key
        nodes: [
            {
                host: "1y5bmgxq69in3cszp-1.a1.typesense.net", // where xxx is the ClusterID of your Typesense Cloud cluster
                port: "443",
                protocol: "https",
            },
        ],
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
        queryBy: "name",
    },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const { Content } = Layout;
const { Meta } = Card;

export function AdminProducts(props) {
    const { categoryid, subcategoryid, subcategoryitemid } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState([]);
    const [edit, setEdit] = useState(false);
    const firestore = useFirestore();

    let productsRef = useFirestore().collection("products");

    // if (categoryid) {
    //   productsRef = firestore
    //     .collection("products")
    //     .where("category", "==", categoryid);
    // } else if (categoryid && subcategoryid) {
    //   productsRef = firestore
    //     .collection("products")
    //     .where("category", "==", subcategoryid);
    // }

    // const { data: productsData, status: productsStatus } =
    //   useFirestoreCollectionData(productsRef, {
    //     idField: "id",
    //   });

    const handleOk = () => {
        setIsModalVisible(false);
        setEdit(false);
        setEditData([]);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEdit(false);
        setEditData([]);
    };

    const checkCategory = (hit) => {
        if (categoryid && !subcategoryid) {
            if (hit.category === categoryid) return true;
        } else if (categoryid && subcategoryid && !subcategoryitemid) {
            if (hit.subcategory === subcategoryid) return true;
        } else if (categoryid && subcategoryid && subcategoryitemid) {
            if (hit.subcategorygroup === subcategoryitemid) return true;
        } else {
            return true;
        }
    };

    const Hits = ({ hits }) => (
        <>
            <Row gutter={[16, 16]}>
                {hits.filter(checkCategory).map((hit) => (
                    <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={8}
                        xxl={4}
                        span={3}
                        style={{ marginTop: 20 }}
                    >
                        <ProductCard
                            setEdit={setEdit}
                            setEditData={setEditData}
                            setIsModalVisible={setIsModalVisible}
                            product={hit}
                        />
                    </Col>
                ))}
            </Row>

            {/* <Row>
        <AntPagination />
      </Row> */}
        </>
    );

    const CustomHits = connectHits(Hits);

    const Search = ({ currentRefinement, iseSearchStalled, hits, refine }) => (
        <Input.Search
            onChange={(event) => {
                refine(event.currentTarget.value);
            }}
            size="large"
            placeholder="Search"
        ></Input.Search>
    );

    const Autocomplete = ({ hits, currentRefinement, refine }) => (
        <ul>
            <li>
                <input
                    type="search"
                    value={currentRefinement}
                    onChange={(event) => {
                        refine(event.currentTarget.value);
                    }}
                />
            </li>
            {hits.map((hit) => (
                <li key={hit.objectID}>{hit.name}</li>
            ))}
        </ul>
    );

    const CustomAutocomplete = connectAutoComplete(Autocomplete);

    const CustomSearchBox = connectSearchBox(Search);
    return (
        <>
            <InstantSearch searchClient={searchClient} indexName="products">
                <PageHeader
                    ghost={false}
                    title={
                        <Breadcrumb>
                            {categoryid ? (
                                <Breadcrumb.Item>
                                    {" "}
                                    <Link to={`/admin/products/${categoryid}`}>
                                        {" "}
                                        {categoryid}
                                    </Link>{" "}
                                </Breadcrumb.Item>
                            ) : (
                                <div> All Products</div>
                            )}
                            {subcategoryid ? (
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/admin/products/${categoryid}/${subcategoryid}`}
                                    >
                                        {subcategoryid}{" "}
                                    </Link>
                                </Breadcrumb.Item>
                            ) : (
                                <div />
                            )}
                            {subcategoryitemid ? (
                                <Link
                                    to={`/admin/products/${categoryid}/${subcategoryid}/${subcategoryitemid}`}
                                >
                                    {subcategoryitemid}{" "}
                                </Link>
                            ) : (
                                <div />
                            )}
                        </Breadcrumb>
                    }
                    extra={[
                        <Button
                            key="3"
                            onClick={() => {
                                setEdit(false);
                                setIsModalVisible(true);
                            }}
                        >
                            Add Product
                        </Button>,
                    ]}
                >
                    <div style={{ width: "30%" }}>
                        <CustomSearchBox />
                    </div>
                    {/* <CustomAutocomplete /> */}
                    {/* <Stats /> */}
                </PageHeader>
                <CustomHits />

                <Configure hitsPerPage={20} />
                {/* <Row>
          {" "}
          <Pagination />
        </Row> */}

                <Modal
                    title={edit ? "Edit Product" : "Add Product"}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AddProductForm
                        edit={edit ? true : false}
                        editData={editData}
                        setIsModalVisible={setIsModalVisible}
                    />
                </Modal>
            </InstantSearch>
        </>
    );
}

function ProductCard(props) {
    const { setEdit, setIsModalVisible, setEditData, product } = props;
    const history = useHistory();
    const firestore = useFirestore();
    const deleteProduct = (id) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
            firestore.collection("products").doc(id).delete();
        }
    };
    return (
        // <Link
        //   to={{
        //     pathname: `/admin/product/${product.name}`,
        //     state: { product },
        //   }}
        // >
        <Card
            onClick={() =>
                history.push({
                    pathname: `/admin/product/${product.name}`,
                    state: { product },
                })
            }
            style={{}}
            hoverable
            cover={
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        style={{
                            maxWidth: "250px",
                            height: "250px",
                            objectFit: "cover",
                        }}
                        alt="example"
                        src={product.img}
                    />
                </div>
            }
            actions={[
                <EditOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        setEdit(true);
                        setEditData(product);
                        setIsModalVisible(true);
                    }}
                    key="edit"
                />,
                <DeleteOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        deleteProduct(product.objectID);
                    }}
                    key="delete"
                />,
            ]}
        >
            <Meta
                title={<Highlight hit={product} attribute="name"></Highlight>}
                description={`$ ${product.price}`}
            ></Meta>
        </Card>
        // </Link>
    );
}
const validateMessages = {
    required: "${label} is required!",
};

function AddProductForm(props) {
    const { setIsModalVisible, edit, editData } = props;
    const [form] = Form.useForm();
    const storage = useStorage();
    const firestore = useFirestore();
    const timestamp = useFirestore.FieldValue.serverTimestamp();
    const [uploads, setUploads] = useState([]);
    const [loading, setLoading] = useState();

    form.resetFields();

    if (edit && editData) {
        form.setFieldsValue({
            name: editData.name,
            number: editData.number,
            price: editData.price,
            description: editData.description,
            category: editData.category,
            subcategory: editData.subcategory,
            subcategorygroup: editData.subcategorygroup,
        });
    }

    const onFinish = async (values) => {
        setLoading(true);
        const img = await uploadFile();

        try {
            if (edit) {
                await firestore
                    .collection("products")
                    .doc(editData.objectID)
                    .update(
                        {
                            ...values,
                            img: img,
                        },
                        { merge: true },
                    );
            } else {
                await firestore.collection("products").add({
                    ...values,
                    img: img,
                    dateAdded: timestamp,
                });
            }

            form.resetFields();
            setLoading(false);
            setIsModalVisible(false);
        } catch {}
    };

    const handleUpload = (e) => {
        setUploads(e.file.originFileObj);
    };

    const uploadFile = async (e) => {
        setLoading(true);
        let downloadURL = "";
        let fileName = Date.now().toString() + "_" + uploads.name;

        await storage
            .ref("productUploads/" + fileName)
            .put(uploads)
            .then((snapshot) => {
                downloadURL = snapshot.ref.getDownloadURL();
                // return snapshot.ref.getDownloadURL();
            })

            .catch((error) => {
                console.log(error);
            });
        return downloadURL;
    };

    return (
        <Form
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
        >
            <Form.Item
                name={"name"}
                label="Product Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name={"number"}
                label="Product Number"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name={"price"}
                label="Price"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                name={"description"}
                label="Product Description"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                name="img"
                label="Product Image"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Upload onChange={handleUpload}>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                name={"category"}
                label="Category"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name={"subcategory"}
                label="Subcategory"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name={"subcategorygroup"}
                label="Subcategory Group"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 8, offset: 0 }}>
                <Space>
                    {" "}
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading ? true : false}
                    >
                        {edit ? "Edit Product" : "Add Product"}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}
