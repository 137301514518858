import React, { useState } from "react";
import { Table, Modal, Space } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { PreshipmentInspectionForm } from "../Forms";
import { useHistory } from "react-router-dom";

export function PreshipmentInspectionOrderTable(props) {
    const { userData } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState([]);
    const firestore = useFirestore();
    const history = useHistory();

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const ordersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .where("serviceType", "==", "preShipmentInspection");

    const { data: orderData, status: orderStatus } = useFirestoreCollectionData(
        ordersRef,
        {
            idField: "id",
        },
    );
    const swapOrder = async (data) => {
        await firestore
            .collection("users")
            .doc(userData.user_id)
            .set(
                {
                    services_ids: {
                        preShipmentInspection: data.id,
                    },
                },
                { merge: "true" },
            );
        history.push({
            pathname: `pre-shipment-inspection/${data.order_id}`,
            state: data,
        });
    };

    const editOrder = (data) => {
        setIsModalVisible(true);
        setEditData(data);
    };

    const openNewTab = (url) => {
        if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
            window.open(url, "_blank", "noopener,noreferrer");
        } else {
            window.open("http://" + url, "_blank", "noopener,noreferrer");
        }
    };

    const actions = (text, record) =>
        record.orderStatus === "invoiceIssued" ||
        record.orderStatus === "invoicePending" ? (
            <Space size="middle">
                <a onClick={() => editOrder(text)}>Edit</a>
            </Space>
        ) : (
            <div />
        );
    const text = (text, data) => (
        <a onClick={() => swapOrder(data)}>{`${text}${
            data.order_idExtension || ""
        }`}</a>
    );
    const schedule = (text) => <a>{text?.inspectionDate}</a>;
    const report = (text) => (
        <a onClick={() => openNewTab(text?.inspectionReport)}>
            {text?.inspectionReport}
        </a>
    );
    const trackingNum = (text) => <a>{text.internationalTrackingNumber}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const shippingDate = (date) => (
        <a>{date && date.dateIssued.toDate().toDateString()}</a>
    );

    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            key: "name",
            render: text,
        },
        {
            title: "Date Ordered",
            key: "dateAdded",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Inspection Date",
            key: "dateAdded",
            dataIndex: "inspectionDetails",
            sorter: (a, b) => a.dateIssued - b.dateIssued,
            defaultSortOrder: "descend",
            render: schedule,
        },

        {
            title: "Inspection Report",
            dataIndex: "inspectionDetails",
            key: "name",
            render: report,
        },

        {
            title: "Action",
            key: "action",
            render: actions,
        },
    ];
    return (
        <>
            <Table
                scroll={{ x: true }}
                columns={columns}
                loading={orderStatus === "loading" ? true : false}
                dataSource={orderData}
            ></Table>

            <Modal
                title="Edit Tracking Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <PreshipmentInspectionForm
                    userData={userData}
                    edit={true}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
}
