import React, { useState } from "react";
import { useAuth } from "reactfire";
import { Form, Input, Button } from "antd";
import { useHistory, Link } from "react-router-dom";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 24,
  },
};

export default function Login() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const auth = useAuth();

  console.log("home");
  const signIn = async (email, password) => {
    setLoading(true);
    await auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        // history.push("/users");
        window.alert("Login Successful");
        setLoading(false);
      })
      .catch((error) => {
        //var errorCode = error.code;
        setLoading(false);
        form.resetFields();
        window.alert(error.message);
        var errorMessage = error.message;
        console.log(errorMessage);
      });
  };
  const onFinish = async (values) => {
    await signIn(values.email, values.password);
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="container">
        <div className="form-container">
          <img alt={""} className="img-logo" src="/assets/b2c.svg"></img>

          <Form
            form={form}
            style={{ marginTop: "40px", flex: "0 0 1" }}
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                style={{
                  backgroundColor: "#FF9900",
                  border: "none",
                  width: "100%",
                }}
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>

          <Link to="/register">Click here to Register!</Link>
        </div>
      </div>
    </>
  );
}
