import React from "react";
import { Tag } from "antd";

function preShipmentColor(tag){
  switch(true){
    case(tag === 'invoicePending'):
      return 'volcano'
    case(tag === 'invoiceIssued'):
      return 'gold'
    case(tag === 'invoicePaid'):
      return 'green'
    case(tag === 'edited'):
      return 'red'
    default: 
      return 'processing'
  }
}

function storageColor(tag){
  switch(true){
    case(tag === 'receiving'):
      return 'gold'
    case(tag === 'received'):
      return 'green'
    case(tag === 'storing'):
      return 'green'
    case(tag === 'stoppedStoring'):
      return 'warning'
    case(tag === 'invoiceIssued'):
      return 'gold'
    case(tag === 'invoicePaid'):
      return 'cyan'
    case(tag === 'inspectionScheduled'):
      return 'blue'
    case(tag === 'edited'):
      return 'warning'
    default: 
      return 'green'
  }
}

function sampleForwardingColor(tag){
  switch(true){
    case(tag === 'doneCollecting'):
      return 'green'
    case(tag === 'collecting'):
      return 'volcano'
    case(tag === 'invoiceIssued'):
      return 'gold'
    case(tag === 'invoicePaid'):
      return 'cyan'
    default: 
      return 'processing'
  }
}

function shippingColor(tag){
  switch(true){
    case(tag === 'quotationPending'):
      return 'volcano'
    case(tag === 'quotationIssued'):
      return 'gold'
    case(tag === 'orderProceeded'):
      return 'processing'
    case(tag === 'invoiceIssued'):
      return 'gold'
    case(tag === 'invoicePaid'):
      return 'green'
    case(tag === 'edited'):
      return 'red'
    default: 
      return 'processing'
  }
}
function tagText(tag){
  switch(true){
    case(tag === 'doneCollecting'):
      return 'Done Collecting'
    case(tag === 'collecting'):
      return 'Collecting'
    case(tag === 'invoiceIssued'):
      return 'Invoice(s) Issued'
    case(tag === 'invoicePaid'):
      return 'Invoice(s) Paid'
    case(tag === 'inspectionScheduled'):
      return 'Inspection Scheduled'
    case(tag === 'shipped'):
      return 'Shipped'
    case(tag === 'invoicePending'):
      return 'Invoice Pending'
    case(tag === 'edited'):
      return 'Edited'
    case(tag === 'reportSubmitted'):
      return 'Done'
    case(tag === 'quotationPending'):
      return 'Quotation Pending'
    case(tag === 'quotationIssued'):
      return 'Quotation Issued'
    case(tag === 'shippingScheduled'):
      return 'Shipping Scheduled'
    case(tag === 'orderProceeded'):
      return "Order Proceeded/Invoice Pending"
    case(tag === 'storing'):
      return 'Storing'
    case(tag === 'receiving'):
      return 'Receiving'
    case(tag === 'received'):
      return 'Received'
    case(tag === 'stoppedStoring'):
      return 'Stopped Storing'
    default: 
      return 'green'
  }
}

export function ServiceTag({ service, tag }) {
  if(service === 'storage'){
    return <Tag
    color={storageColor(tag)}>
      {
     tagText(tag)}
    </Tag>
  }
  if (service === "samplesForwarding") {
    return (
      <Tag
        color={sampleForwardingColor(tag)}
      >
        {tagText(tag)}
      </Tag>
    );
  }

  if (service === "preShipmentInspection") {
    return (
      <Tag
        color={preShipmentColor(tag)}
      >
        {tagText(tag)}
      </Tag>
    );
  }

  return (
    <>
      <Tag
        color={
          shippingColor(tag)
        }
      >
        {tagText(tag)}
      </Tag>
    </>
  );
}
