import React, { useContext } from 'react'
import { UserContext } from '../../../../context/authContext'
import { AdminLayout } from './AdminLayout';
import { AgentLayout } from './AgentLayout';

export default function Layout({children}) {
    const { userClaims, userData } = useContext(UserContext);

    if(userClaims.agent) return <AgentLayout children={children}/>

    return (
        <AdminLayout children={children}/>
    )
}
