import React, { useEffect, useState } from "react";
import { Form, Input, Upload, Button, Space, InputNumber } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage } from "reactfire";

const validateMessages = {
    required: "${label} is required!",
};

export default function Invoiceform(props) {
    const { edit, orderData, editData, setIsModalVisible, orderid } = props;
    const [form] = Form.useForm();
    const storage = useStorage();
    const firestore = useFirestore();
    const fieldValue = useFirestore.FieldValue;
    const [uploads, setUploads] = useState([]);
    const [loading, setLoading] = useState();

    const userInvoiceRef = firestore
        .collection("users")
        .doc(orderData?.orderedBy_id)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const handleUpload = (e) => {
        setUploads(e.file.originFileObj);
    };

    const uploadFile = async (e) => {
        setLoading(true);
        let downloadURL = "";
        let fileName = Date.now().toString() + "_" + uploads.name;

        await storage
            .ref("invoiceUploads/" + fileName)
            .put(uploads)
            .then((snapshot) => {
                downloadURL = snapshot.ref.getDownloadURL();
                // return snapshot.ref.getDownloadURL();
            })

            .catch((error) => {
                console.log(error);
            });
        return downloadURL;
    };

    const addInvoiceNumber = async (values) => {
        const url = await uploadFile();
        await userInvoiceRef.add({
            user_id: orderData?.orderedBy_id,
            orderNumber: orderData.order_id,
            invoiceNumber: values.invoiceNumber,
            invoiceAmount: values.invoiceAmount,
            paymentStatus: "notPaid",
            dateIssued: fieldValue.serverTimestamp(),
            invoiceFile: url,
        });

        await firestore
            .collection("users")
            .doc(orderData?.orderedBy_id)
            .collection("orders")
            .doc(orderid)
            .update({
                orderStatus: "invoiceIssued",
            });
    };

    const onFinish = async (values) => {
        await addInvoiceNumber(values);
        await window.alert("Invoice Number Issued!");
        await form.resetFields();
        await setLoading(false);
        await setIsModalVisible(false);
    };

    const updateInvoiceNumber = async (values) => {
        const url = await uploadFile();
        await userInvoiceRef.doc(editData.id).update({
            invoiceNumber: values.invoiceNumber,
            invoiceAmount: values.invoiceAmount,
            paymentStatus: "notPaid",
            invoiceFile: url,
            dateIssued: fieldValue.serverTimestamp(),
        });
    };

    const onFinishEdit = async (values) => {
        await updateInvoiceNumber(values);
        await window.alert("Invoice Number Updated!");
        await form.resetFields();
        await setLoading(false);
        await setIsModalVisible(false);
    };

    if (edit && editData) {
        // console.log(editData);
    }
    return (
        <>
            <Form
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"invoiceNumber"}
                    label="Invoice Number"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"invoiceAmount"}
                    label="Amount to Pay"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    name="invoiceFile"
                    label="Invoice File"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Upload onChange={handleUpload}>
                        <Button icon={<UploadOutlined />}>
                            Click to upload
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 8, offset: 0 }}>
                    <Space>
                        {" "}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading ? true : false}
                        >
                            Issue Invoice
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
}
