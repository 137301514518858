import React, { useContext } from "react";
import { Table, Select, Space, Tag } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { ServiceTag } from "../../components/ServiceTag";
import { IoArchiveOutline } from "react-icons/io5";
import { UserContext } from "../../context/authContext";

const { Option } = Select;

export const AdminOrders = ({ service }) => {
    const history = useHistory();
    const firestore = useFirestore();
    const { userClaims, userData } = useContext(UserContext);

    const ordersRef = useFirestore().collectionGroup("orders");
    const agentsRef = useFirestore()
        .collection("admins")
        .where("role", "==", "agent");

    const orderQuery = ordersRef
        .where("serviceType", "==", service)
        .where("isArchived", "==", false)
        .orderBy("dateOrdered", "desc");

    const orderAgentQuery = ordersRef
        .where("serviceType", "==", service)
        .where("assignedTo", "==", userClaims.user_id)
        .where("isArchived", "==", false)
        .orderBy("dateOrdered", "desc");

    const { data: agentsData, status: agentsDataStatus } =
        useFirestoreCollectionData(agentsRef, {
            idField: "id",
        });

    const { data: ordersData, status: ordersStatus } =
        useFirestoreCollectionData(orderQuery, {
            idField: "id",
        });

    const { data: orderAgentQueryData, status: orderAgentQueryStatus } =
        useFirestoreCollectionData(orderAgentQuery, {
            idField: "id",
        });

    const archiveOrder = async (data) => {
        if (window.confirm("Archive order?"))
            firestore
                .collection("users")
                .doc(data.orderedBy_id)
                .collection("orders")
                .doc(data.id)
                .update({
                    isArchived: true,
                });
    };

    const deleteOrder = async (data) => {
        if (window.confirm("Delete order?"))
            await firestore
                .collection("users")
                .doc(data.orderedBy_id)
                .collection("orders")
                .doc(data.id)
                .delete();
    };

    const redirect = () => {
        if (service === "samplesForwarding") return "samples-forwarding";
        if (service === "preShipmentInspection")
            return "pre-shipment-inspection";
        if (service === "storage") return "storage";
        if (service === "sourcing") return "sourcing";
        return "shipping";
    };

    const assignAgent = (value, event, data) => {
        const userRef = firestore.collection("users").doc(data.orderedBy_id);
        userRef
            .collection("orders")
            .doc(data.id)
            .update({
                assignedTo: value || "",
                assignedToName: event.children || "",
            });
    };

    const selectAgent = (text, record) => (
        <Select
            onSelect={(value, options) => assignAgent(value, options, record)}
            value={text}
            placeholder="Select an agent to assign to"
        >
            {agentsData?.map((agent, index) => (
                <Option key={index} value={agent.id}>
                    {agent.name}
                </Option>
            ))}
            <Option value="">None</Option>
        </Select>
    );

    const tag = (tag) => <ServiceTag tag={tag} service={service} />;
    const renderText = (text, record) => (
        <Link
            to={{
                pathname: `/admin/${redirect()}/${record.order_id}`,
                state: { record },
            }}
        >
            {text}
        </Link>
        // <a onClick={() => history.push(`/admin/${redirect()}/${record.id}`)}>
        //   {text}
        // </a>
    );

    const renderEmail = (text, record) => (
        <a
            onClick={() => {
                navigator.clipboard.writeText(text);
            }}
        >
            {" "}
            {text}
        </a>
    );

    const renderName = (text, record) => (
        <Link
            to={{
                pathname: `/admin/users/${record.orderedBy_id}`,
            }}
        >
            {text}
        </Link>
    );

    const actions = (text, record) => (
        <Space>
            <InboxOutlined
                onClick={() => archiveOrder(text)}
                style={{ fontSize: "24px" }}
            />
            <DeleteOutlined
                onClick={() => deleteOrder(text)}
                style={{ fontSize: "22px" }}
            />
        </Space>
    );
    const date = (date) => <a>{date.toDate().toDateString()}</a>;
    const trackingNumbersReceived = (samples, data) => (
        <a>
            {samples || 0}/{data.totalTrackingNumbers || 0}
        </a>
    );
    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            render: renderText,
        },
        {
            title: "Customer Name",
            dataIndex: "orderedBy_name",
            render: renderName,
        },
        {
            title: "Customer Email",
            dataIndex: "orderedBy_email",
            render: renderEmail,
        },
        {
            title: "Assigned To",
            dataIndex: "assignedToName",
            render: userClaims.agent ? renderText : selectAgent,
        },
        {
            title: "Date Ordered",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },
        {
            title: "Status",
            dataIndex: "orderStatus",
            render: tag,
            defaultSortOrder: "false",
            sorter: (a, b) => (a.orderStatus === b.orderStatus ? 1 : -1),
        },
        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];

    const samplesColumns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            render: renderText,
        },
        {
            title: "Customer Name",
            dataIndex: "orderedBy_name",
            render: renderName,
        },

        {
            title: "Customer Email",
            dataIndex: "orderedBy_email",
            render: renderEmail,
        },
        {
            title: "Assigned To",
            dataIndex: "assignedToName",
            render: userClaims.agent ? renderText : selectAgent,
        },
        {
            title: "Date Ordered",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },
        {
            title: "Status",
            dataIndex: "orderStatus",
            render: tag,
            defaultSortOrder: "false",
            sorter: (a, b) => (a.orderStatus === b.orderStatus ? 1 : -1),
        },

        {
            title: "Total Samples Collected",
            dataIndex: "trackingNumbersReceived",
            render: trackingNumbersReceived,
        },

        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];
    return (
        <Table
            bordered
            scroll={{ x: true }}
            columns={service === "samplesForwarding" ? samplesColumns : columns}
            dataSource={userClaims.agent ? orderAgentQueryData : ordersData}
            loading={ordersStatus === "loading" ? true : false}
        />
    );
};
