import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Table, Tag, Card, Popover } from "antd";
import {
    useFirestore,
    useFirestoreDocData,
    useFirestoreCollectionData,
} from "reactfire";

const bankDetails = (
    <div>
        <div>You can transfer the money to our bank account:</div>
        <div>
            USD Bank Account: Beneficiary Bank: Zhejiang Rural Credit
            Cooperative Union, Hangzhou, China
        </div>
        <div>Swift BIC: ZJRCCN2N</div>
        <div>
            Add: No. 660 Qiutao Road, Hangzhou, Zhejiang Province, China, 310016
        </div>
        <div>Beneficiary Name: B2C SOURCING LIMITED</div>
        <div>Account#: NRA201000185171317</div>
    </div>
);

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

export function UserInvoicePaymentTable(props) {
    const { userData, order_id } = props;
    //   const order_id = userData.services_ids.samplesForwarding;
    const [paymentStatus, setPaymentStatus] = useState(false);
    const ordersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd");

    const { data: orderData, status: orderStatus } = useFirestoreDocData(
        ordersRef,
        {
            idField: "id",
        },
    );

    const userInvoiceRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd")
        .collection("invoices");

    const paymentRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd")
        .collection("payments");

    const { data: invoiceData, status: invoiceStatus } =
        useFirestoreCollectionData(userInvoiceRef, {
            idField: "id",
        });

    const createOrder = (data, actions, text) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: text.invoiceAmount,
                    },
                },
            ],
        });
    };

    const checkPayment = async (paymentDetails, data) => {
        if (paymentDetails.status === "COMPLETED") {
            await userInvoiceRef.doc(data.id).update({
                paymentStatus: "confirmationPending",
            });

            await paymentRef.add({
                customerName: orderData.orderedBy_name,
                amount: data.invoiceAmount,
                order_id: data.orderNumber,
                invoiceNumber: data.invoiceNumber,
                datePaid: paymentDetails.update_time,
                paymentDetails: paymentDetails,
            });
            setPaymentStatus(true);
            window.alert(
                "Payment Successfuly sent, please wait for confirmation",
            );
        } else {
            window.alert("Payment Failed");
        }
    };

    const onApprove = async (data, actions, text) => {
        const paymentDetails = await actions.order.capture();
        return checkPayment(paymentDetails, text);
    };

    const payNowButton = (text, record) =>
        text?.paymentStatus === "confirmationPending" ||
        text?.paymentStatus === "paid" ? (
            <div>{window.Date(orderData.datePaid)}</div>
        ) : (
            <a onClick={() => openNewTab(record?.invoiceFile)}>Pay Now</a>
        );

    const actions = (text, record) =>
        text?.paymentStatus === "confirmationPending" ||
        text?.paymentStatus === "paid" ? (
            <div>{window.Date(orderData.datePaid)}</div>
        ) : (
            <PayPalButton
                createOrder={(data, actions) =>
                    createOrder(data, actions, text)
                }
                onApprove={(data, actions) => onApprove(data, actions, text)}
            />
        );

    const paymentAction = (text, record) =>
        orderData?.serviceType === "samplesForwarding" ||
        orderData?.serviceType === "preShipmentInspection"
            ? actions(text, record)
            : payNowButton(text, record);

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const text = (text, record) => (
        <a onClick={() => openNewTab(record?.invoiceFile)}>{text}</a>
    );
    const amount = (text) => <a>${text}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const title = () => <div>Invoice Details</div>;
    const footer = () => (
        <div>
            {/* <Popover content={''} trigger="click">
        <a>Click Here to contact our sales</a>
      </Popover> */}
            <div>Click the given invoice number to download the invoice</div>
        </div>
    );

    const paymentTitle = (text) => "Payment Methods/Payment Date";

    const paymentTag = (tag) => (
        <Tag
            color={
                tag === "paid"
                    ? "green"
                    : tag === "notPaid"
                    ? "volcano"
                    : tag === "confirmationPending"
                    ? "processing"
                    : "volcano"
            }
        >
            {tag === "paid"
                ? "Paid"
                : tag === "notPaid"
                ? "Not Paid"
                : tag === "confirmationPending"
                ? "Confirmation Pending"
                : ""}
        </Tag>
    );

    const columns = [
        {
            title: "Invoice Number",
            dataIndex: "invoiceNumber",
            key: "id",
            render: text,
        },
        {
            title: "Amount to Pay",
            key: "id",
            dataIndex: "invoiceAmount",
            render: amount,
        },
        {
            title: "Payment Status",
            key: "id",
            dataIndex: "paymentStatus",
            render: paymentTag,
        },

        {
            title: paymentTitle,
            key: "action",
            render: paymentAction,
        },
    ];

    if (orderStatus === "error") {
        return (
            <Table
                title={title}
                loading={orderStatus === "loading" ? true : false}
                columns={columns}
                data={""}
                footer={footer}
            />
        );
    }

    return (
        <>
            {orderStatus === "success" ? (
                <Card
                    title={`Invoice Details | Order # ${
                        orderData.order_id ? orderData?.order_id : ""
                    }`}
                    bodyStyle={{ padding: "0" }}
                    bordered
                    style={{ border: "1px solid lightgrey" }}
                >
                    <Table
                        scroll={{ x: true }}
                        loading={orderStatus === "loading" ? true : false}
                        columns={columns}
                        dataSource={invoiceData}
                        footer={footer}
                    />
                </Card>
            ) : (
                <Table
                    title={title}
                    loading={orderStatus === "loading" ? true : false}
                    columns={columns}
                    pagination={false}
                />
            )}
        </>
    );
}
