import React, { useState } from "react";
import {
  Card,
  Input,
  Button,
  Col,
  Form,
  Space,
} from "antd";
import { useLocation } from "react-router-dom";
import {
  useFirestore,
} from "reactfire";

export function InternationalTrackingNumber({orderData}) {
    const [loading, setLoading] = useState();
    const [form] = Form.useForm();
    const orderDetails = orderData?.orderDetails;
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record.orderedBy_id;

    const userOrderRef = useFirestore()
    .collection("users")
    .doc(userid)
    .collection("orders")
    .doc(orderid);

    const onFinish = async (data) => {
        await setLoading(true);
        await userOrderRef.set(
          {
            orderDetails: {
                trackingNumber: data.trackingNumber
            },
          },
          { merge: true }
        );
        await window.alert("Tracking Number issued!");
        await form.resetFields();
        await setLoading(false);
      };
    return (
        <>
        <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
            <Card title={"Shipping Schedule"} bordered>
            <Form
                form={form}
                name="internationalTracking"
                onFinish={onFinish}
            >
                <Space>
                <Form.Item
                    name={"trackingNumber"}
                    label="Tracking Number"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 8, offset: 0 }}>
                    
                        {" "}
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Issue International Tracking Number
                        </Button>
                    
                </Form.Item>
                </Space>
            </Form>
              <div>
                International Tracking Number:{" "}{orderDetails?.trackingNumber}
              </div>
            </Card>
          </Col>
        </>
    )
}
