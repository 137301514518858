import React from "react";
import { Table, Layout, Breadcrumb, Tag } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { ServiceTag } from "../../components/ServiceTag";
const { Content } = Layout;

export const AdminUserOrders = () => {
  const history = useHistory();
  let { userid } = useParams();

  const ordersRef = useFirestore()
    .collection("users")
    .doc(userid)
    .collection("orders");

  const { data: ordersData, status: ordersStatus } = useFirestoreCollectionData(
    ordersRef,
    {
      idField: "id",
    }
  );

  const text = (text, record) => <a>{text}</a>;
  const renderText = (text, record) => (
    <a>{record.firstName + " " + record.lastName}</a>
  );
  const tag = (tag, record) => (
    <ServiceTag tag={tag} service={record.serviceType} />
  );
  // const renderText = (text) => <a>{text}</a>;
  const date = (date) => <a>{date.toDate().toDateString()}</a>;
  const columns = [
    {
      title: "Order Number",
      dataIndex: "order_id",
      render: text,
    },

    {
      title: "Date Ordered",
      dataIndex: "dateOrdered",
      sorter: (a, b) => a.dateOrdered - b.dateOrdered,
      defaultSortOrder: "descend",
      render: date,
    },

    {
      title: "Status",
      dataIndex: "orderStatus",
      render: tag,
    },
  ];
  return (
    <div
      className="samples-layout"
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      <Content style={{ margin: "0 16px" }}>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       history.push("/admin/samples-forwarding/orderid");
          //     }, // click row
          //   };
          // }}
          bordered
          columns={columns}
          dataSource={ordersData}
        />
      </Content>
    </div>
  );
};
