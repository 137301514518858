import React, { useState } from "react";
import { useAuth, useFirestore } from "reactfire";
import { Form, Input, Button, Select } from "antd";
import { useHistory, Link } from "react-router-dom";
import { countryCodes } from "../utils/countryCodes";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 24,
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 150,
      }}
      defaultValue="+1"
      value="+1"
    >
      {countryCodes.map((code, index) => {
        return (
          <Option key={index} value={code.dial_code}>
            {code.name} {code.dial_code}
          </Option>
        );
      })}
    </Select>
  </Form.Item>
);

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export default function Register() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const auth = useAuth();
  const firestore = useFirestore();
  const timestamp = useFirestore.FieldValue.serverTimestamp();
  form.setFieldsValue({ prefix: "+1" });

  const signIn = async (values) => {
    setLoading(true);
    await auth
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async (userCredential) => {
        var user = userCredential.user;
        let newValues = await clean(values);
        await firestore
          .collection("users")
          .doc(user.uid)
          .set({
            ...newValues,
            dateRegistered: timestamp,
            services_ids: {
              preShipmentInspection: "",
              samplesForwarding: "",
              shipping: "",
              sourcing: "",
            },
            user_id: user.uid,
          });

        history.push("/");
        window.alert("Registration Successful");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        form.resetFields();
        var errorCode = error.code;
        window.alert(error.message);
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };
  const onFinish = async (values) => {
    await signIn(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="container">
        <div className="register-container">
          <img className="img-logo" src="/assets/b2c.svg"></img>

          <Form
            form={form}
            style={{ marginTop: "20px" }}
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="contactNumber" label="Contact Number">
              <Input
                addonBefore={prefixSelector}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item name="referenceCode" label="Reference Code">
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                style={{
                  backgroundColor: "#FF9900",
                  border: "none",
                  width: "100%",
                }}
                htmlType="submit"
              >
                Register
              </Button>
            </Form.Item>
          </Form>

          <Link to="/">Click here to Login!</Link>
        </div>
      </div>
    </>
  );
}
