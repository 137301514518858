import React, { useContext } from "react";
import { UserContext } from "../../context/authContext";
import { Spin, Row, Col, Layout, Card } from "antd";
import {
  UserInvoicePaymentTable,
  ShippingOrderTable,
  StorageForm
} from "./components";
import { StorageOrderTable } from "./components/Tables";

export function UserStorage(props) {
  const { user, userData } = useContext(UserContext);
  if (!user || !userData) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={6} xs={24} xl={24} xxl={10}>
        <Card
          title={<div>Form</div>}
          style={{ border: "1px solid lightgrey" }}
          bordered
        >
          <StorageForm edit={false} userData={userData} />
        </Card>
      </Col>

      <Col span={6} xs={24} xl={24} xxl={14}>
        <Row gutter={[16, 16]}>
          <Col span={6} xs={24} xl={24}>
            <UserInvoicePaymentTable
              userData={userData}
              order_id={userData?.services_ids?.storage}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={6} xs={24} xl={24}>
            <Card
              bodyStyle={{ padding: "0" }}
              bordered
              style={{ border: "1px solid lightgrey" }}
            >
              <StorageOrderTable
                userData={userData}
                order_id={userData?.services_ids?.storage}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
