import React, { useState, useContext } from "react";
import {
    AdminInvoiceTable,
    AdminInvoicePaymentsTable,
    AdminInvoiceForm,
    UserInfoCard,
} from "./components";
import {
    Table,
    Layout,
    Breadcrumb,
    Card,
    Form,
    Button,
    Tag,
    Input,
    Col,
    Spin,
    Row,
    Space,
    Modal,
    Descriptions,
    PageHeader,
} from "antd";
import { useParams, useLocation } from "react-router-dom";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useStorage,
} from "reactfire";
import CommentsSection from "../../components/CommentsSection";
import { UserContext } from "../../context/authContext";

const { Content } = Layout;

export const AdminSamplesForwarding = () => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    //   let { orderid } = useParams();
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record.orderedBy_id;
    const firestore = useFirestore();
    const fieldValue = useFirestore.FieldValue;
    const [editStatus, setEditStatus] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState();
    const [uploads, setUploads] = useState([]);
    const [loading, setLoading] = useState();
    const storage = useStorage();
    const increment = useFirestore.FieldValue.increment;
    const { user } = useContext(UserContext);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const orderDataRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd");

    const userOrderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid);

    const trackingNumbersRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd")
        .collection("trackingNumbers")
        .orderBy("dateAdded", "desc");

    const userInvoiceRef = firestore
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const paymentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd")
        .collection("payments");

    const adminRef = useFirestore().collection("admins").doc(user.uid);

    const commentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("comments");

    const { data: commentsData, status: commentsStatus } =
        useFirestoreCollectionData(commentsRef.orderBy("timeStamp", "asc"), {
            idField: "id",
        });

    const { data: adminData, status: adminStatus } = useFirestoreDocDataOnce(
        adminRef,
        {
            idField: "id",
        },
    );

    const { data: trackingNumbersData, status: trackingNumbersStatus } =
        useFirestoreCollectionData(trackingNumbersRef, {
            idField: "id",
        });

    const { data: orderData, status: orderDataStatus } = useFirestoreDocData(
        orderDataRef,
        {
            idField: "id",
        },
    );

    const { data: invoiceData, status: invoiceStatus } =
        useFirestoreCollectionData(userInvoiceRef, {
            idField: "id",
        });

    const { data: paymentsData, status: paymentsStatus } =
        useFirestoreCollectionData(paymentsRef, {
            idField: "id",
        });

    const updateReceivedStatus = async (text, received) => {
        await firestore
            .collection("users")
            .doc(orderData.orderedBy_id)
            .collection("orders")
            .doc(orderid)
            .collection("trackingNumbers")
            .doc(text.id)
            .update({
                isReceived: !text.isReceived,
            });
        await firestore
            .collection("users")
            .doc(orderData.orderedBy_id)
            .collection("orders")
            .doc(orderid)
            .update({
                trackingNumbersReceived: received
                    ? increment(1)
                    : increment(-1),
            });
    };

    const confirmPayment = async (data) => {
        await userInvoiceRef.doc(data.id).update({
            paymentStatus: "paid",
        });

        await userOrderRef.update({
            orderStatus: "invoicePaid",
        });
    };

    const deleteInvoice = async (data) => {
        await userInvoiceRef.doc(data.id).delete();
    };

    const updateInternationalTracking = async (values) => {
        setLoading(true);
        await firestore
            .collection("users")
            .doc(userid)
            .collection("orders")
            .doc(orderid)
            .update({
                orderStatus: "shipped",
                internationalTrackingDetails: {
                    internationalTrackingNumber:
                        values.internationalTrackingNumber,
                    dateIssued: fieldValue.serverTimestamp(),
                },
            });

        await firestore
            .collection("users")
            .doc(userid)
            .set(
                {
                    services_ids: {
                        samplesForwarding: "",
                    },
                },
                { merge: true },
            );
        setLoading(false);
    };

    const onFinishIntTracking = async (values) => {
        await updateInternationalTracking(values);
        await window.alert("International Tracking Number Issued!");
        await form.resetFields();
    };

    const markAsAllPaid = async () => {
        if (orderData.allPaid !== undefined) {
            await userOrderRef.update({
                allPaid: !orderData.allPaid,
            });
        } else {
            await userOrderRef.update({
                allPaid: true,
            });
        }
    };

    const markAsAllReceived = async () => {
        if (orderData.allReceived !== undefined) {
            await userOrderRef.update({
                allReceived: !orderData.allReceived,
            });
        } else {
            await userOrderRef.update({
                allReceived: true,
            });
        }
    };

    const markAsAllInvoicesIssued = async () => {
        if (orderData.allInvoicesIssued !== undefined) {
            await userOrderRef.update({
                allInvoicesIssued: !orderData.allInvoicesIssued,
            });
        } else {
            await userOrderRef.update({
                allInvoicesIssued: true,
            });
        }
    };

    const intTrackingValidateMessages = {
        required: "${label} is required!",
    };

    const tag = (tag) => (
        <Tag color={tag ? "green" : "volcano"}>
            {tag === true ? "Received" : "Not Received"}
        </Tag>
    );
    const statusTag = (tag) => (
        <Tag
            color={
                tag === "doneCollecting"
                    ? "green"
                    : tag === "collecting"
                    ? "volcano"
                    : tag === "invoiceIssued"
                    ? "gold"
                    : tag === "invoicePaid"
                    ? "cyan"
                    : "processing"
            }
        >
            {tag === "doneCollecting"
                ? "Done Collecting"
                : tag === "collecting"
                ? "Collecting"
                : tag === "invoiceIssued"
                ? "Invoice(s) Issued"
                : tag === "invoicePaid"
                ? "Invoice(s) Paid"
                : "Shipped"}
        </Tag>
    );

    const actions = (text, record) =>
        text.isReceived ? (
            <Button
                danger={true}
                style={{
                    background: "#C9302C",
                    color: "white",
                    border: "none",
                }}
                col
                onClick={() => updateReceivedStatus(text, false)}
            >
                Mark as Not Received
            </Button>
        ) : (
            <Button
                style={{
                    borderColor: "#449D44",
                    color: "white",
                    background: "#449D44",
                }}
                onClick={() => updateReceivedStatus(text, true)}
            >
                Mark as Received
            </Button>
        );
    const text = (text) => <a>{text}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;

    const expandedColumns = [
        { title: "Sample Name", dataIndex: "sampleName", key: "sampleName" },
        {
            title: "Supplier Name",
            dataIndex: "supplierName",
            key: "supplierName",
        },
        {
            title: "Supplier Contact",
            dataIndex: "supplierContact",
            key: "supplierContact",
        },
        { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
        {
            title: "Quantity of Samples",
            dataIndex: "quantityOfSamples",
            key: "phoneNumber",
        },
    ];

    const columns = [
        {
            title: "Tracking Number",
            dataIndex: "trackingNumber",
            key: "trackingNumber",
            render: text,
        },

        {
            title: "Date Added",
            key: "dateAdded",
            dataIndex: "dateAdded",
            render: date,
        },
        {
            title: "Status",
            key: "isReceived",
            dataIndex: "isReceived",
            render: tag,
        },

        {
            title: "Action",
            key: "action",
            render: actions,
        },
    ];

    if (
        trackingNumbersStatus === "loading" ||
        invoiceStatus === "loading" ||
        paymentsStatus === "loading"
    ) {
        return (
            <div className="loader">
                <Spin></Spin>
            </div>
        );
    }

    return (
        <div>
            <Content>
                <UserInfoCard orderData={orderData} tag={statusTag} />
                <ShippingAndInvoiceDetails
                    data={orderData?.shippingAndInvoiceDetails}
                />
                <Breadcrumb style={{ margin: "16px 0" }}>
                    {/* <Breadcrumb.Item>Samples Forwarding</Breadcrumb.Item> */}
                </Breadcrumb>
                <Card
                    bodyStyle={{ padding: "0" }}
                    bordered
                    extra={
                        <Button
                            onClick={() => {
                                markAsAllReceived();
                            }}
                        >
                            {orderData.allReceived
                                ? "Mark as All not Received"
                                : "Mark as All Received"}
                        </Button>
                    }
                >
                    <Table
                        expandable={{
                            expandedRowRender: (record) => (
                                <Table
                                    rowKey={trackingNumbersData.id}
                                    columns={expandedColumns}
                                    dataSource={[record]}
                                    pagination={false}
                                />
                            ),
                        }}
                        rowKey={"id"}
                        bordered
                        title={() => "Tracking Numbers"}
                        loading={
                            trackingNumbersStatus === "loading" ? true : false
                        }
                        columns={columns}
                        pagination={false}
                        dataSource={trackingNumbersData}
                    />
                </Card>

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col style={{ marginTop: "20px" }} xs={24} xl={24} xxl={24}>
                        <Card
                            title="Invoice Details"
                            bodyStyle={{ padding: "0" }}
                            bordered
                            extra={
                                <Space>
                                    <Button
                                        onClick={() => {
                                            setIsModalVisible(true);
                                            setEditStatus(false);
                                        }}
                                    >
                                        Add Invoice
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            markAsAllInvoicesIssued();
                                        }}
                                    >
                                        {orderData.allInvoicesIssued
                                            ? "Mark as All Invoices Not Issued"
                                            : "Mark as All Invoices Issued"}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            markAsAllPaid();
                                        }}
                                    >
                                        {orderData.allPaid
                                            ? "Mark as All Not Paid"
                                            : "Mark as All Paid"}
                                    </Button>
                                </Space>
                            }
                        >
                            <AdminInvoiceTable
                                invoiceData={invoiceData}
                                setIsModalVisible={setIsModalVisible}
                                setEditStatus={setEditStatus}
                                setEditData={setEditData}
                                confirmPayment={confirmPayment}
                                deleteInvoice={deleteInvoice}
                            />
                        </Card>
                    </Col>
                    <Col style={{ marginTop: "20px" }} xs={24} xl={24} xxl={24}>
                        <AdminInvoicePaymentsTable paymentData={paymentsData} />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col style={{ marginTop: "20px" }} span={12}>
                        {internationalTrackingForm(
                            loading,
                            onFinishIntTracking,
                            intTrackingValidateMessages,
                            form2,
                        )}
                    </Col>

                    <Col style={{ marginTop: "20px" }} span={12}>
                        <Card>
                            International Tracking Number:{" "}
                            {
                                orderData?.internationalTrackingDetails
                                    ?.internationalTrackingNumber
                            }
                        </Card>
                    </Col>
                </Row>

                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 20 }}
                    title={"Comments"}
                >
                    <CommentsSection
                        commentsData={commentsData}
                        orderData={orderData}
                        authorName={adminData?.name}
                    />
                </Card>

                <Modal
                    title={editStatus ? "Edit Invoice" : "Add Invoice"}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AdminInvoiceForm
                        orderData={orderData}
                        orderid={orderid}
                        edit={editStatus}
                        editData={editData}
                        setIsModalVisible={setIsModalVisible}
                    />
                </Modal>
            </Content>
        </div>
    );
};

const internationalTrackingForm = (
    loading,
    onFinish,
    validateMessages,
    form,
) => (
    <Card bordered>
        <Form
            form={form}
            name="internationalTracking"
            onFinish={onFinish}
            validateMessages={validateMessages}
        >
            <Form.Item
                name={"internationalTrackingNumber"}
                label="International Tracking Number"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 8, offset: 0 }}>
                <Space>
                    {" "}
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Issue International Tracking Number
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    </Card>
);

const ShippingAndInvoiceDetails = ({ data }) => {
    return (
        <>
            <div style={{ margin: "16px" }}></div>
            <Card title={"Shipping/Delivery Address and Contact Details"}>
                <Descriptions>
                    <Descriptions.Item>
                        Street/Building: {data?.street}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        City/Province: {data?.city}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Country: {data?.country}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Postal Code: {data?.postalCode}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Contact Person: {data?.contactPerson}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Contact Number: {data?.contactNumber}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Active Email Address: {data?.activeEmail}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        VAT/TVA/BTW/MWST Number: {data?.taxNumber}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            <div style={{ margin: "16px" }}></div>

            <Card title={"Invoice Details"}>
                <Descriptions>
                    <Descriptions.Item>
                        Invoice Declared Value: ${data?.invoiceDeclaredValue}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Invoice Product Description:{" "}
                        {data?.invoiceProductDescription}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
};
