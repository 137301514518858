import React, { useContext, useState } from "react";
import { UserContext } from "../../context/authContext";
import { Row, Col, Card, Steps, Spin, Button } from "antd";
import {
  UserInvoicePaymentTable,
  PreshipmentInspectionOrderTable,
  PreshipmentInspectionForm,
  Plans,
} from "./components";

const { Step } = Steps;

export function UserPreshipmentInspection(props) {
  const { user, userData } = useContext(UserContext);
  const [current, setCurrent] = React.useState(0);
  const [plan, setPlan] = useState("");

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };


  if (!user || !userData) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const steps = [
    {
      title: 'Select A Plan',
      content: <Plans plan={plan} setPlan={setPlan}/>,
    },
    {
      title: 'Fill Up Form',
      content: <PreshipmentInspectionForm plan={plan} edit={false} userData={userData} />,
    },
  ];
  

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={24} xxl={12}>
        <Card
          title={<div>Inspection Details</div>}
          style={{ border: "1px solid lightgrey" }}
          bordered
          // dataSource={data}
          // renderItem={(item) => <List.Item>{item}</List.Item>}
        >
        <Steps current={current}>
          {steps.map(item => (
        <Step key={item.title} title={item.title} />
        ))}
          </Steps>
          
          <Card style={{marginTop: 20, marginBottom: 20}}>{steps[current].content}</Card>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={!plan}>
            Next
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        </Card>
      </Col>

      <Col xs={24} xl={24} xxl={12}>
        <Row style={{ marginBottom: "20px" }}>
          <Col xxl={24} xl={24}>
            <UserInvoicePaymentTable
              userData={userData}
              order_id={userData?.services_ids?.preShipmentInspection}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            {" "}
            <Card
              title="Orders"
              bodyStyle={{ padding: "0" }}
              bordered
              style={{ border: "1px solid lightgrey" }}
            >
              <PreshipmentInspectionOrderTable userData={userData} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
