import React, { useState } from "react";
import {
    Table,
    Tag,
    Space,
    Button,
    Modal,
    Form,
    Input,
    InputNumber,
} from "antd";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
} from "reactfire";

import { SamplesForwardingForm } from "../Forms";

// import DomesticTrackingForm from "./DomesticTrackingForm";

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

export function SamplesForwardingTrackingTable(props) {
    const { userData } = props;
    const order_id = userData.services_ids.samplesForwarding;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState([]);
    const [doneCollecting, setDoneCollecting] = useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const firestore = useFirestore();

    const orderRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd");

    const trackingRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd")
        .collection("trackingNumbers");

    const { data: orderData, status: orderStatus } = useFirestoreDocData(
        orderRef,
        {
            idField: "id",
        },
    );

    const trackingNumbersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .doc(order_id ? order_id : "asd")
        .collection("trackingNumbers")
        .orderBy("dateAdded", "desc");
    const { data: trackingNumbersData, status: trackingNumbersStatus } =
        useFirestoreCollectionData(trackingNumbersRef, {
            idField: "id",
        });

    const showModal = (data) => {
        if (orderData.orderStatus === "collecting") {
            setIsModalVisible(true);
            setEditData(data);
        } else {
            if ("invoiceDetails" in orderData) {
                window.alert("Your Invoice Number has already been issued");
            } else {
                window.alert(
                    "You are done collecting, please click Keep Collecting to continue",
                );
            }
        }
    };

    const deleteTracking = async (text) => {
        if (orderData.orderStatus === "collecting") {
            if (
                window.confirm(
                    "Are you sure you want to delete this tracking number?",
                )
            )
                await trackingRef.doc(text.id).delete();
        } else {
            if ("invoiceDetails" in orderData) {
                window.alert("Your Invoice Number has already been issued");
            } else {
                window.alert(
                    "You are done collecting, please click Keep Collecting to continue",
                );
            }
        }
    };

    const checkOrderStatus = async () => {
        var orderStatus = orderData.orderStatus;
        if (orderStatus === "collecting") return "doneCollecting";
        return "collecting";
    };

    const updateCollectingStatus = async () => {
        var orderStatus = await checkOrderStatus();
        await orderRef.update({
            orderStatus: orderStatus,
        });
    };

    const doneCollectingModal = async () => {
        setDoneCollecting(true);
        setIsModalVisible(true);
    };

    const footer = () =>
        orderStatus === "success" ? (
            <Space size="middle">
                <Button
                    type="primary"
                    disabled={
                        orderData.orderStatus === "collecting" ? false : true
                    }
                    onClick={() => doneCollectingModal()}
                >
                    Done Collecting
                </Button>
                <Button
                    type="primary"
                    disabled={
                        orderData.orderStatus === "doneCollecting" ||
                        orderData.orderStatus === "invoiceIssued"
                            ? false
                            : true
                    }
                    onClick={() => updateCollectingStatus()}
                >
                    Keep Collecting
                </Button>
            </Space>
        ) : (
            <div></div>
        );

    const tag = (tag) => (
        <Tag color={tag ? "green" : "volcano"}>
            {tag ? "Received" : "Not Received"}
        </Tag>
    );
    const actions = (text, record) => (
        <Space size="middle">
            <a onClick={() => showModal(text)}>Edit</a>
            <a onClick={() => deleteTracking(text)}>Delete</a>
        </Space>
    );

    const text = (text) => <a>{text}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const title = () => (
        <div>
            {" "}
            Supplier Package Tracking List | Order #
            {orderData ? orderData.order_id : ""}
        </div>
    );

    const columns = [
        {
            title: "Tracking Number",
            dataIndex: "trackingNumber",
            key: "name",
            render: text,
        },

        {
            title: "Date Added",
            key: "dateAdded",
            dataIndex: "dateAdded",
            render: date,
        },
        {
            title: "Status",
            key: "isReceived",
            dataIndex: "isReceived",
            render: tag,
        },

        {
            title: "Action",
            key: "action",
            render: actions,
        },
    ];

    if (trackingNumbersStatus === "error") {
        return (
            <Table
                title={title}
                loading={trackingNumbersStatus === "loading" ? true : false}
                columns={columns}
                data={""}
            />
        );
    }

    return (
        <>
            <Table
                scroll={{ x: true }}
                title={title}
                loading={trackingNumbersStatus === "loading" ? true : false}
                columns={columns}
                pagination={false}
                dataSource={trackingNumbersData}
                footer={footer}
            />
            <Modal
                title={
                    doneCollecting
                        ? "Shipping and Invoice details"
                        : "Edit Tracking Details"
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                {doneCollecting ? (
                    <DoneCollectingForm
                        updateCollectingStatus={updateCollectingStatus}
                        orderRef={orderRef}
                        setIsModalVisible={setIsModalVisible}
                    />
                ) : (
                    <SamplesForwardingForm
                        userData={userData}
                        edit={true}
                        editData={editData}
                        setIsModalVisible={setIsModalVisible}
                    />
                )}
            </Modal>
        </>
    );
}

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

function DoneCollectingForm(props) {
    const { updateCollectingStatus, orderRef, setIsModalVisible } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        let cleanData = await clean(values);
        await updateCollectingStatus();
        await orderRef.update(
            {
                shippingAndInvoiceDetails: cleanData,
            },
            { merge: true },
        );
        setLoading(false);
        form.resetFields();
        setIsModalVisible(false);
        window.alert("Form Submitted!");
    };

    return (
        <>
            <h4>Shipping/Delivery Address and Contact Details</h4>
            <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"street"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Street/Building #"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={"city"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="City/Province"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="country"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Country"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="postalCode"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Postal Code"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="contactPerson"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Contact Person"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="contactNumber"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Contact Number"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="activeEmail"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Active Email Address"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="taxNumber"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    label="VAT/TVA/BTW/MWST Number"
                >
                    <Input />
                </Form.Item>
                <p style={{ paddingLeft: "110px" }}>
                    *For Europe Customers Only (UK not included)*
                </p>

                <h4>Invoice Details</h4>
                <p>
                    * For the first invoice. How much do you want us to declare
                    your package?. If you think that is your package exceeds
                    more than $10 please give us the exact amount that we will
                    do for the invoice.*
                </p>

                <p>
                    *For the second invoice, we will write the remaining amount
                    in your bill.*
                </p>

                <Form.Item
                    name="invoiceDeclaredValue"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Invoice Declared Value (USD)"
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name="invoiceProductDescription"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label={"Invoice Product Description "}
                >
                    <Input />
                </Form.Item>
                <p>
                    (E.g.: Home Accesories, Kitchen Utensils, Tools, etc. , or
                    “the Exact Product Name”)
                </p>

                <p>
                    *Product Description can be specific or exact and it will
                    not be written as “Samples”*
                </p>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading ? true : false}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
