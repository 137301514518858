import React from "react";
import { Card, Table } from "antd";
import {
    useFirestore,
    useFirestoreDocData,
    useFirestoreCollectionData,
} from "reactfire";
import { useHistory } from "react-router-dom";

export function SamplesForwardingOrderTable(props) {
    const { userData } = props;
    const order_id = userData.services_ids.samplesForwarding;
    const firestore = useFirestore();
    const history = useHistory();
    const ordersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .where("orderStatus", "==", "shipped");

    const { data: orderData, status: orderStatus } = useFirestoreCollectionData(
        ordersRef,
        {
            idField: "id",
        },
    );

    const swapOrder = async (data) => {
        await firestore
            .collection("users")
            .doc(userData.user_id)
            .set(
                {
                    services_ids: {
                        samplesForwarding: data.id,
                    },
                },
                { merge: "true" },
            );
        history.push({
            pathname: `samples-forwarding/${data.order_id}`,
            state: data,
        });
    };

    const text = (text, data) => (
        <a onClick={() => swapOrder(data)}>{`${text}${
            data.order_idExtension || ""
        }`}</a>
    );
    const trackingNum = (text) => <a>{text.internationalTrackingNumber}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const shippingDate = (date) => (
        <a>{date && date.dateIssued.toDate().toDateString()}</a>
    );

    // const newOrderData = orderData && [
    //   ...orderData,
    //   ...internationalTrackingDetails,
    // ];

    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            key: "name",
            render: text,
        },
        {
            title: "International Tracking Number",
            dataIndex: "internationalTrackingDetails",
            key: "name",
            render: trackingNum,
        },

        {
            title: "Date Ordered",
            key: "dateAdded",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Date Shipped",
            key: "dateAdded",
            dataIndex: "internationalTrackingDetails",
            sorter: (a, b) => a.dateIssued - b.dateIssued,
            defaultSortOrder: "descend",
            render: shippingDate,
        },
    ];
    return (
        <>
            <Table
                scroll={{ x: true }}
                title={() => "Order History"}
                columns={columns}
                loading={orderStatus === "loading" ? true : false}
                dataSource={orderData}
            ></Table>
        </>
    );
}
