import React, { useState } from "react";
import { Table, Layout, Space, Form, Input, Modal, Button } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
const { Content } = Layout;

export const AdminUsers = () => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userData, setUserData] = useState([]);
    const ordersRef = useFirestore().collection("users");

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const { data: ordersData, status: ordersStatus } =
        useFirestoreCollectionData(ordersRef, {
            idField: "id",
        });

    const renderName = (text, record) => (
        <Link
            to={{
                pathname: `/admin/users/${record.user_id}`,
            }}
        >
            {record.firstName + " " + record.lastName}
        </Link>
        // <a onClick={() => history.push(`/admin/${redirect()}/${record.id}`)}>
        //   {text}
        // </a>
    );

    const deleteUser = (id) => {
        if (window.confirm("Delete user?")) ordersRef.doc(id).delete();
    };
    const actions = (text, record) => (
        <Space>
            {" "}
            <a onClick={() => deleteUser(record.id)}>Delete</a>
            <a
                onClick={() => {
                    setIsModalVisible(true);
                    setUserData(record);
                }}
            >
                Add/Edit Ref Code
            </a>
        </Space>
    );

    const text = (text, record) => <a>{text}</a>;
    const renderText = (text, record) => (
        <a>
            {record.contactNumber &&
                `(${record?.prefix}) ${record?.contactNumber}`}
        </a>
    );
    // const renderText = (text) => <a>{text}</a>;
    const date = (date) => <a>{date.toDate().toDateString()}</a>;
    const columns = [
        {
            title: "Full Name",
            dataIndex: "firstName",
            render: renderName,
            defaultSortOrder: "",
            sorter: (a, b) => a.firstName < b.firstName,
        },
        {
            title: "Email",
            dataIndex: "email",
            render: text,
        },

        {
            title: "Password",
            dataIndex: "password",
            render: text,
        },

        {
            title: "Contact Number",
            dataIndex: "contactNumber",
            render: renderText,
        },
        {
            title: "Reference Code",
            dataIndex: "referenceCode",
            render: text,
            defaultSortOrder: "false",
            sorter: (a, b) => (b.referenceCode ? 1 : -1),
        },

        {
            title: "Date Registered",
            dataIndex: "dateRegistered",
            render: date,
            defaultSortOrder: "",
            sorter: (a, b) => a.dateRegistered - b.dateRegistered,
        },

        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];
    return (
        <div
            className="samples-layout"
            style={{
                padding: 24,
                minHeight: 360,
            }}
        >
            <Content>
                <Table bordered columns={columns} dataSource={ordersData} />
            </Content>

            <Modal
                title="Add Reference Code"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <RefCodeForm userData={userData} />
            </Modal>
        </div>
    );
};

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const layout = {
    labelCol: {
        span: 14,
    },
    wrapperCol: {
        span: 10,
    },
};

const RefCodeForm = (props) => {
    const { loading, userData } = props;
    const [form] = Form.useForm();
    const firestore = useFirestore();

    if (userData.referenceCode) {
        form.setFieldsValue({
            referenceCode: userData.referenceCode,
        });
    } else {
        form.resetFields();
    }

    const onFinish = (values) => {
        firestore.collection("users").doc(userData.id).update({
            referenceCode: values.referenceCode,
        });
    };

    return (
        <>
            <Form
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"referenceCode"}
                    label="Reference Code"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
