import React, { useContext, useState } from "react";
import {
    Layout,
    Breadcrumb,
    Card,
    Spin,
    Button,
    Row,
    Col,
    Modal,
    Form,
    Input,
    Space,
    DatePicker,
    Tag,
    Descriptions,
    Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
} from "reactfire";
import {
    AdminInvoiceTable,
    AdminInvoicePaymentsTable,
    AdminInvoiceForm,
    UserInfoCard,
} from "./components";
import { UserContext } from "../../context/authContext";
import CommentsSection from "../../components/CommentsSection";

const { Content } = Layout;
const { Paragraph, Text } = Typography;

const renderText = (text) => <a>{text}</a>;
const columns = [
    {
        title: "Tracking Number",
        dataIndex: "tracking",
        render: renderText,
    },
    {
        title: "Invoice Number",
        dataIndex: "invoice",
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: renderActions,
    // },
];
const data = [
    {
        key: "1",
        tracking: "SF12345678",
        invoice: "3001",
    },
    {
        key: "2",
        tracking: "SF12345678",
        invoice: "3002",
    },
    {
        key: "3",
        tracking: "SF12345678",
        invoice: "3003",
    },
];

export const AdminPreshipmentInspection = () => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record.orderedBy_id;
    const [editData, setEditData] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [loading, setLoading] = useState();
    const fieldValue = useFirestore.FieldValue;
    const firestore = useFirestore();
    const [ellipsis, setEllipsis] = React.useState(true);
    const { user } = useContext(UserContext);

    const orderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd");
    const { data: orderData, status: orderStatus } = useFirestoreDocData(
        orderRef,
        {
            idField: "id",
        },
    );

    const userInvoiceRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const userOrderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid);

    const paymentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd")
        .collection("payments");

    const adminRef = useFirestore().collection("admins").doc(user.uid);

    const commentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("comments");

    const { data: commentsData, status: commentsStatus } =
        useFirestoreCollectionData(commentsRef.orderBy("timeStamp", "asc"), {
            idField: "id",
        });

    const { data: adminData, status: adminStatus } = useFirestoreDocDataOnce(
        adminRef,
        {
            idField: "id",
        },
    );

    const { data: invoiceData, status: invoiceStatus } =
        useFirestoreCollectionData(userInvoiceRef, {
            idField: "id",
        });

    const { data: paymentsData, status: paymentsStatus } =
        useFirestoreCollectionData(paymentsRef, {
            idField: "id",
        });

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    if (
        paymentsStatus === "loading" ||
        invoiceStatus === "loading" ||
        orderStatus === "loading"
    ) {
        return (
            <div className="loader">
                <Spin></Spin>
            </div>
        );
    }

    const confirmPayment = async (data) => {
        await userInvoiceRef.doc(data.id).update({
            paymentStatus: "paid",
        });

        await userOrderRef.update({
            orderStatus: "invoicePaid",
        });
    };

    const deleteInvoice = async (data) => {
        await userInvoiceRef.doc(data.id).delete();
    };

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const issueInspectionDate = async (data) => {
        const inspectionDate = data.inspectionDate.toDate().toString();
        await setLoading(true);
        await userOrderRef.set(
            {
                orderStatus: "inspectionScheduled",
                inspectionDetails: {
                    inspectionDate: inspectionDate,
                },
            },
            { merge: true },
        );
        await window.alert("Inspection Date Issued!");
        await form.resetFields();
        await setLoading(false);
    };

    const issueInspectionReport = async (data) => {
        await setLoading(true);
        await userOrderRef.set(
            {
                orderStatus: "reportSubmitted",
                inspectionDetails: {
                    inspectionReport: data.inspectionReport,
                },
            },
            { merge: true },
        );
        await firestore
            .collection("users")
            .doc(userid)
            .set(
                {
                    services_ids: {
                        preShipmentInspection: "",
                    },
                },
                { merge: true },
            );
        await window.alert("Inspection Report Issued!");
        await form2.resetFields();
        await setLoading(false);
    };

    const tag = (tag) => (
        <Tag
            color={
                tag === "invoicePending"
                    ? "volcano"
                    : tag === "invoiceIssued"
                    ? "yellow"
                    : tag === "invoicePaid"
                    ? "green"
                    : tag === "inspectionScheduled"
                    ? "blue"
                    : "processing"
            }
        >
            {tag === "invoicePending"
                ? "Invoice Pending"
                : tag === "invoiceIssued"
                ? "Invoice(s) Issued"
                : tag === "invoicePaid"
                ? "Invoice(s) Paid"
                : tag === "inspectionScheduled"
                ? "Inspection Scheduled"
                : "Done"}
        </Tag>
    );

    return (
        <div className="samples-layout">
            <Content>
                <UserInfoCard orderData={orderData} tag={tag} />
                <Breadcrumb style={{ margin: "16px 0" }}>
                    {/* <Breadcrumb.Item>Samples Forwarding</Breadcrumb.Item> */}
                </Breadcrumb>
                {/* <Table bordered columns={columns} dataSource={data} /> */}
                <Row span={24}>
                    <Card>
                        <Descriptions>
                            <Descriptions.Item label="Plan">
                                {orderData?.plan}
                            </Descriptions.Item>
                            <Descriptions.Item label="Product Name">
                                {orderData?.orderDetails.productName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Product Picture">
                                <a
                                    onClick={() =>
                                        openNewTab(orderData?.pictureLink)
                                    }
                                >
                                    {orderData?.pictureFileName}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Factory Contact Info">
                                {orderData?.orderDetails.supplierContact}
                            </Descriptions.Item>
                            <Descriptions.Item label="Factory Address">
                                {orderData?.orderDetails.factoryAddress}
                            </Descriptions.Item>
                            <Descriptions.Item label="Product Details">
                                <Paragraph
                                    ellipsis={
                                        ellipsis
                                            ? {
                                                  rows: 2,
                                                  expandable: true,
                                                  symbol: "see more",
                                              }
                                            : false
                                    }
                                >
                                    {orderData?.orderDetails.productDetails}
                                    {/* <a
                    onClick={() => {
                      setEllipsis(!ellipsis);
                    }}
                  >
                    {ellipsis ? <a>see less</a> : <a>see less</a>}
                  </a> */}
                                </Paragraph>
                            </Descriptions.Item>
                            <Descriptions.Item label="Contract">
                                <a
                                    onClick={() =>
                                        openNewTab(orderData?.contractLink)
                                    }
                                >
                                    {orderData?.contractFileName}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Company Details">
                                {orderData?.orderDetails.companyDetails}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Row>

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={24}>
                        <Card
                            title="Invoice Details"
                            bodyStyle={{ padding: "0" }}
                            bordered
                            extra={
                                <Button
                                    onClick={() => {
                                        setIsModalVisible(true);
                                        setEditStatus(false);
                                    }}
                                >
                                    Add Invoice
                                </Button>
                            }
                        >
                            <AdminInvoiceTable
                                invoiceData={invoiceData}
                                setIsModalVisible={setIsModalVisible}
                                setEditStatus={setEditStatus}
                                setEditData={setEditData}
                                confirmPayment={confirmPayment}
                                deleteInvoice={deleteInvoice}
                            />
                        </Card>
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={24}>
                        <AdminInvoicePaymentsTable paymentData={paymentsData} />
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
                        <Card title={"Inspection Schedule"} bordered>
                            <Form onFinish={issueInspectionDate} form={form}>
                                <Space>
                                    <Form.Item
                                        name={"inspectionDate"}
                                        label="Issue Inspection Date"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker showTime />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : false}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                            <div>
                                Inspection Date:{" "}
                                {orderData?.inspectionDetails?.inspectionDate}
                            </div>
                        </Card>
                    </Col>
                    <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
                        <Card title={"Inspection Report"} bordered>
                            <Form onFinish={issueInspectionReport} form={form2}>
                                <Space>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        name={"inspectionReport"}
                                        label="Issue Inspection Report Link"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading ? true : false}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                            <div>
                                Inspection Report Link:{" "}
                                {orderData?.inspectionDetails?.inspectionReport}
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 20 }}
                    title={"Comments"}
                >
                    <CommentsSection
                        commentsData={commentsData}
                        orderData={orderData}
                        authorName={adminData?.name}
                    />
                </Card>
            </Content>

            <Modal
                title={editStatus ? "Edit Invoice" : "Add Invoice"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <AdminInvoiceForm
                    orderData={orderData}
                    orderid={orderid}
                    edit={editStatus}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </div>
    );
};
