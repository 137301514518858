import React, { useState } from "react";
import {
  Layout,
  Descriptions,
  Card,
  Spin,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Upload,
  Space,
  DatePicker,
  Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage,
} from "reactfire";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { orders } from "./dummydata";
import {Charges} from './Charges'
import { UserInfoCard } from "../components";

const { Content } = Layout;

export const BaseCalculation = ({baseCharge}) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState();
  const [uploads, setUploads] = useState([]);
  const fieldValue = useFirestore.FieldValue;
  const firestore = useFirestore();
  const storage = useStorage();
  const { record } = useLocation().state;
  const orderid = record.id;
  const userid = record?.orderedBy_id;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const orderRef = useFirestore()
    .collection("users")
    .doc(userid)
    .collection("orders")
    .doc(orderid || '');


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function getChargeFromDays(days){
      switch(true){
        case (days<15):
            return 0;
        case (days>15 && days<31):
            return 1.5;
        case (days>30 && days<61):
            return 2; 
        case (days>60 && days<91):
            return 3; 
        case (days>90 && days<181):
            return 3.5; 
        case (days>180 && days<241):
            return 4; 
        case (days>240 && days<361):
            return 4.5; 
        case (days>360 && days<451):
            return 5;
        case (days>450):
            return 5.5; 
        default: 
            return 0;
      }
  }


  function editCell (params){
    const {data} = params
    const chargeFromDays = getChargeFromDays(parseInt(data.numberOfDaysInStorage))
    const chargeTotal = chargeFromDays * parseFloat(data.plt);
    orderRef.update({
        currentBaseCharge: parseFloat(chargeTotal),
        baseCharge:{
            numberOfDaysInStorage: parseInt(data.numberOfDaysInStorage),
            plt: parseFloat(data.plt),
            currentBaseCharge: parseFloat(chargeTotal),
        }
    })
  }

  function formatNumber(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const baseChargeData = [baseCharge]

  function currencyFormatter(params) {
    const number = parseFloat(params.value);
    return '\x24' + number.toFixed(2) || 0;
  }

  return (
    <>
          <Card
          bordered
          style={{ marginTop: "20px" }}
          bodyStyle={{ padding: 10 }}
          title={"Base Calculations"}
        >
            <div className="ag-theme-alpine" style={{height: 93, width: '100%'}}>
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 110,
                editable: true,
                resizable: true,
              }}
              onGridReady={onGridReady}
              rowData={baseChargeData}
              stopEditingWhenCellsLoseFocus={true}
            >
                <AgGridColumn field="numberOfDaysInStorage" headerName="Number of days in Storage" onCellValueChanged={editCell}/>
                <AgGridColumn field="plt" headerName="PLT" onCellValueChanged={editCell} />
                <AgGridColumn field="currentBaseCharge" headerName="Current Base Charge" onCellValueChanged={editCell} valueFormatter={currencyFormatter} />
         </AgGridReact>
         </div>
        </Card>
    </>
  );
};
