import React, { useContext } from "react";
import { UserContext } from "../../context/authContext";
import { Spin, Row, Col, Layout, Card } from "antd";
import {
    UserInvoicePaymentTable,
    ShippingForm,
    ShippingOrderTable,
} from "./components";

export function UserShipping(props) {
    const { user, userData } = useContext(UserContext);
    if (!user || !userData) {
        return (
            <div className="loader">
                <Spin />
            </div>
        );
    }
    return (
        <Row gutter={[16, 16]}>
            <Col span={6} xs={24} xl={24} xxl={10}>
                <Card
                    title={<div>Form</div>}
                    style={{ border: "1px solid lightgrey" }}
                    bordered
                    // dataSource={data}
                    // renderItem={(item) => <List.Item>{item}</List.Item>}
                >
                    <ShippingForm edit={false} userData={userData} />
                </Card>
            </Col>

            <Col span={6} xs={24} xl={24} xxl={14}>
                <Row gutter={[16, 16]}>
                    <Col span={6} xs={24} xl={24}>
                        <UserInvoicePaymentTable
                            userData={userData}
                            order_id={userData?.services_ids?.shipping}
                        />
                    </Col>
                    {/* <Col span={12} xs={24} xl={12}>
            <List
              header={<div>Shipments Tracking</div>}
              bordered
              dataSource={data}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col> */}
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={6} xs={24} xl={24}>
                        <Card
                            bodyStyle={{ padding: "0" }}
                            bordered
                            style={{ border: "1px solid lightgrey" }}
                        >
                            <ShippingOrderTable
                                userData={userData}
                                order_id={userData?.services_ids?.shipping}
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
