import React, { useState } from "react";
import { Table, Col, Space, Tag, Card, Row, Modal, Button } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { AdminAddAdminForm } from "./components/Forms";
export function AdminAssignAdmins(props) {
  const firestore = useFirestore();
  const [editStatus, setEditStatus] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState();

  const adminsRef = useFirestore().collection("admins");

  const { data: adminsData, status: adminsStatus } = useFirestoreCollectionData(
    adminsRef,
    {
      idField: "id",
    }
  );

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const deleteAdmin = (id) => {
    if (window.confirm("Delete admin?"))
      firestore.collection("admins").doc(id).delete();
  };

  const renderText = (text, record) => <a>{text}</a>;

  const date = (date) => <a>{date && date.toDate().toDateString()}</a>;

  const actions = (text, record) => (
    <Space>
      <a onClick={() => deleteAdmin(record.id)}>Delete</a>
    </Space>
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: renderText,
    },

    {
      title: "Email",
      dataIndex: "email",
      render: renderText,
    },

    {
      title: "Date Added",
      dataIndex: "dateAssigned",
      sorter: (a, b) => a.dateAssigned - b.dateAssigned,
      defaultSortOrder: "descend",
      render: date,
    },

    {
      title: "Role",
      dataIndex: "role",
      render: renderText,
    },
    {
      title: "Actions",
      key: "action",
      render: actions,
    },
  ];

  return (
    <Row>
      <Col xxl={24}>
        <Card
          title="Admins"
          bodyStyle={{ padding: "0" }}
          bordered
          extra={
            <Button
              onClick={() => {
                setIsModalVisible(true);
                setEditStatus(false);
              }}
            >
              Add Admin
            </Button>
          }
        >
          <Table
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       history.push("/admin/samples-forwarding/orderid");
            //     }, // click row
            //   };
            // }}
            bordered
            scroll={{ x: true }}
            columns={columns}
            dataSource={adminsData}
            loading={adminsStatus === "loading" ? true : false}
          />
        </Card>
      </Col>

      <Modal
        title={editStatus ? "Edit Invoice" : "Add Admin"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AdminAddAdminForm
          edit={editStatus}
          editData={editData}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>
    </Row>
  );
}
