import React, { useContext } from "react";
import { UserContext } from "../../context/authContext";
import { List, Row, Col, Card, Spin } from "antd";
import {
    SamplesForwardingForm,
    SamplesForwardingTrackingTable,
    UserInvoicePaymentTable,
    SamplesForwardingOrderTable,
} from "./components";
const step1 = (
    <div>
        <div>
            {" "}
            1. Request your supplier to send your samples using SF Express to
            the following address:
        </div>
        <div style={{ marginTop: "10px" }}>English Address:</div>
        <div style={{ marginTop: "10px" }}>
            4-1, City Renhe Center, #58, Qizha Street, Haishu District, Ningbo,
            Zhejiang, China, 315000
        </div>
        <div style={{ marginTop: "10px" }}>Chinese Version:</div>

        <div style={{ marginTop: "10px" }}>
            浙江省宁波市海曙区碶闸街58号 都市仁和中心 4楼 4-1,
            宁波欧赛供应链管理有限公司，王娟，18167215211 (可接受到付）
        </div>
    </div>
);
const textGuide = [
    step1,
    '2. Submit the tracking number of each supplier through the form "Sample Form." ',
    "3. The tracking number will be generated automatically under the form. You can delete or edit at any time.",
    '4. The Tracking status will be marked "Received" after we received the package(s)',
    "5. After submitted all the tracking numbers you got from your supplier(s), Please click Done Collecting; our finance team will update the Invoice at the Invoice area (top-right corner).",
    "6. After you paid the Invoice, We will update the International tracking number.",
    "For further questions, you can email: sales2@b2csourcing.com",
];

export function UserSamplesForwarding(props) {
    const { user, userData } = useContext(UserContext);
    if (!user || !userData) {
        return (
            <div className="loader">
                <Spin />
            </div>
        );
    }

    return (
        <Row Row gutter={16}>
            <Col span={6} xs={24} xl={6} xxl={6}>
                <Col span={24}>
                    <List
                        header={<div>Samples Forwarding Guide</div>}
                        bordered
                        dataSource={textGuide}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                </Col>
            </Col>

            <Col span={6} xs={24} xl={18}>
                <Row gutter={[16, 16]}>
                    <Col span={6} xs={24} lg={24} xl={24} xxl={12}>
                        <Card
                            title="Sample Form"
                            style={{ border: "1px solid lightgrey" }}
                        >
                            <SamplesForwardingForm userData={userData} />
                        </Card>
                    </Col>
                    <Col span={8} xs={24} lg={24} xl={24} xxl={12}>
                        <Card bodyStyle={{ padding: "0" }}>
                            <UserInvoicePaymentTable
                                userData={userData}
                                order_id={
                                    userData.services_ids?.samplesForwarding
                                }
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} lg={24} xl={24} xxl={12}>
                        <Card
                            bodyStyle={{ padding: "0" }}
                            bordered
                            style={{ border: "1px solid lightgrey" }}
                        >
                            <SamplesForwardingTrackingTable
                                userData={userData}
                            />
                        </Card>
                    </Col>

                    <Col span={6} xs={24} lg={24} xl={24} xxl={12}>
                        <Card
                            bodyStyle={{ padding: "0" }}
                            bordered
                            style={{ border: "1px solid lightgrey" }}
                        >
                            <SamplesForwardingOrderTable userData={userData} />
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
