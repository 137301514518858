import React from "react";
import { Card, Descriptions, List } from "antd";

export function OrderDetails({ orderDetails, orderData }) {
    const labelUploads = orderData?.labelUploads;

    return (
        <>
            <Card title={"Order Details"}>
                <Descriptions bordered>
                    <Descriptions.Item>
                        Goods Description:{" "}
                        {orderDetails?.goodsDescription || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Product Photo:{" "}
                        {(
                            <a
                                href={orderData?.pictureLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                            >
                                {orderData?.pictureFilename}
                            </a>
                        ) || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Storage Location: {orderDetails?.storageLocation || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Carton Height: {orderDetails?.cartonHeight || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Carton Length: {orderDetails?.cartonLength || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Carton Width: {orderDetails?.cartonWidth || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Estimated Cubic Meters:{" "}
                        {orderDetails?.estimatedCubicMeters || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Estimated Weight: {orderDetails?.estimatedWeight || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Pickup: {orderDetails?.shouldPickup ? "Yes" : "No"}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Estimated Total Cartons:{" "}
                        {orderDetails?.estimatedTotalCartons || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Tracking Number: {orderDetails?.trackingNumber || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Customer Issued ETD:{" "}
                        {orderDetails?.customerIssuedEtd || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Customer Issued ETA:{" "}
                        {orderDetails?.customerIssuedEta || ""}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Customer Issued Tracking Number:{" "}
                        {orderDetails?.customerIssuedTrackingNumber || ""}
                    </Descriptions.Item>
                    {orderDetails?.shouldPickup && (
                        <>
                            <Descriptions.Item>
                                Supplier Contact:{" "}
                                {orderDetails?.supplierContact || ""}
                            </Descriptions.Item>
                            <Descriptions.Item>
                                Supplier Number:{" "}
                                {orderDetails?.supplierNumber || ""}
                            </Descriptions.Item>
                        </>
                    )}

                    <Descriptions.Item>
                        Label Uploads:
                        <List
                            size={"default"}
                            dataSource={labelUploads}
                            itemLayout="horizontal"
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                download={item?.fileName}
                                                style={{
                                                    color: "#FF9900",
                                                    fontWeight: "normal",
                                                }}
                                                href={item?.url}
                                            >
                                                {item?.fileName}
                                            </a>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
