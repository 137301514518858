import React, { useState } from "react";
import {
    Table,
    Col,
    Space,
    Tag,
    Card,
    Row,
    Modal,
    Button,
    Form,
    Input,
} from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const validateMessages = {
    required: "${label} is required!",
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 7 },
        sm: { span: 20, offset: 7 },
    },
};

function AddCategoryForm(props) {
    const { edit, orderData, editData, setIsModalVisible, orderid } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const firestore = useFirestore();
    const timestamp = useFirestore.FieldValue.serverTimestamp();
    const { record } = useLocation().state;

    const addSubcategory = async (values) => {
        await firestore.collection("subcategories").add({
            name: values.name,
            dateAdded: timestamp,
            category_id: record.id,
            categoryName: record.name,
            group: values.group ? values.group : "",
        });
    };
    const onFinish = async (values) => {
        await setLoading(true);
        await addSubcategory(values);
        await form.resetFields();
        await setLoading(false);
        await setIsModalVisible(false);
    };
    return (
        <Form
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
        >
            <Form.Item
                name={"name"}
                label="Subcategory Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.List
                name="group"
                // rules={[
                //   {
                //     validator: async (_, names) => {
                //       if (!names || names.length < 2) {
                //         return Promise.reject(new Error("At least 2 passengers"));
                //       }
                //     },
                //   },
                // ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0
                                    ? formItemLayout
                                    : formItemLayoutWithOutLabel)}
                                label={index === 0 ? "Group" : ""}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message:
                                                "Please input subcategory or delete this field.",
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        placeholder="Group Item"
                                        style={{ width: "60%" }}
                                    />
                                </Form.Item>
                                {fields.length >= 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item wrapperCol={{ offset: 6 }}>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "60%" }}
                                icon={<PlusOutlined />}
                            >
                                Add Subcategory
                            </Button>
                            {/* <Form.ErrorList errors={errors} /> */}
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}

export function AdminSubcategories(props) {
    const firestore = useFirestore();
    const [editStatus, setEditStatus] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState();
    const { record } = useLocation().state;

    const subcategoriesRef = useFirestore()
        .collection("subcategories")
        .where("category_id", "==", record.id);

    const { data: subcategoriesData, status: subcategoriesStatus } =
        useFirestoreCollectionData(subcategoriesRef, {
            idField: "id",
        });

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const deleteSubcategory = (id) => {
        if (window.confirm("Delete subcategory?"))
            firestore
                .collection("categories")
                .doc(record.id)
                .collection("subcategories")
                .doc(id)
                .delete();
    };

    const renderText = (text, record) => <a>{text}</a>;

    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;

    const actions = (text, record) => (
        <Space>
            <a onClick={() => deleteSubcategory(record.id)}>Delete</a>
        </Space>
    );
    const columns = [
        {
            title: "Subcategory Name",
            dataIndex: "name",
            render: renderText,
        },

        {
            title: "Date Added",
            dataIndex: "dateAdded",
            sorter: (a, b) => a.dateAssigned - b.dateAssigned,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];

    return (
        <>
            <Row>
                <Col span={24} xxl={24}>
                    <Card
                        title={record.name}
                        bodyStyle={{ padding: "0" }}
                        bordered
                        extra={
                            <Button
                                onClick={() => {
                                    setIsModalVisible(true);
                                    setEditStatus(false);
                                }}
                            >
                                Add Subcategory
                            </Button>
                        }
                    >
                        <Table
                            bordered
                            scroll={{ x: true }}
                            columns={columns}
                            dataSource={subcategoriesData}
                            loading={
                                subcategoriesStatus === "loading" ? true : false
                            }
                        />
                    </Card>
                </Col>

                <Modal
                    title={editStatus ? "Edit" : "Add Subcategory"}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AddCategoryForm
                        edit={editStatus}
                        editData={editData}
                        setIsModalVisible={setIsModalVisible}
                    />
                </Modal>
            </Row>
        </>
    );
}
