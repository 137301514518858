import { createContext } from "react";

export const UserContext = createContext({
  user: null,
  userData: null,
  userClaims: null,
});

export const CategoriesContext = createContext({
  categories: [null],
  subcategories: [null],
  subcategorygroup: [null],
});
