import React from "react";
import { Card, Button, List } from "antd";

export default function Plans({ plan, setPlan }) {
  return (
    <>
      <div className="plans-container">
        <Card
          title="Basic: 99$"
          style={{
            border: "1px solid lightgrey",
            width: "100%",
          }}
          bodyStyle={{ minHeight: "250px" }}
          actions={[
            plan === "Basic" ? (
              <Button
                onClick={() => setPlan("Basic")}
                type="primary"
                style={{ background: "#FF9900", border: "none" }}
              >
                Booked
              </Button>
            ) : (
              <Button onClick={() => setPlan("Basic")} type="primary">
                {" "}
                Book
              </Button>
            ),
          ]}
        >
          <ul style={{ width: "100%", textAlign: "left" }}>
            <li>Inspection Report</li>
            <li>24 hrs Inspection Report Result</li>
          </ul>
        </Card>
        <Card
          title="Standard: 129$"
          style={{
            border: "1px solid lightgrey",
            width: "100%",
          }}
          bodyStyle={{ minHeight: "250px" }}
          actions={[
            plan === "Standard" ? (
              <Button
                onClick={() => setPlan("Standard")}
                type="primary"
                style={{ background: "#FF9900", border: "none" }}
              >
                Booked
              </Button>
            ) : (
              <Button onClick={() => setPlan("Standard")} type="primary">
                {" "}
                Book
              </Button>
            ),
          ]}
        >
          <ul style={{ textAlign: "left" }}>
            <li>Inspection Report</li>
            <li>FBA Audit Report (based on the Amazon FBA Standard</li>
            <li>24 hrs Inspection Report Result</li>
          </ul>
        </Card>
        <Card
          title="Premium: 149$"
          style={{ border: "1px solid lightgrey", width: "100%" }}
          bodyStyle={{ minHeight: "250px" }}
          actions={[
            plan === "Premium" ? (
              <Button
                onClick={() => setPlan("Premium")}
                type="primary"
                style={{ background: "#FF9900", border: "none" }}
              >
                Booked
              </Button>
            ) : (
              <Button onClick={() => setPlan("Premium")} type="primary">
                {" "}
                Book
              </Button>
            ),
          ]}
        >
          <ul style={{ width: "100%", textAlign: "left" }}>
            <li>Inspection Report</li>
            <li>FBA Audit Report (based on the Amazon FBA Standard</li>
            <li>Videos</li>
            <li>12 hrs Inspection Report Result</li>
          </ul>
        </Card>
      </div>
    </>
  );
}
