import React, { useState } from "react";
import { Table, Space, Modal } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { ShippingForm } from "../Forms";
import { useHistory } from "react-router-dom";

export function ShippingOrderTable(props) {
    const { userData } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState([]);
    const firestore = useFirestore();
    const history = useHistory();

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const ordersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .where("serviceType", "==", "shipping");

    const { data: orderData, status: orderStatus } = useFirestoreCollectionData(
        ordersRef,
        {
            idField: "id",
        },
    );

    const showModal = (data) => {
        if (orderData.orderStatus !== "proceed") {
            setIsModalVisible(true);
            setEditData(data);
        }
    };

    const orderProceed = async (data) => {
        if (data.orderStatus === "quotationIssued") {
            if (window.confirm("Proceed with your order?")) {
                await firestore
                    .collection("users")
                    .doc(userData.user_id)
                    .collection("orders")
                    .doc(data.id)
                    .update({ orderStatus: "orderProceeded" });

                // await firestore
                //   .collection("users")
                //   .doc(userData.user_id)
                //   .set(
                //     {
                //       services_ids: {
                //         shipping: "",
                //       },
                //     },
                //     { merge: "true" }
                //   );
                window.alert(
                    "Order Proceeded, please wait for your invoice to be issued",
                );
            }
        } else {
            window.alert("Quotation not yet issued");
        }
    };

    const swapOrder = async (data) => {
        await firestore
            .collection("users")
            .doc(userData.user_id)
            .set(
                {
                    services_ids: {
                        shipping: data.id,
                    },
                },
                { merge: "true" },
            );
        history.push({
            pathname: `shipping/${data.order_id}`,
            state: data,
        });
    };

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const text = (text, data) => (
        <a onClick={() => swapOrder(data)}>{`${text}${
            data.order_idExtension || ""
        }`}</a>
    );
    const schedule = (text) => <a>{text?.inspectionDate}</a>;
    const report = (text, data) => (
        <a onClick={() => openNewTab(data?.quotationFile)}>{text}</a>
    );
    const etd = (text) => <a>{text?.etd}</a>;
    const eta = (text) => <a>{text?.eta}</a>;
    const trackingNum = (text) => <a>{text.internationalTrackingNumber}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const shippingDate = (date) => (
        <a>{date && date.dateIssued.toDate().toDateString()}</a>
    );
    const actions = (text, record) => (
        <Space size="middle">
            {text.orderStatus === "quotationIssued" ||
            text.orderStatus === "quotationPending" ? (
                <a onClick={() => showModal(text)}>Edit</a>
            ) : (
                <div />
            )}

            {text.orderStatus === "quotationIssued" ? (
                <a onClick={() => orderProceed(text)}>Proceed</a>
            ) : (
                <div />
            )}
        </Space>
    );

    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            key: "name",
            render: text,
        },
        {
            title: "Date Ordered",
            key: "dateAdded",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },
        {
            title: "Quotation File",
            dataIndex: "quotationFileName",
            key: "name",
            render: report,
        },
        {
            title: "ETD",
            dataIndex: "shippingDetails",
            key: "name",
            render: etd,
        },
        {
            title: "ETA",
            dataIndex: "shippingDetails",
            key: "name",
            render: eta,
        },

        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];
    return (
        <>
            <Table
                scroll={{ x: true }}
                columns={columns}
                loading={orderStatus === "loading" ? true : false}
                dataSource={orderData}
            ></Table>

            <Modal
                width={800}
                title="Edit Shipping Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <ShippingForm
                    userData={userData}
                    edit={true}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
}
