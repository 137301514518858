import React, { useContext } from "react";
import { Navbar } from "./Navbar";
import { Layout, Input, Menu } from "antd";
import {
  Link,
  useRouteMatch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { UserContext } from "../../../../context/authContext";
import {
  DesktopOutlined,
  PieChartOutlined,
  SearchOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { FaBoxes, FaWarehouse } from 'react-icons/fa';
import { FcInspection } from "react-icons/fc";
import { RiShip2Fill } from "react-icons/ri";
import { IoArchiveOutline } from "react-icons/io5";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

export const AgentLayout = ({ children }) => {
  const { userClaims, userData } = useContext(UserContext);
  let { url } = useRouteMatch();
  let location = useLocation();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider breakpoint="lg" collapsedWidth="0" width="250px">
        <div className="logo">
          <img alt="" className="layout-logo" src="/assets/b2c-white.svg"></img>
        </div>
        <Menu
          style={{ marginTop: "16px" }}
          theme="dark"
          // style={{ background: " #4E5468" }}
          defaultSelectedKeys={["1"]}
          selectedKeys={[location.pathname]}
          mode="vertical"
        >
          {/* <Menu.Item key="/admin" icon={<PieChartOutlined />}>
            <span style={{ flexGrow: "1" }}>Dashboard</span>
            <Link to="/admin"></Link>
          </Menu.Item> */}

          <Menu.Item
            key={`/admin/samples-forwarding`}
            icon={<FaBoxes style={{ marginRight: "10px" }} />}
          >
            <Link
              to={`${url}/samples-forwarding`}
              style={{ textDecoration: "none" }}
            ></Link>
            Samples Forwarding
          </Menu.Item>

          <Menu.Item
            key="/admin/pre-shipment-inspection"
            icon={<FcInspection style={{ marginRight: "10px" }} />}
          >
            <Link
              to={`${url}/pre-shipment-inspection`}
              style={{ textDecoration: "none" }}
            />
            Pre-Shipment Inspection
          </Menu.Item>
          <Menu.Item
            key="/admin/shipping"
            icon={<RiShip2Fill style={{ marginRight: "10px" }} />}
          >
            <Link to={`${url}/shipping`} style={{ textDecoration: "none" }} />
            Shipping
          </Menu.Item>

          <Menu.Item
            key="/admin/sourcing"
            icon={<DesktopOutlined style={{ marginRight: "10px" }} />}
          >
            <Link to={`${url}/sourcing`} style={{ textDecoration: "none" }} />
            Sourcing
          </Menu.Item>

          <Menu.Item
            key="/admin/storage"
            icon={<FaWarehouse style={{ marginRight: "10px" }} />}
          >
            <Link to={`${url}/storage`} style={{ textDecoration: "none" }} />
            Storage
          </Menu.Item>

          {/* <Menu.Item
            key="/admin/archived"
            icon={<IoArchiveOutline style={{ marginRight: "10px" }} />}
          >
            <Link to={`${url}/archived`} style={{ textDecoration: "none" }} />
            Archived
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            background: "#fff",
            padding: 16,
            display: "flex",
            float: "right",
            alignItems: "center",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <Navbar userData={userData && userData} />
        </Header>
        <Content>
          {" "}
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
