import React, { useState } from "react";
import { Descriptions, Card } from "antd";
import { useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import CommentsSection from "../../components/CommentsSection";
import ShippingDescriptions from "./components/Descriptions/ShippingDescriptions";
import SamplesForwardingDescriptions from "./components/Descriptions/SamplesForwardingDescriptions";
import PreShipmentInspectionDescriptions from "./components/Descriptions/PreShipmentInspectionDescriptions";
import SourcingDescriptions from "./components/Descriptions/SourcingDescriptions";
import StorageDescriptions from "./components/Descriptions/StorageDescriptions";

export default function UserOrderDetails({ service }) {
    const orderData = useLocation().state;
    const orderDetails = orderData?.orderDetails;

    const commentsRef = useFirestore()
        .collection("users")
        .doc(orderData?.orderedBy_id || "asd")
        .collection("orders")
        .doc(orderData.id ? orderData.id : "asd")
        .collection("comments");

    const { data: commentsData, status: orderStatus } =
        useFirestoreCollectionData(commentsRef.orderBy("timeStamp", "asc"), {
            idField: "id",
        });

    if (!orderData) {
        return <></>;
    }

    const orderDescriptions = () => {
        switch (service) {
            case "shipping":
                return <ShippingDescriptions orderData={orderData} />;
            case "samplesForwarding":
                return <SamplesForwardingDescriptions orderData={orderData} />;
            case "preShipmentInspection":
                return (
                    <PreShipmentInspectionDescriptions orderData={orderData} />
                );
            case "sourcing":
                return <SourcingDescriptions orderData={orderData} />;
            case "storage":
                return <StorageDescriptions orderData={orderData} />;

            default:
                return <></>;
        }
    };

    return (
        <>
            {orderDescriptions()}
            <Card
                bordered
                style={{ marginTop: "20px" }}
                bodyStyle={{ padding: 20 }}
                title={"Comments"}
            >
                <CommentsSection
                    commentsData={commentsData}
                    orderData={orderData}
                    authorName={orderData?.orderedBy_name}
                />
            </Card>
        </>
    );
}
