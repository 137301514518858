import React, { useContext } from "react";
import { Navbar } from "./Navbar";
import { Layout, Input, Menu, SubMenu } from "antd";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { UserContext } from "../../../../context/authContext";
import {
    DesktopOutlined,
    PieChartOutlined,
    SearchOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { Widget } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";

import { FaBoxes, FaWarehouse } from "react-icons/fa";
import { FcInspection } from "react-icons/fc";
import { RiShip2Fill } from "react-icons/ri";
import MessengerCustomerChat from "react-messenger-customer-chat";

const { Header, Sider, Content } = Layout;

export const UserLayout = ({ children }) => {
    const { user, userData } = useContext(UserContext);
    let { url } = useRouteMatch();
    let location = useLocation();
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <MessengerCustomerChat
                pageId="235810393699210"
                appId="509748310829732"
            />
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                width="250px"
            >
                <div className="logo">
                    <img
                        alt=""
                        className="layout-logo"
                        src="/assets/b2c-white.svg"
                    ></img>
                </div>
                {/* <div className="logo">
        <Input
          style={{ height: "40px" }}
          size="small"
          placeholder="Search"
          prefix={<SearchOutlined />}
        />
      </div> */}
                <Menu
                    theme="dark"
                    style={{ marginTop: "16px" }}
                    defaultSelectedKeys={["/dashboard"]}
                    selectedKeys={[location.pathname]}
                    mode="inline"
                >
                    <Menu.Item key="/" icon={<PieChartOutlined />}>
                        <span style={{ flexGrow: "1" }}>Dashboard</span>
                        {/* <RightOutlined /> */}
                        <Link to="/"></Link>
                    </Menu.Item>

                    <Menu.Item
                        key="/samples-forwarding"
                        icon={<FaBoxes style={{ marginRight: "10px" }} />}
                    >
                        <Link
                            to={`/samples-forwarding`}
                            style={{ textDecoration: "none" }}
                        ></Link>
                        Samples Forwarding
                        {/* <RightOutlined /> */}
                    </Menu.Item>

                    <Menu.Item
                        key="/pre-shipment-inspection"
                        icon={<FcInspection style={{ marginRight: "10px" }} />}
                    >
                        <Link
                            to={`/pre-shipment-inspection`}
                            style={{ textDecoration: "none" }}
                        />
                        Pre-Shipment Inspection
                        {/* <RightOutlined /> */}
                    </Menu.Item>
                    <Menu.Item
                        key="/shipping"
                        icon={<RiShip2Fill style={{ marginRight: "10px" }} />}
                    >
                        <Link
                            to={`/shipping`}
                            style={{ textDecoration: "none" }}
                        />
                        Shipping
                        {/* <RightOutlined /> */}
                    </Menu.Item>

                    <Menu.Item
                        key="/sourcing"
                        icon={
                            <DesktopOutlined style={{ marginRight: "10px" }} />
                        }
                    >
                        <Link
                            to={`/sourcing`}
                            style={{ textDecoration: "none" }}
                        />
                        Sourcing
                        {/* <RightOutlined /> */}
                    </Menu.Item>

                    <Menu.Item
                        key="/storage"
                        icon={<FaWarehouse style={{ marginRight: "10px" }} />}
                    >
                        <Link
                            to={`/storage`}
                            style={{ textDecoration: "none" }}
                        />
                        Storage
                        {/* <RightOutlined /> */}
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        background: "#fff",
                        padding: 16,
                        display: "flex",
                        float: "right",
                        alignItems: "center",
                        borderBottom: "1px solid lightgrey",
                    }}
                >
                    <Navbar userData={userData && userData} />
                </Header>
                <Content>
                    {" "}
                    <div
                        className="site-layout-background"
                        style={{ padding: 24, minHeight: 360 }}
                    >
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
