import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/authContext";
import { AdminLayout } from "./components/Layout/AdminLayout";
import Layout from "./components/Layout";
import { Route, Switch, useRouteMatch, Link } from "react-router-dom";
import { Result, Button } from "antd";

import {
    AdminOrders,
    AdminDashboard,
    AdminPreshipmentInspection,
    AdminSamplesForwarding,
    AdminShipping,
    AdminUsers,
    AdminUserOrders,
    AdminArchived,
    AdminAssignAdmins,
    AdminCategories,
    AdminSubcategories,
    AdminProducts,
    AdminProductInfo,
    AdminSourcing,
    AdminStorage,
} from "../admin";

import { Subcategories } from "./components/Routes/Subcategories";

export function Admin() {
    const { path } = useRouteMatch();
    const { userClaims } = useContext(UserContext);

    if (!userClaims.admin && !userClaims.superAdmin && !userClaims.agent) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={
                    <Link to="/" style={{ color: "white" }}>
                        <Button type="primary"> Back Home</Button>
                    </Link>
                }
            />
        );
    }
    return (
        <>
            <Layout>
                <Switch>
                    {" "}
                    <Route exact path={`${path}/`}>
                        <AdminDashboard />
                    </Route>
                    <Route
                        path={`${path}/samples-forwarding`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminOrders
                                        service={"samplesForwarding"}
                                    />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:orderid`}
                                    component={AdminSamplesForwarding}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/pre-shipment-inspection`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminOrders
                                        service={"preShipmentInspection"}
                                    />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:orderid`}
                                    component={AdminPreshipmentInspection}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/shipping`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminOrders service={"shipping"} />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:orderid`}
                                    component={AdminShipping}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/sourcing`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminOrders service={"sourcing"} />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:orderid`}
                                    component={AdminSourcing}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/storage`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminOrders service={"storage"} />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:orderid`}
                                    component={AdminStorage}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/archived`}
                        render={({ match: { url } }) => (
                            <>
                                <Route
                                    path={`${url}/`}
                                    component={AdminArchived}
                                    exact
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/users`}
                        render={({ match: { url } }) => (
                            <>
                                <Route
                                    path={`${url}/`}
                                    component={AdminUsers}
                                    exact
                                />
                                <Route
                                    path={`${url}/:userid`}
                                    component={AdminUserOrders}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/assign-admins`}
                        render={({ match: { url } }) => (
                            <>
                                <Route
                                    path={`${url}/`}
                                    component={AdminAssignAdmins}
                                    exact
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/categories`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminCategories />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:categoryid`}
                                    component={AdminSubcategories}
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/products`}
                        render={({ match: { url } }) => (
                            <>
                                <Route path={`${url}/`} exact>
                                    <AdminProducts />{" "}
                                </Route>
                                <Route
                                    path={`${url}/:categoryid`}
                                    component={AdminProducts}
                                    exact
                                />
                                <Route
                                    path={`${url}/:categoryid/:subcategoryid`}
                                    component={AdminProducts}
                                    exact
                                />

                                <Route
                                    path={`${url}/:categoryid/:subcategoryid/:subcategoryitemid`}
                                    component={AdminProducts}
                                    exact
                                />
                            </>
                        )}
                    ></Route>
                    <Route
                        path={`${path}/product`}
                        render={({ match: { url } }) => (
                            <>
                                <Route
                                    path={`${url}/:productid`}
                                    component={AdminProductInfo}
                                    exact
                                />
                            </>
                        )}
                    ></Route>
                </Switch>
            </Layout>
        </>
    );
}
