import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Space,
  Button,
  Upload,
  DatePicker,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage } from "reactfire";
import { Checkbox } from 'antd';
import moment from "moment";

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] instanceof Object
    ) {
      delete obj[propName];
    }
    if (obj["readyToShipDate"]) {
      let date = Date(obj["readyToShipDate"]);
      console.log(date);
      obj["readyToShipDate"] = date;
    }
    // obj[propName] = obj[propName].toDate().toString();
  }
  return obj;
}

export function StorageForm(props) {
  const { userData, edit, editData, setIsModalVisible, view } = props;
  const [form] = Form.useForm();
  // const [width, setWidth] = useState(0);
  // const [length, setLength] = useState(0);
  // const [height, setHeight] = useState(0);
  const uid = userData.user_id;
  const [uploads, setUploads] = useState();
  const [loading, setLoading] = useState();
  const fieldValue = useFirestore.FieldValue;
  // const order_id = userData.services_ids.preShipmentInspection;
  const storage = useStorage();
  const firestore = useFirestore();
  const [pickup, setPickup] = useState(false);
  const [fileList, setFileList] = useState([]);

  console.log('edit',editData)

  form.setFieldsValue({ transitType: "sea" });
  const orderRef = useFirestore()
    .collection("users")
    .doc(uid)
    .collection("orders");

  const userDetailsRef = useFirestore().collection("users").doc(uid);

  const handleUpload = (e) => {
    console.log(e.file);
    setUploads(e.file.originFileObj);
  };

  const handleUpload2 = ( {fileList} ) => {
   console.log(fileList);
   setFileList(fileList);
  };

  const uploadLabels = async () =>{
    console.log("uploading...");
    const files = [];
    if (fileList) {
      await Promise.all(
        fileList.map(async file => {
          let fileName = Date.now().toString() + "_" + file.name;
          const storageRef = storage.ref();
          const fileUploadRef = storageRef.child('userUploads');
          const userUploadRef = fileUploadRef.child(`${userData.user_id}`);
          const fileRef = userUploadRef.child(fileName)
  
          const designFile = await fileRef.put(file.originFileObj)
          const downloadURL = await designFile.ref.getDownloadURL();
  
          const item = {
            url: downloadURL,
            fileName: file.name,
          };
  
          files.push(item)
        })
      )
    }

    return files;
  }

  const uploadFile = async (e) => {
    console.log("uploading...");
    let downloadURL = "";
    if (uploads) {
      let fileName = Date.now().toString() + "_" + uploads.name;

      await storage
        .ref(`fileUploads/${userData.user_id}` + fileName)
        .put(uploads)
        .then((snapshot) => {
          downloadURL = snapshot.ref.getDownloadURL();
        })

        .catch((error) => {
          console.log(error);
        });
    }

    return downloadURL;
  };

  const generateOrder_id = async () => {
    let order_id = "STOI210001";
    await firestore
      .collectionGroup("orders")
      .where("serviceType", "==", "storage")
      .orderBy("dateOrdered", "desc")
      .limit(1)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            console.log(doc.id, " => ", doc.data());
            order_id = incrementOrder_id(doc.data().order_id);
          }
        });
      });
    return order_id;
  };

  const incrementOrder_id = (order_id) => {
    const splitOrder_id = order_id.split(/([0-9]+)/);
    const incremented_id = parseInt(splitOrder_id[1]) + 1;
    return splitOrder_id[0] + incremented_id;
  };

  const onFinishEdit = async(values) =>{
    console.log(values)
    const etd = values.customerIssuedEtd.toDate().toString();
    const eta = values.customerIssuedEta.toDate().toString();
    setLoading(true);
    let orderDetails = await clean(values);
    let url = "";
    const labelUploads = await uploadLabels();
    if (uploads) url = await uploadFile();

    await orderRef.doc(editData?.id).update({
      orderDetails:{
        ...orderDetails,
        customerIssuedEtd: etd,
        customerIssuedEta: eta,
        shouldPickup: pickup,
      },
      labelUploads: labelUploads,
      pictureLink: url !== "" ? url : "",
      pictureFilename: uploads ? uploads.name : "",
    })
    setLoading(false);
  }

  const onFinish = async (values) => {
    setLoading(true);
    let orderDetails = await clean(values);
    let newOrder_id = await generateOrder_id();
    let url = "";
    const labelUploads = await uploadLabels();
    if (uploads) url = await uploadFile();
    await orderRef
      .add({
        currentCharge: 0,
        order_id: newOrder_id,
        orderedBy_id: uid,
        orderedBy_name: userData.firstName + " " + userData.lastName,
        orderedBy_email: userData.email,
        dateOrdered: fieldValue.serverTimestamp(),
        orderStatus: "receiving",
        serviceType: "storage",
        pictureLink: url !== "" ? url : "",
        pictureFilename: uploads ? uploads.name : "",
        orderDetails:{
            ...orderDetails,
            shouldPickup: pickup,
        },
        isArchived: false,
        isCharging: false,
        isReceived: false,
        currentTotalCharge: 0,
        
        baseCharge:{
            numberOfDaysInStorage: 0,
            plt: 0,
            currentBaseCharge: 0
        },
        labelUploads: labelUploads
      })
      .then(async (docRef) => {
        if (edit) {
          await orderRef.doc(editData.id).update({
            orderStatus: "edited",
          });
        }
        await userDetailsRef.set(
          {
            services_ids: {
              storage: docRef.id,
            },
          },
          { merge: true }
        );
        window.alert("Form Submitted!");
        if (edit) setIsModalVisible(false);
        setLoading(false);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let length = 0;
  let width = 0;
  let height = 0;

  let cartonWeight = 0;
  let carton = 0;
  let cbm = 0;

  const calcCubic = () => {
    form.setFieldsValue({
      estimatedCubicMeters: width * 0.01 * length * 0.01 * height * 0.01,
    });
    cbm = width * 0.01 * length * 0.01 * height * 0.01;
  };

  const calcTotalWeight = () => {
    form.setFieldsValue({
      estimatedTotalWeight: cartonWeight * carton,
    });
    if (carton > 0)
      form.setFieldsValue({
        estimatedCubicMeters: carton * cbm,
      });
  };

  const getWidth = (num) => {
    width = num;
    calcCubic();
  };
  const getHeight = (num) => {
    height = num;
    calcCubic();
  };
  const getLength = (num) => {
    length = num;
    calcCubic();
  };

  const getCarton = (num) => {
    carton = num;
    calcTotalWeight();
  };

  const getCartonWeight = (num) => {
    cartonWeight = num;
    calcTotalWeight();
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      China +86
    </Form.Item>
  );

  function onChangePickup(e){
      setPickup(e.target.checked)
  }
  const editDetails = editData?.orderDetails || {};
  return (
    <>
      <Form
        initialValues={ edit || view ?
          {
            goodsDescription: editDetails?.goodsDescription,
            cartonWidth: editDetails?.cartonWidth,
            cartonHeight: editDetails?.cartonHeight,
            cartonLength: editDetails?.cartonLength,
            estimatedCubicMeters: editDetails?.estimatedCubicMeters,
            estimatedWeight: editDetails?.estimatedWeight,
            estimatedTotalCartons: editDetails?.estimatedTotalCartons,
            estimatedTotalWeight: editDetails?.estimatedTotalWeight,
            customerIssuedTrackingNumber: editDetails?.customerIssuedTrackingNumber,
            // customerIssuedEtd: moment(editDetails?.customerIssuedEtd),
            // customerIssuedEta: moment(editDetails?.customerIssuedEta),
          }
          : {}
        }
        form={form}
        {...layout}
        name="nest-messages"
        onFinish={edit ? onFinishEdit : onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={"goodsDescription"}
          label="Goods Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={view} />
        </Form.Item>

        <Form.Item
          name="productPictures"
          label="Product Photos (or similar photos)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload onChange={handleUpload}>
            <Button disabled={view} icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item 
            name={"pickup"} 
            wrapperCol={{
            offset: 12,
            span: 16,
            }}
        >
            <Checkbox disabled={view} onChange={onChangePickup}>Pickup</Checkbox>
        </Form.Item>
        {pickup && 
            <div>
                <Form.Item
                    name={"supplierContact"}
                    label="Supplier Contact"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input disabled={view} />
                </Form.Item>

                <Form.Item
                    name="supplierNumber"
                    label="Supplier Number"
                    rules={[
                        {
                            required: true,
                            message: "Please input the Supplier Contact!",
                        },
                    ]}
                >
                    <Input
                        disabled={view}
                        addonBefore={prefixSelector}
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
            </div>
        }

        <Form.Item 
            initialValue={"USA"}
            name={"storageLocation"} 
            label="Storage Location"
            rules={[
                {
                  required: true,
                },
            ]}
        >
          <Select disabled={view} defaultValue="USA" >
            <Option value="usa">USA</Option>
            <Option value="china">China</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Estimated dimensions of each Carton (cm)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Width is required!",
              },
            ]}
            style={{ display: "inline-block", width: "calc(33% - 3px)" }}
            name={"cartonWidth"}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={getWidth}
              min={0}
              placeholder="width"
              disabled={view}
            />
          </Form.Item>{" "}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Length is required!",
              },
            ]}
            style={{ display: "inline-block", width: "calc(33% - 3px)" }}
            name={"cartonLength"}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={getLength}
              min={0}
              placeholder="length"
              disabled={view}
            />
          </Form.Item>{" "}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Height is required!",
              },
            ]}
            style={{ display: "inline-block", width: "calc(33% - 3px)" }}
            name={"cartonHeight"}
          >
            <InputNumber
              style={{ width: "100%" }}
              onChange={getHeight}
              min={0}
              placeholder="height"
              disabled={view}
            />
          </Form.Item>{" "}
        </Form.Item>
        <Form.Item
          name={"estimatedCubicMeters"}
          label="Estimated Cubic Meters (CBM)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber disabled={view} min={0} />
        </Form.Item>

        <Form.Item
          name={"estimatedWeight"}
          label="Estimated Weight of each Carton (kg)"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber disabled={view} onChange={getCartonWeight} min={0} />
        </Form.Item>

        <Form.Item
          name={"estimatedTotalCartons"}
          label="Estimated Total Cartons"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber disabled={view} onChange={getCarton} min={0} />
        </Form.Item>

        <Form.Item
          name="labels"
          label="Shipping labels, Barcodes, etc."
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload onChange={handleUpload2} disabled={view}>
            <Button disabled={view} icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name={"trackingNumber"}
          label="Tracking Number (if ship w/ other forwarder)"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input disabled={view} />
        </Form.Item>
        {edit || view ?
          <>
            <Form.Item
              name={"customerIssuedEtd"}
              label="ETD"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name={"customerIssuedEta"}
              label="ETA"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
                  name={"customerIssuedTrackingNumber"}
                  label="Tracking Number"
                  rules={[
                      {
                          required: false,
                      },
                  ]}
              >
                  <Input />
            </Form.Item>
        </>
        : ''
        }

        <Form.Item
          wrapperCol={{ ...layout.wrapperCol, offset: 12 }}
          loading={loading ? true : false}
        >
          <Button disabled={view} type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>

      
    </>
  );
}
