/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Table, Space, Modal, Popover, Tag } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { StorageForm } from "../Forms";
import { ServiceTag } from "../../../../components/ServiceTag";
import moment from "moment";
import { useHistory } from "react-router-dom";

export function StorageOrderTable(props) {
    const { userData } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState([]);
    const [editState, setEditState] = useState(false);
    const [viewState, setViewState] = useState(true);
    const firestore = useFirestore();
    const history = useHistory();
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const ordersRef = useFirestore()
        .collection("users")
        .doc(userData.user_id)
        .collection("orders")
        .where("serviceType", "==", "storage");

    const { data: orderData, status: orderStatus } = useFirestoreCollectionData(
        ordersRef,
        {
            idField: "id",
        },
    );

    const showModal = (data) => {
        if (orderData.orderStatus !== "proceed") {
            setIsModalVisible(true);
            setEditData(data);
        }
    };

    const orderProceed = async (data) => {
        if (data.orderStatus === "quotationIssued") {
            if (window.confirm("Proceed with your order?")) {
                await firestore
                    .collection("users")
                    .doc(userData.user_id)
                    .collection("orders")
                    .doc(data.id)
                    .update({ orderStatus: "orderProceeded" });

                // await firestore
                //   .collection("users")
                //   .doc(userData.user_id)
                //   .set(
                //     {
                //       services_ids: {
                //         shipping: "",
                //       },
                //     },
                //     { merge: "true" }
                //   );
                window.alert(
                    "Order Proceeded, please wait for your invoice to be issued",
                );
            }
        } else {
            window.alert("Quotation not yet issued");
        }
    };

    const swapOrder = async (data) => {
        await firestore
            .collection("users")
            .doc(userData.user_id)
            .set(
                {
                    services_ids: {
                        storage: data.id,
                    },
                },
                { merge: "true" },
            );
        history.push({
            pathname: `sourcing/${data.order_id}`,
            state: data,
        });
    };

    const modalEdit = (data) => {
        setEditState(true);
        setViewState(false);
        showModal(data);
    };

    const modalView = (data) => {
        setViewState(true);
        setEditState(false);
        showModal(data);
    };

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const storageDetails = (text, data) => (
        <div>{data?.baseCharge?.numberOfDaysInStorage || 0}</div>
    );
    const text = (text, data) => (
        <a onClick={() => swapOrder(data)}>{`${text}${
            data.order_idExtension || ""
        }`}</a>
    );
    const currency = (text) => <a>${text}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const status = (text, record) => (
        <ServiceTag service={record.serviceType} tag={text} />
    );
    const actions = (text, record) => (
        <Space size="middle">
            <a onClick={() => modalView(text)}>View</a>
            <a onClick={() => modalEdit(text)}>Edit</a>
        </Space>
    );
    const etd = (text, data) => (
        <a>
            {data.orderDetails.eta
                ? moment(data.orderDetails.etd).format("MMM Do YY")
                : ""}
        </a>
    );
    const eta = (text, data) => (
        <a>
            {data.orderDetails.eta
                ? moment(data.orderDetails.eta).format("MMM Do YY")
                : ""}
        </a>
    );
    const trackingNumber = (text, data) => (
        <a>{data.orderDetails.trackingNumber}</a>
    );

    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            key: "name",
            render: text,
        },
        {
            title: "Date Ordered",
            key: "dateAdded",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },
        {
            title: "Current Charge",
            dataIndex: "currentTotalCharge",
            key: "name",
            render: currency,
        },
        {
            title: "Order Status",
            dataIndex: "orderStatus",
            key: "name",
            render: status,
        },
        {
            title: "Number of days in storage",
            dataIndex: "currentTotalCharge",
            key: "name",
            render: storageDetails,
        },
        {
            title: "ETD",
            dataIndex: "etd",
            key: "name",
            render: etd,
        },
        {
            title: "ETA",
            dataIndex: "currentTotalCharge",
            key: "name",
            render: eta,
        },
        {
            title: "Tracking Number",
            dataIndex: "currentTotalCharge",
            key: "name",
            render: trackingNumber,
        },
        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];
    return (
        <>
            <Table
                scroll={{ x: true }}
                columns={columns}
                loading={orderStatus === "loading" ? true : false}
                dataSource={orderData}
            ></Table>

            <Modal
                width={800}
                title="Edit Shipping Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <StorageForm
                    userData={userData}
                    edit={editState}
                    view={viewState}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
}
