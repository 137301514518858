import React, { useContext } from "react";
import { UserContext } from "../../context/authContext";
import { UserOutlined } from "@ant-design/icons";
import { Row, Col, Spin, Card, Avatar, Space } from "antd";
import { DashboardTable, DashboardInvoiceTable } from "./components";
import { useHistory } from "react-router-dom";

export function UserDashboard(props) {
  const { user, userData, userClaims } = useContext(UserContext);
  const history = useHistory;
  if (!user || !userData) {
    return (
      <div className="loader">
        <Spin></Spin>
      </div>
    );
  }

  // if (userClaims.superAdmin || userClaims.admin) {
  //   history.push("/admin");
  // }
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={24} xl={24}>
          <div className="centered-container">
            <img style={{ width: "40%" }} alt="" src="/assets/b2c.svg"></img>
          </div>
        </Col>
        {/* <Col xs={24} lg={24} md={24} xl={12}>
          <Card>
            <Space size="middle" align="center">
              <Avatar size={64} icon={<UserOutlined />} />
              <div>
                <div style={{ fontSize: "24px" }}>
                  {userData.firstName + " " + userData.lastName}
                </div>
                <div style={{ color: "grey" }}>{userData.email}</div>
              </div>
            </Space>
          </Card>{" "}
        </Col> */}
        <Col xs={24} lg={24} xl={24}>
          <Card
            title="Recent Invoices"
            bordered
            bodyStyle={{ padding: "0" }}
            style={{ border: "1px solid lightgrey" }}
          >
            <DashboardInvoiceTable userData={userData} />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={6} xs={24} lg={12} xl={12}>
          <Card
            title="Samples Forwarding Orders"
            bordered
            bodyStyle={{ padding: "0" }}
            style={{ border: "1px solid lightgrey" }}
          >
            <DashboardTable service={"samplesForwarding"} />
          </Card>
        </Col>

        <Col span={6} xs={24} lg={12} xl={12}>
          <Card
            title="Preshipment Inspection Orders"
            bordered
            bodyStyle={{ padding: "0" }}
            style={{ border: "1px solid lightgrey" }}
          >
            <DashboardTable service={"preShipmentInspection"} />
          </Card>
        </Col>

        <Col span={6} xs={24} lg={12} xl={12}>
          <Card
            title="Shipping Orders"
            style={{ border: "1px solid lightgrey" }}
            bordered
            bodyStyle={{ padding: "0" }}
          >
            <DashboardTable service={"shipping"} />
          </Card>
        </Col>
      </Row>
    </>
  );
}
