import { useEffect, useState } from "react";
import { useUser, useFirestore } from "reactfire";

// Custom hook to read  auth record and user profile doc
export function useUserData() {
  const firestore = useFirestore();
  const { data: user, status } = useUser();
  const [userData, setUserData] = useState([]);
  const [userClaims, setUserClaims] = useState([]);
  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    if (user) {
      const ref = firestore.collection("users").doc(user.uid);
      user.getIdTokenResult().then((getIdTokenResult) => {
        setUserClaims(getIdTokenResult.claims);
      });
      unsubscribe = ref.onSnapshot((doc) => {
        setUserData(doc.data());
      });
    } else {
      setUserData(null);
    }

    return unsubscribe;
  }, [firestore, user]);

  return { user, userData, userClaims };
}
