import React, { useState } from "react";
import {
    Layout,
    Descriptions,
    Card,
    Spin,
    Button,
    Row,
    Col,
    Modal,
    Form,
    Upload,
    Space,
    DatePicker,
    Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useStorage,
} from "reactfire";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { orders } from "./dummydata";
import { UserInfoCard } from "../components";

const { Content } = Layout;

export const Charges = ({ chargesData, orderData }) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [loading, setLoading] = useState();
    const [uploads, setUploads] = useState([]);
    const fieldValue = useFirestore.FieldValue;
    const firestore = useFirestore();
    const storage = useStorage();
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record?.orderedBy_id;
    const now = useFirestore.Timestamp.now();

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const orderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid || "");

    const chargesRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid || "")
        .collection("charges");

    function addCharge() {
        chargesRef.add({
            baseCharge: 0,
            plt: 0,
            dayNumber: 0,
            dateCharged: useFirestore.Timestamp.now(),
        });
    }

    function setRecieved() {
        orderRef.update({ orderStatus: "received", isReceived: true });
    }
    function setRecieving() {
        orderRef.update({ orderStatus: "receiving", isReceived: false });
    }

    function startCharging() {
        if (orderData.chargeAt) {
            if (orderData.isCharging)
                orderRef.update({
                    isCharging: false,
                    orderStatus: "stoppedStoring",
                });
            else {
                orderRef.update({ isCharging: true, orderStatus: "storing" });
            }
        } else {
            orderRef.update({
                isCharging: true,
                chargeAt: now,
                orderStatus: "storing",
            });
        }
    }

    const headerButtons = (
        <Space>
            <Button
                onClick={
                    orderData?.orderStatus === "received" ||
                    orderData?.isReceived
                        ? setRecieving
                        : setRecieved
                }
            >
                {orderData?.orderStatus === "received" || orderData?.isReceived
                    ? "Mark As Not Received"
                    : "Mark As Received"}
            </Button>
            <Button onClick={startCharging}>
                {orderData?.isCharging ? "Stop Charging" : "Start Charging"}
            </Button>
        </Space>
    );

    function getChargeFromDays(days) {
        switch (true) {
            case days < 15:
                return 0;
            case days > 15 && days < 31:
                return 1.5;
            case days > 30 && days < 61:
                return 2;
            case days > 60 && days < 91:
                return 3;
            case days > 90 && days < 181:
                return 3.5;
            case days > 180 && days < 241:
                return 4;
            case days > 240 && days < 361:
                return 4.5;
            case days > 360 && days < 451:
                return 5;
            case days > 450:
                return 5.5;
            default:
                return 0;
        }
    }

    function editCell(params) {
        const { data } = params;
        const chargeFromDays = getChargeFromDays(parseInt(data.dayNumber));
        const chargeTotal = chargeFromDays * parseFloat(data.plt);
        chargesRef.doc(data.id).update({
            charge: parseFloat(chargeTotal),
            dayNumber: parseInt(data.dayNumber),
            plt: parseFloat(data.plt),
        });
    }

    function formatNumber(number) {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    function currencyFormatter(params) {
        const number = parseFloat(params.value);
        if (params.node.rowPinned) {
            return `Total Charge Amount: \x24${number.toFixed(2)}` || 0;
        }
        return "\x24" + number.toFixed(2) || 0;
    }

    function dateFormatter(params) {
        return params.value ? params.value.toDate() : "";
    }

    const pinnedRow = [
        {
            charge: orderData?.currentTotalCharge,
        },
    ];

    return (
        <>
            <div
                className="ag-theme-alpine"
                style={{ height: 500, width: "100%" }}
            >
                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 10 }}
                    title={"Charges"}
                    extra={headerButtons}
                >
                    <div
                        className="ag-theme-alpine"
                        style={{ height: 400, width: "100%" }}
                    >
                        <AgGridReact
                            defaultColDef={{
                                flex: 1,
                                minWidth: 110,
                                resizable: true,
                                sortable: true,
                            }}
                            getRowStyle={function (params) {
                                if (params.node.rowPinned) {
                                    return { "font-weight": "bold" };
                                }
                            }}
                            onGridReady={onGridReady}
                            rowData={chargesData}
                            stopEditingWhenCellsLoseFocus={true}
                            pinnedBottomRowData={pinnedRow}
                        >
                            <AgGridColumn
                                field="dayNumber"
                                headerName="Day Number"
                                onCellValueChanged={editCell}
                            />
                            <AgGridColumn
                                field="plt"
                                headerName="PLT"
                                onCellValueChanged={editCell}
                            />
                            <AgGridColumn
                                field="dateCharged"
                                sort="desc"
                                headerName="Date Charged"
                                cellEditor="datePicker"
                                valueFormatter={dateFormatter}
                            />
                            <AgGridColumn
                                field="charge"
                                headerName="Charge"
                                valueFormatter={currencyFormatter}
                            />
                        </AgGridReact>
                    </div>
                </Card>
            </div>
        </>
    );
};
