import React, { useState } from "react";
import { Descriptions, Card, Typography } from "antd";

const { Paragraph } = Typography;

export default function SourcingDescriptions({ orderData }) {
    const orderDetails = orderData?.orderDetails;
    return (
        <>
            <Card
                bordered
                style={{ marginTop: "20px" }}
                bodyStyle={{ padding: 0 }}
                title={"Order Details"}
            >
                <Descriptions bordered>
                    <Descriptions.Item label="Product Name">
                        {orderDetails?.productName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product Photo(optional)">
                        <a
                            href={orderData?.pictureLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {orderData?.pictureFilename}
                        </a>
                    </Descriptions.Item>

                    <Descriptions.Item label="Customize-detailed sketch diagram">
                        <a
                            href={orderData?.sketchLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {orderData?.sketchFilename}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label=" Order Quantity">
                        {orderDetails?.orderQuantity}
                    </Descriptions.Item>
                    <Descriptions.Item label="Customize Requirements">
                        {orderDetails?.customizeRequirements}
                    </Descriptions.Item>
                    <Descriptions.Item label="Reference Listing Link">
                        {orderDetails?.referenceListingLink}
                    </Descriptions.Item>
                    <Descriptions.Item label="Others">
                        {orderDetails?.others}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
