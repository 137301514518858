import React from "react";
import { Layout, Row, Col } from "antd";
import { useParams, useLocation } from "react-router-dom";
const { Content } = Layout;

export function AdminProductInfo(props) {
  const { product } = useLocation().state;
  return (
    <>
      <Row
        style={{
          background: "white",
          minHeight: "500px",
        }}
        justify="center"
      >
        <Content
          style={{
            padding: "24px",
            maxWidth: "1500px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xxl={6}>
              <img style={{ width: "100%" }} alt="example" src={product.img} />
            </Col>

            <Col xxl={8}>
              <h2>{product.name}</h2>
              <h3>${product.price}</h3>
              <div style={{ width: "100%", minHeight: "100px" }}>
                {product.description}
              </div>
            </Col>
          </Row>
        </Content>
      </Row>
    </>
  );
}
