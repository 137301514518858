import React, { useState } from "react";
import { Form, Input, Select, Button, Space } from "antd";
import { useFirestore } from "reactfire";
import { countryCodes } from "../../../../utils/countryCodes";

// import DomesticTrackingFormEdit from "./DomesticTrackingFormEdit";
const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const { Option } = Select;

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
}

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const prefixSelector = (
    <Form.Item name="prefix" noStyle>
        China +86
    </Form.Item>
);

export function SamplesForwardingForm(props) {
    const { userData, edit, editData, setIsModalVisible } = props;
    const uid = userData.user_id;
    const [form] = Form.useForm();
    const order_id = userData.services_ids.samplesForwarding || "";
    const fieldValue = useFirestore.FieldValue;
    const increment = fieldValue.increment;
    const [loading, setLoading] = useState(false);
    const firestore = useFirestore();
    const [isSubmittedAsNewOrder, setIsSubmittedAsNewOrder] = useState(false);

    console.log(isSubmittedAsNewOrder);

    const orderRef = useFirestore()
        .collection("users")
        .doc(uid)
        .collection("orders");
    const userDetailsRef = useFirestore().collection("users").doc(uid);

    const generateOrder_id = async () => {
        let order_id = "SAM210001";
        await firestore
            .collectionGroup("orders")
            .where("serviceType", "==", "samplesForwarding")
            .orderBy("dateOrdered", "desc")
            .limit(1)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        order_id = incrementOrder_id(doc.data().order_id);
                    }
                });
            });
        return order_id;
    };

    const checkCollectingStatus = async () => {
        if (order_id === "") return;

        let orderStatus = "doneCollecting";
        let orderData;
        await orderRef
            .doc(order_id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    orderData = doc.data();
                    orderStatus = doc.data().order_id;
                }
            });
        return orderData;
    };

    const incrementOrder_id = (order_id) => {
        const splitOrder_id = order_id.split(/([0-9]+)/);
        const incremented_id = parseInt(splitOrder_id[1]) + 1;
        return splitOrder_id[0] + incremented_id;
    };

    const addTrackingNumber = async (id, trackingValues, orderNumber) => {
        await firestore
            .collection("users")
            .doc(uid)
            .collection("orders")
            .doc(id)
            .collection("trackingNumbers")
            .add({
                ...trackingValues,
                order_id: orderNumber,
                orderedBy_id: uid,
                orderedBy_name: userData.firstName + " " + userData.lastName,
                isReceived: false,
                dateAdded: fieldValue.serverTimestamp(),
            });
    };

    const onFinish = async (values) => {
        console.log(isSubmittedAsNewOrder);

        let trackingValues = await clean(values);
        const orderData = await checkCollectingStatus();
        if (order_id === "" || isSubmittedAsNewOrder) {
            await setLoading(true);
            let newOrder_id = await generateOrder_id();
            await orderRef
                .add({
                    order_id: newOrder_id,
                    orderedBy_id: uid,
                    orderedBy_name:
                        userData.firstName + " " + userData.lastName,
                    orderedBy_email: userData.email,
                    dateOrdered: fieldValue.serverTimestamp(),
                    orderStatus: "collecting",
                    serviceType: "samplesForwarding",
                    isArchived: false,
                    allPaid: false,
                    allReceived: false,
                    allInvoicesIssued: false,
                    trackingNumbersReceived: 0,
                    totalTrackingNumbers: 1,
                })
                .then(async (docRef) => {
                    await addTrackingNumber(
                        docRef.id,
                        trackingValues,
                        newOrder_id,
                    );
                    await userDetailsRef.update({
                        services_ids: {
                            ...userData.services_ids,
                            samplesForwarding: docRef.id,
                        },
                    });
                    await window.alert("Form Submitted!");
                    await form.resetFields();
                    await setLoading(false);
                    await setIsSubmittedAsNewOrder(false);
                });
        } else {
            const orderData = await checkCollectingStatus();
            if (orderData.orderStatus === "collecting") {
                await setLoading(true);
                await addTrackingNumber(
                    order_id,
                    trackingValues,
                    orderData.order_id,
                );
                await firestore
                    .collection("users")
                    .doc(uid)
                    .collection("orders")
                    .doc(order_id)
                    .update({ totalTrackingNumbers: increment(1) });
                await window.alert("Form Submitted!");
                await form.resetFields();
                await setLoading(false);
            } else {
                window.alert(
                    "You are done collecting, please click Keep Collecting to continue",
                );
            }
        }
    };

    const onFinishEdit = async (values) => {
        await setLoading(true);
        let trackingValues = await clean(values);
        await orderRef
            .doc(order_id)
            .collection("trackingNumbers")
            .doc(editData.id)
            .update({
                ...editData,
                ...trackingValues,
            });
        await window.alert("Form Submitted!");
        await form.resetFields();
        await setIsModalVisible(false);
        await setLoading(false);
    };

    if (edit && editData) {
        form.setFieldsValue({
            sampleName: editData.sampleName,
            supplierName: editData.supplierName,
            supplierContact: editData.supplierContact,
            phoneNumber: editData.phoneNumber,
            trackingNumber: editData.trackingNumber,
            quantityOfSamples: editData.quantityOfSamples,
        });
    }
    return (
        <>
            <Form
                {...layout}
                form={form}
                name="nest-messages"
                onFinish={edit ? onFinishEdit : onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item name={"sampleName"} label="Sample Name">
                    <Input />
                </Form.Item>
                <Form.Item name={"supplierName"} label="Supplier Company Name">
                    <Input />
                </Form.Item>
                <Form.Item name="supplierContact" label="Supplier Contact Name">
                    <Input />
                </Form.Item>

                <Form.Item name="phoneNumber" label="Phone Number">
                    <Input
                        addonBefore={prefixSelector}
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"trackingNumber"}
                    label="Tracking Number"
                    rules={[
                        {
                            required: true,
                            message: "Please input the tracking number!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={"quantityOfSamples"}
                    label="Quantity of samples in tracking number"
                    rules={[
                        {
                            required: true,
                            message: "Please input the quantity of samples!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        ...layout.wrapperCol,
                        offset: 0,
                        sm: { offset: 10 },
                    }}
                >
                    <Space direction="vertical">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading ? true : false}
                        >
                            Submit as Tracking Number
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading ? true : false}
                            onClick={() => setIsSubmittedAsNewOrder(true)}
                        >
                            Submit as New Order
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
}
