import React, { useState } from "react";
import {
    Form,
    Input,
    Select,
    Space,
    Button,
    Upload,
    DatePicker,
    InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage } from "reactfire";

const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const { Option } = Select;
const { TextArea } = Input;

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

function clean(obj) {
    for (var propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] instanceof Object
        ) {
            delete obj[propName];
        }
        if (obj["readyToShipDate"]) {
            let date = Date(obj["readyToShipDate"]);
            obj["readyToShipDate"] = date;
        }
        // obj[propName] = obj[propName].toDate().toString();
    }
    return obj;
}

export function ShippingForm(props) {
    const { userData, edit, editData, setIsModalVisible } = props;
    const [form] = Form.useForm();
    // const [width, setWidth] = useState(0);
    // const [length, setLength] = useState(0);
    // const [height, setHeight] = useState(0);
    const uid = userData.user_id;
    const [uploads, setUploads] = useState();
    const [loading, setLoading] = useState();
    const fieldValue = useFirestore.FieldValue;
    const order_id = userData.services_ids.preShipmentInspection;
    const storage = useStorage();
    const firestore = useFirestore();

    form.setFieldsValue({ transitType: "sea" });
    const orderRef = useFirestore()
        .collection("users")
        .doc(uid)
        .collection("orders");

    const userDetailsRef = useFirestore().collection("users").doc(uid);

    const handleUpload = (e) => {
        setUploads(e.file.originFileObj);
    };

    const uploadFile = async (e) => {
        let downloadURL = "";
        if (uploads) {
            let fileName = Date.now().toString() + "_" + uploads.name;

            await storage
                .ref(`fileUploads/${userData.user_id}` + fileName)
                .put(uploads)
                .then((snapshot) => {
                    downloadURL = snapshot.ref.getDownloadURL();
                    // return snapshot.ref.getDownloadURL();
                })

                .catch((error) => {
                    console.log(error);
                });
        }

        return downloadURL;
    };

    const generateOrder_id = async () => {
        let order_id = "SHI210001";
        await firestore
            .collectionGroup("orders")
            .where("serviceType", "==", "shipping")
            .orderBy("dateOrdered", "desc")
            .limit(1)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        order_id = incrementOrder_id(doc.data().order_id);
                    }
                });
            });
        return order_id;
    };

    const incrementOrder_id = (order_id) => {
        const splitOrder_id = order_id.split(/([0-9]+)/);
        const incremented_id = parseInt(splitOrder_id[1]) + 1;
        return splitOrder_id[0] + incremented_id;
    };

    const onFinish = async (values) => {
        setLoading(true);
        let orderDetails = await clean(values);
        let newOrder_id = await generateOrder_id();
        let url = "";
        if (uploads) url = await uploadFile();
        await orderRef
            .add({
                order_id: newOrder_id,
                orderedBy_id: uid,
                orderedBy_name: userData.firstName + " " + userData.lastName,
                orderedBy_email: userData.email,
                dateOrdered: fieldValue.serverTimestamp(),
                orderStatus: "quotationPending",
                serviceType: "shipping",
                pictureLink: url !== "" ? url : "",
                pictureFilename: uploads ? uploads.name : "",
                orderDetails,
                isArchived: false,
            })
            .then(async (docRef) => {
                if (edit) {
                    await orderRef.doc(editData.id).update({
                        orderStatus: "edited",
                    });
                }
                await userDetailsRef.set(
                    {
                        services_ids: {
                            shipping: docRef.id,
                        },
                    },
                    { merge: true },
                );
                window.alert("Form Submitted!");
                if (edit) setIsModalVisible(false);
                setLoading(false);
                form.resetFields();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let length = 0;
    let width = 0;
    let height = 0;

    let cartonWeight = 0;
    let carton = 0;
    let cbm = 0;

    const calcCubic = () => {
        form.setFieldsValue({
            estimatedCubicMeters: width * 0.01 * length * 0.01 * height * 0.01,
        });
        cbm = width * 0.01 * length * 0.01 * height * 0.01;
    };

    const calcTotalWeight = () => {
        form.setFieldsValue({
            estimatedTotalWeight: cartonWeight * carton,
        });
        if (carton > 0)
            form.setFieldsValue({
                estimatedCubicMeters: carton * cbm,
            });
    };

    const getWidth = (num) => {
        width = num;
        calcCubic();
    };
    const getHeight = (num) => {
        height = num;
        calcCubic();
    };
    const getLength = (num) => {
        length = num;
        calcCubic();
    };

    const getCarton = (num) => {
        carton = num;
        calcTotalWeight();
    };

    const getCartonWeight = (num) => {
        cartonWeight = num;
        calcTotalWeight();
    };

    if (edit && editData) {
        const editDetails = editData.orderDetails;
        form.setFieldsValue({
            goodsDescription: editDetails?.goodsDescription,
            HSCode: editDetails?.HSCode,
            departureAddress: editDetails?.departureAddress,
            deliveryAddress: editDetails?.deliveryAddress,
            incoterms: editDetails?.incoterms,
            transitType: editDetails?.transitType,
            cartonWidth: editDetails?.cartonWidth,
            cartonHeight: editDetails?.cartonHeight,
            cartonLength: editDetails?.cartonLength,
            estimatedCubicMeters: editDetails?.estimatedCubicMeters,
            estimatedWeight: editDetails?.estimatedWeight,
            estimatedTotalCartons: editDetails?.estimatedTotalCartons,
            estimatedTotalWeight: editDetails?.estimatedTotalWeight,
            shipmentValue: editDetails?.shipmentValue,
            containerShipment: editDetails?.containerShipment,
            // readyToShipDate: editData?.readyToShipDate,
        });
    }

    return (
        <>
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"goodsDescription"}
                    label="Goods Description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="productPictures"
                    label="Product Photos (or similar photos)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Upload onChange={handleUpload}>
                        <Button icon={<UploadOutlined />}>
                            Click to upload
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item name={"HSCode"} label="HS Code/Tariff Code">
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"departureAddress"}
                    label="Departure Address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"deliveryAddress"}
                    label="Delivery Address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"supplierContact"}
                    label="Supplier Contact"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"incoterms"}
                    label="Incoterms with the supplier"
                >
                    <Select>
                        <Option value="exw">EXW</Option>
                        <Option value="fob">FOB</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"transitType"}
                    label="Preferred Transit type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select>
                        <Option value="sea">Sea</Option>
                        <Option value="air">Air</Option>
                        <Option value="both">Both</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Estimated dimensions of each Carton (cm)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Width is required!",
                            },
                        ]}
                        style={{
                            display: "inline-block",
                            width: "calc(33% - 3px)",
                        }}
                        name={"cartonWidth"}
                    >
                        <InputNumber
                            style={{ width: "100%" }}
                            onChange={getWidth}
                            min={0}
                            placeholder="width"
                        />
                    </Form.Item>{" "}
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Length is required!",
                            },
                        ]}
                        style={{
                            display: "inline-block",
                            width: "calc(33% - 3px)",
                        }}
                        name={"cartonLength"}
                    >
                        <InputNumber
                            style={{ width: "100%" }}
                            onChange={getLength}
                            min={0}
                            placeholder="length"
                        />
                    </Form.Item>{" "}
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Height is required!",
                            },
                        ]}
                        style={{
                            display: "inline-block",
                            width: "calc(33% - 3px)",
                        }}
                        name={"cartonHeight"}
                    >
                        <InputNumber
                            style={{ width: "100%" }}
                            onChange={getHeight}
                            min={0}
                            placeholder="height"
                        />
                    </Form.Item>{" "}
                </Form.Item>
                <Form.Item
                    name={"estimatedCubicMeters"}
                    label="Estimated Cubic Meters (CBM)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name={"estimatedWeight"}
                    label="Estimated Weight of each Carton (kg)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber onChange={getCartonWeight} min={0} />
                </Form.Item>

                <Form.Item
                    name={"estimatedTotalCartons"}
                    label="Estimated Total Cartons"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber onChange={getCarton} min={0} />
                </Form.Item>

                <Form.Item
                    name={"estimatedTotalWeight"}
                    label="Estimated Total Weight (kg)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name={"shipmentValue"}
                    label="Shipment Value (USD)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name={"readyToShipDate"}
                    label="Expected Ready-to-ship date"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item
                    name={"containerShipment"}
                    label="Container Van Shipment"
                >
                    <Select>
                        <Option value="20GP">20GP</Option>
                        <Option value="40GP">40GP</Option>
                    </Select>
                </Form.Item>

                <Form.Item name={"notes"} label="Notes">
                    <TextArea />
                </Form.Item>

                <Form.Item
                    wrapperCol={{ ...layout.wrapperCol, offset: 12 }}
                    loading={loading ? true : false}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
