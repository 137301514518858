import React, { useState, useContext } from "react";
import {
    Layout,
    Descriptions,
    Card,
    Spin,
    Button,
    Row,
    Col,
    Modal,
    Form,
    Upload,
    Space,
    DatePicker,
    Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
    useFirestoreDocDataOnce,
    useStorage,
} from "reactfire";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Charges } from "./Charges";
import {
    UserInfoCard,
    AdminInvoiceTable,
    AdminInvoicePaymentsTable,
    AdminInvoiceForm,
} from "../components";
import { BaseCalculation } from "./BaseCalculation";
import { OrderDetails } from "./OrderDetails";
import { Schedule } from "./Schedule";
import { InternationalTrackingNumber } from "./InternationalTrackingNumber";
import { UserContext } from "../../../context/authContext";
import CommentsSection from "../../../components/CommentsSection";

const { Content } = Layout;

export const AdminStorage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record?.orderedBy_id;
    const { user } = useContext(UserContext);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const orderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid || "");

    const { data: orderData, status: orderStatus } = useFirestoreDocData(
        orderRef,
        {
            idField: "id",
        },
    );

    const userOrderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid);

    const userInvoiceRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const { data: invoiceData, status: invoiceStatus } =
        useFirestoreCollectionData(userInvoiceRef, {
            idField: "id",
        });

    const chargesRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid || "")
        .collection("charges");

    const { data: chargesData, status: chargesStatus } =
        useFirestoreCollectionData(chargesRef, {
            idField: "id",
        });

    const paymentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid ? orderid : "asd")
        .collection("payments");
    const { data: paymentsData, status: paymentsStatus } =
        useFirestoreCollectionData(paymentsRef, {
            idField: "id",
        });

    const adminRef = useFirestore().collection("admins").doc(user.uid);

    const commentsRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid)
        .collection("comments");

    const { data: commentsData, status: commentsStatus } =
        useFirestoreCollectionData(commentsRef.orderBy("timeStamp", "asc"), {
            idField: "id",
        });

    const { data: adminData, status: adminStatus } = useFirestoreDocDataOnce(
        adminRef,
        {
            idField: "id",
        },
    );

    const orderDetails = orderData?.orderDetails;
    const baseCharge = orderData?.baseCharge;

    const confirmPayment = async (data) => {
        await userInvoiceRef.doc(data.id).update({
            paymentStatus: "paid",
        });

        await userOrderRef.update({
            orderStatus: "invoicePaid",
        });
    };

    const deleteInvoice = async (data) => {
        await userInvoiceRef.doc(data.id).delete();
    };

    function formatNumber(number) {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    function currencyFormatter(params) {
        return "\x24" + formatNumber(params.value);
    }

    if (orderStatus === "loading") {
        return <div>loading</div>;
    }
    return (
        <>
            <Content>
                <UserInfoCard orderData={orderData} />

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col
                        style={{ marginTop: "20px" }}
                        span={12}
                        sm={24}
                        xl={24}
                        xxl={24}
                    >
                        <OrderDetails
                            orderDetails={orderDetails}
                            orderData={orderData}
                        />
                    </Col>
                </Row>

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col
                        style={{ marginTop: "20px" }}
                        span={12}
                        sm={24}
                        xl={24}
                        xxl={24}
                    >
                        <BaseCalculation baseCharge={baseCharge} />
                    </Col>
                </Row>

                <Row className="responsive-layout" gutter={16}>
                    {" "}
                    <Col
                        style={{ marginTop: "20px" }}
                        span={12}
                        sm={24}
                        xl={24}
                        xxl={24}
                    >
                        <Charges
                            chargesData={chargesData}
                            orderData={orderData}
                        />
                    </Col>
                </Row>

                <Col
                    style={{ marginTop: "20px" }}
                    span={12}
                    sm={24}
                    xl={24}
                    xxl={24}
                >
                    <Card
                        title="Invoice Details"
                        bodyStyle={{ padding: "0" }}
                        bordered
                        extra={
                            <Button
                                onClick={() => {
                                    setIsModalVisible(true);
                                    setEditStatus(false);
                                }}
                            >
                                Add Invoice
                            </Button>
                        }
                    >
                        <AdminInvoiceTable
                            invoiceData={invoiceData}
                            setIsModalVisible={setIsModalVisible}
                            setEditStatus={setEditStatus}
                            setEditData={setEditData}
                            confirmPayment={confirmPayment}
                            deleteInvoice={deleteInvoice}
                        />
                    </Card>
                </Col>
                <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={24}>
                    <AdminInvoicePaymentsTable paymentData={paymentsData} />
                </Col>

                <Row gutter={16}>
                    <Schedule orderData={orderData} />
                    <InternationalTrackingNumber orderData={orderData} />
                </Row>

                <Card
                    bordered
                    style={{ marginTop: "20px" }}
                    bodyStyle={{ padding: 20 }}
                    title={"Comments"}
                >
                    <CommentsSection
                        commentsData={commentsData}
                        orderData={orderData}
                        authorName={adminData?.name}
                    />
                </Card>
            </Content>

            <Modal
                title={editStatus ? "Edit Invoice" : "Add Invoice"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <AdminInvoiceForm
                    orderData={orderData}
                    orderid={orderid}
                    edit={editStatus}
                    editData={editData}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
};
