import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Table, Tag, Card } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export function DashboardInvoiceTable(props) {
  const { userData } = props;

  const userInvoiceRef = useFirestore()
    .collectionGroup("invoices")
    .where("user_id", "==", userData.user_id)
    .orderBy("dateIssued", "desc")
    .limit(5);

  const { data: invoiceData, status: invoiceStatus } =
    useFirestoreCollectionData(userInvoiceRef, {
      idField: "id",
    });

  console.log("collection group", invoiceData);

  const text = (text) => <a>{text}</a>;
  const amount = (text) => <a>${text}</a>;
  const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
  const title = () => <div>Invoice Details</div>;
  const footer = () => (
    <a>Click Here to contact our sales</a>
  );

  const paymentTag = (tag) => (
    <Tag
      color={
        tag === "paid"
          ? "green"
          : tag === "notPaid"
          ? "volcano"
          : tag === "confirmationPending"
          ? "processing"
          : "volcano"
      }
    >
      {tag === "paid"
        ? "Paid"
        : tag === "notPaid"
        ? "Not Paid"
        : tag === "confirmationPending"
        ? "Confirmation Pending"
        : ""}
    </Tag>
  );

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "id",
      render: text,
    },
    {
      title: "Date Issued",
      key: "dateAdded",
      dataIndex: "dateIssued",
      sorter: (a, b) => a.dateOrdered - b.dateOrdered,
      defaultSortOrder: "descend",
      render: date,
    },

    {
      title: "Order Number",
      key: "id",
      dataIndex: "orderNumber",
      render: text,
    },

    {
      title: "Amount to Pay",
      key: "id",
      dataIndex: "invoiceAmount",
      render: amount,
    },

    {
      title: "Payment Status",
      key: "id",
      dataIndex: "paymentStatus",
      render: paymentTag,
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        loading={invoiceStatus === "loading" ? true : false}
        columns={columns}
        dataSource={invoiceData}
      />
    </>
  );
}
