import React from "react";
import { Descriptions, Card } from "antd";
export default function SamplesForwardingDescriptions({ orderData }) {
    const orderDetails = orderData?.shippingAndInvoiceDetails;
    return (
        <>
            <Card title={"Shipping/Delivery Address and Contact Details"}>
                <Descriptions>
                    <Descriptions.Item>
                        Street/Building: {orderDetails?.street}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        City/Province: {orderDetails?.city}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Country: {orderDetails?.country}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Postal Code: {orderDetails?.postalCode}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Contact Person: {orderDetails?.contactPerson}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Contact Number: {orderDetails?.contactNumber}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        Active Email Address: {orderDetails?.activeEmail}
                    </Descriptions.Item>

                    <Descriptions.Item>
                        VAT/TVA/BTW/MWST Number: {orderDetails?.taxNumber}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
