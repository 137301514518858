import React from "react";
import { Table } from "antd";
import moment from "moment";

export default function AdminInvoicePaymentsTable(props) {
  const { paymentData } = props;

  const text = (text) => <a>{text}</a>;
  const paymentDate = (date) => <div>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</div>;
  const paymentID = (text) => <div>{text.id}</div>;
  const paymentAmount = (text) => (
    <div>${text.purchase_units[0].amount.value}</div>
  );
  const payerName = (text) => (
    <div>{text.payer.name.given_name + " " + text.payer.name.surname}</div>
  );
  const payerEmail = (text) => <div>{text.payer.email_address}</div>;
  const invoiceColumns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "name",
      render: text,
    },

    {
      title: "Date Paid",
      key: "datePaid",
      dataIndex: "datePaid",
      render: paymentDate,
    },
    {
      title: "Transaction ID",
      key: "paymentDetails",
      dataIndex: "paymentDetails",
      render: paymentID,
    },
    {
      title: "Transaction Amount",
      key: "paymentDetails",
      dataIndex: "paymentDetails",
      render: paymentAmount,
    },

    {
      title: "Payer Name",
      key: "paymentDetails",
      dataIndex: "paymentDetails",
      render: payerName,
    },

    {
      title: "Payer Email",
      key: "paymentDetails",
      dataIndex: "paymentDetails",
      render: payerEmail,
    },

    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: paymentActions,
    // },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        bordered
        title={() => "Invoice PayPal Payment Details"}
        columns={invoiceColumns}
        dataSource={paymentData}
        pagination={false}
      />

      {/* <Modal
        title={"Payment Details"}
        visible={isPaymentModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          Payment ID:
          <div></div>
        </div>
      </Modal> */}
    </>
  );
}
