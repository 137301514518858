import React, { useState } from "react";
import { Descriptions, Card, Typography } from "antd";

const { Paragraph } = Typography;

export default function PreShipmentInspectionDescriptions({ orderData }) {
    const [ellipsis, setEllipsis] = useState(true);

    const openNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <Card
                bordered
                style={{ marginTop: "20px" }}
                bodyStyle={{ padding: 0 }}
                title={"Order Details"}
            >
                <Descriptions bordered>
                    <Descriptions.Item label="Plan">
                        {orderData?.plan}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product Name">
                        {orderData?.orderDetails.productName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product Picture">
                        <a onClick={() => openNewTab(orderData?.pictureLink)}>
                            {orderData?.pictureFileName}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Factory Contact Info">
                        {orderData?.orderDetails.supplierContact}
                    </Descriptions.Item>
                    <Descriptions.Item label="Factory Address">
                        {orderData?.orderDetails.factoryAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product Details">
                        <Paragraph
                            ellipsis={
                                ellipsis
                                    ? {
                                          rows: 2,
                                          expandable: true,
                                          symbol: "see more",
                                      }
                                    : false
                            }
                        >
                            {orderData?.orderDetails.productDetails}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="Contract">
                        <a onClick={() => openNewTab(orderData?.contractLink)}>
                            {orderData?.contractFileName}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Company Details">
                        {orderData?.orderDetails.companyDetails}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
