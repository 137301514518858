import React, { useState } from "react";
import {
  Layout,
  Descriptions,
  Card,
  Spin,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Upload,
  Space,
  DatePicker,
  Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage,
} from "reactfire";

export function Schedule({orderData}) {
    const [loading, setLoading] = useState();
    const [form] = Form.useForm();
    const orderDetails = orderData?.orderDetails;
    const { record } = useLocation().state;
    const orderid = record.id;
    const userid = record.orderedBy_id;

    const userOrderRef = useFirestore()
    .collection("users")
    .doc(userid)
    .collection("orders")
    .doc(orderid);

    const issueDates = async (data) => {
        const etd = data.etd.toDate().toString();
        const eta = data.eta.toDate().toString();
        await setLoading(true);
        await userOrderRef.set(
          {
            orderDetails: {
              etd: etd,
              eta: eta,
            },
          },
          { merge: true }
        );
        await window.alert("ETD and ETA issued!");
        await form.resetFields();
        await setLoading(false);
      };
    return (
        <>
            <Col style={{ marginTop: "20px" }} sm={24} xl={24} xxl={12}>
            <Card title={"Shipping Schedule"} bordered>
              <Form onFinish={issueDates} form={form}>
                <Space>
                  <Form.Item
                    name={"etd"}
                    label="ETD"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>

                  <Form.Item
                    name={"eta"}
                    label="ETA"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading ? true : false}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
              <Space size={'large'}>
              <div>
                ETD:{" "}
                {orderDetails?.etd}
              </div>
              <div>
                ETA:{" "}
                {orderDetails?.eta}
              </div>
              </Space>
              
            </Card>
          </Col>
        </>
    )
}
