import React, { useState } from "react";
import { Button, Comment, Form, Input, List } from "antd";
import { useFirestore } from "reactfire";
import moment from "moment";
const { TextArea } = Input;

export default function CommentsSection({
    commentsData,
    orderData,
    authorName,
    service,
}) {
    const [value, setValue] = useState("");
    const [isSubmitting, setisSubmitting] = useState(false);
    const timeStamp = useFirestore.FieldValue.serverTimestamp();

    const commentsRef = useFirestore()
        .collection("users")
        .doc(orderData?.orderedBy_id || "")
        .collection("orders")
        .doc(orderData.id ? orderData.id : "asd")
        .collection("comments");

    async function handleSubmit() {
        setisSubmitting(true);
        setValue("");
        await commentsRef.add({
            author: authorName,
            datetime: moment(timeStamp).format("MMMM Do YYYY"),
            timeStamp: timeStamp,
            content: value,
        });

        setisSubmitting(false);
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            {commentsData && commentsData.length > 0 && (
                <CommentList comments={commentsData} />
            )}
            <Comment
                content={
                    <Editor
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        submitting={isSubmitting}
                        value={value}
                    />
                }
            />
        </>
    );
}
const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                disabled={submitting}
                type="primary"
            >
                Add Comment
            </Button>
        </Form.Item>
    </>
);

const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(props) => <Comment {...props} />}
    />
);
