import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import { UserContext } from "./context/authContext";
import { useUserData } from "./hooks/auth";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useRouteMatch,
} from "react-router-dom";
import Login from "./pages/Login";
import AdminLogin from "./pages/AdminLogin";
import { UserLayout } from "./pages/user/components/Layout/UserLayout";
import {
    Register,
    UserDashboard,
    UserSamplesForwarding,
    UserPreshipmentInspection,
    UserShipping,
    UserSourcing,
    UserStorage,
    Admin,
} from "./pages";
import { SuspenseWithPerf, AuthCheck } from "reactfire";
import "./App.less";
import UserOrderDetails from "./pages/user/UserOrderDetails";

function App() {
    const userData = useUserData();

    return (
        <>
            <SuspenseWithPerf
                fallback={<div></div>}
                traceId={"loading-app-status"}
            >
                <UserContext.Provider value={userData}>
                    <Router>
                        <Switch>
                            <Route exact path={`/register`}>
                                <Register />
                            </Route>
                            <Route exact path={`/`}>
                                <AuthCheck fallback={<Login />}>
                                    <UserLayout>
                                        <UserDashboard />
                                    </UserLayout>
                                </AuthCheck>
                            </Route>

                            <Route
                                path={`/samples-forwarding`}
                                render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} exact>
                                            <UserLayout>
                                                <UserSamplesForwarding />
                                            </UserLayout>
                                        </Route>
                                        <Route path={`${url}/:orderid`}>
                                            <UserLayout>
                                                <UserOrderDetails service="samplesForwarding" />
                                            </UserLayout>{" "}
                                        </Route>
                                    </>
                                )}
                            ></Route>

                            <Route
                                path={`/pre-shipment-inspection`}
                                render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} exact>
                                            <UserLayout>
                                                <UserPreshipmentInspection />
                                            </UserLayout>
                                        </Route>
                                        <Route path={`${url}/:orderid`}>
                                            <UserLayout>
                                                <UserOrderDetails service="preShipmentInspection" />
                                            </UserLayout>{" "}
                                        </Route>
                                    </>
                                )}
                            ></Route>

                            <Route
                                path={`/shipping`}
                                render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} exact>
                                            <UserLayout>
                                                <UserShipping />
                                            </UserLayout>
                                        </Route>
                                        <Route path={`${url}/:orderid`}>
                                            <UserLayout>
                                                <UserOrderDetails service="shipping" />
                                            </UserLayout>{" "}
                                        </Route>
                                    </>
                                )}
                            ></Route>

                            <Route
                                path={`/storage`}
                                render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} exact>
                                            <UserLayout>
                                                <UserStorage />
                                            </UserLayout>
                                        </Route>
                                        <Route path={`${url}/:orderid`}>
                                            <UserLayout>
                                                <UserOrderDetails service="storage" />
                                            </UserLayout>{" "}
                                        </Route>
                                    </>
                                )}
                            ></Route>

                            <Route
                                path={`/sourcing`}
                                render={({ match: { url } }) => (
                                    <>
                                        <Route path={`${url}/`} exact>
                                            <UserLayout>
                                                <UserSourcing />
                                            </UserLayout>
                                        </Route>
                                        <Route path={`${url}/:orderid`}>
                                            <UserLayout>
                                                <UserOrderDetails service="sourcing" />
                                            </UserLayout>{" "}
                                        </Route>
                                    </>
                                )}
                            ></Route>

                            <Route path="/admin">
                                <AuthCheck fallback={<AdminLogin />}>
                                    <Admin />
                                </AuthCheck>
                            </Route>
                        </Switch>
                    </Router>
                </UserContext.Provider>
            </SuspenseWithPerf>
        </>
    );
}

export default App;
