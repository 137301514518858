import React, { useContext, useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, Button, Breadcrumb } from "antd";
import { UserOutlined, BellOutlined } from "@ant-design/icons";
import { useLocation, useRouteMatch, Link, useHistory } from "react-router-dom";
import { useAuth } from "reactfire";
import { UserContext } from "../../../../context/authContext";

const breadcrumbNameMap = {
  "/admin": "Admin",
  "/admin/samples-forwarding": "Samples Forwarding",
  "/admin/pre-shipment-inspection": "Preshipment Inspection",
  "/admin/pre-shipment-inspection/": "Preshipment Inspection",
  "/admin/shipping": "Shipping",
  "/admin/users": "Users",
};

export function Navbar() {
  const location = useLocation();
  let { url } = useRouteMatch();
  const auth = useAuth();
  const history = useHistory();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const { userClaims } = useContext(UserContext);
  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  // const breadcrumb = () => {
  //   const title = "Dashboard";

  //   return <div>{title}</div>;
  // };

  const logout = () => {
    auth.signOut().then(() => {
      history.push("/admin");
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={() => logout()} target="_blank" rel="noopener noreferrer">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* <span style={{ width: "300px", marginLeft: "15px" }}>
            <Input.Search size="large" placeholder="Search" />
          </span> */}
      <div
        style={{
          flex: "1 1 0%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        {/* <Breadcrumb>
          {breadcrumbs.map((data, index) => (
            <Breadcrumb.Item key={index}>{data.split("-")}</Breadcrumb.Item>
          ))}
        </Breadcrumb> */}
      </div>
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          {/* <Input.Search size="large" placeholder="Search" /> */}
        </div>

        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          <BellOutlined style={{ fontSize: "24px" }} />
        </div>

        {/* <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px",
          }}
        >
          {url === "/users" ? (
            <Link to="/admin">
              <Button> Switch to Admin</Button>
            </Link>
          ) : (
            <Link to="/users">
              <Button> Switch to Users</Button>
            </Link>
          )}
        </div> */}
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "12px",
          }}
        >
          <Dropdown overlay={menu}>
            <div>
              <Avatar icon={<UserOutlined />} />{" "}
              <span style={{ paddingLeft: "5px" }}>{userClaims.agent? 'Agent': 'Admin'}</span>
            </div>
          </Dropdown>
        </div>
        {/* <Input.Search size="large" placeholder="Search" /> */}
      </div>
    </>
  );
}
