import React, { useState } from "react";
import {
    Table,
    Col,
    Space,
    Tag,
    Card,
    Row,
    Modal,
    Button,
    Form,
    Input,
} from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const validateMessages = {
    required: "${label} is required!",
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 6 },
        sm: { span: 20, offset: 6 },
    },
};

function AddCategoryForm(props) {
    const { edit, orderData, editData, setIsModalVisible, orderid } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const firestore = useFirestore();
    const timestamp = useFirestore.FieldValue.serverTimestamp();

    const addCategory = async (values) => {
        await firestore
            .collection("categories")
            .add({
                name: values.name,
                dateAdded: timestamp,
                subcategories: values.subcategories,
            })
            .then(async (docRef) => {
                await values.subcategories.map((value) => {
                    firestore.collection("subcategories").add({
                        category_id: docRef.id,
                        categoryName: values.name,
                        name: value,
                        dateAdded: timestamp,
                    });
                });
            });
    };
    const onFinish = async (values) => {
        await setLoading(true);
        await addCategory(values);
        await form.resetFields();
        await setLoading(false);
        await setIsModalVisible(false);
    };
    return (
        <Form
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
        >
            <Form.Item
                name={"name"}
                label="Category Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.List
                name="subcategories"
                // rules={[
                //   {
                //     validator: async (_, names) => {
                //       if (!names || names.length < 2) {
                //         return Promise.reject(new Error("At least 2 passengers"));
                //       }
                //     },
                //   },
                // ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0
                                    ? formItemLayout
                                    : formItemLayoutWithOutLabel)}
                                label={index === 0 ? "Subcategories" : ""}
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message:
                                                "Please input subcategory or delete this field.",
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        placeholder="Subcategory"
                                        style={{ width: "60%" }}
                                    />
                                </Form.Item>
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item wrapperCol={{ offset: 6 }}>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "60%" }}
                                icon={<PlusOutlined />}
                            >
                                Add Subcategory
                            </Button>
                            {/* <Form.ErrorList errors={errors} /> */}
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}

export function AdminCategories(props) {
    const firestore = useFirestore();
    const [editStatus, setEditStatus] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editData, setEditData] = useState();
    const categoriesRef = useFirestore().collection("categories");

    const { data: categoriesData, status: categoriesStatus } =
        useFirestoreCollectionData(categoriesRef, {
            idField: "id",
        });

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const deleteCategory = (id) => {
        if (window.confirm("Delete category?"))
            firestore.collection("categories").doc(id).delete();
    };

    const renderText = (text, record) => <a>{text}</a>;

    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;

    const actions = (text, record) => (
        <Space>
            <a onClick={() => deleteCategory(record.id)}>Delete</a>
        </Space>
    );

    const redirect = () => {};

    const render = (text, record) => (
        <Link
            to={{
                pathname: `/admin/categories/${record.id}`,
                state: { record },
            }}
        >
            {text}
        </Link>
        // <a onClick={() => history.push(`/admin/${redirect()}/${record.id}`)}>
        //   {text}
        // </a>
    );
    const columns = [
        {
            title: "Category Name",
            dataIndex: "name",
            render: render,
        },

        {
            title: "Date Added",
            dataIndex: "dateAdded",
            sorter: (a, b) => a.dateAssigned - b.dateAssigned,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Actions",
            key: "action",
            render: actions,
        },
    ];

    return (
        <>
            <Row>
                <Col xxl={24}>
                    <Card
                        title="Categories"
                        bodyStyle={{ padding: "0" }}
                        bordered
                        extra={
                            <Button
                                onClick={() => {
                                    setIsModalVisible(true);
                                    setEditStatus(false);
                                }}
                            >
                                Add Category
                            </Button>
                        }
                    >
                        <Table
                            bordered
                            scroll={{ x: true }}
                            columns={columns}
                            dataSource={categoriesData}
                            loading={categoriesRef === "loading" ? true : false}
                        />
                    </Card>
                </Col>

                <Modal
                    title={editStatus ? "Edit" : "Add Category"}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <AddCategoryForm
                        edit={editStatus}
                        editData={editData}
                        setIsModalVisible={setIsModalVisible}
                    />
                </Modal>
            </Row>
        </>
    );
}
