import React from "react";
import { Table, Button, Tag, Space } from "antd";

export default function Invoicetable(props) {
  const {
    invoiceData,
    setIsModalVisible,
    setEditStatus,
    setEditData,
    confirmPayment,
    deleteInvoice,
  } = props;

  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const text = (text, data) => (
    <a onClick={() => openNewTab(data.invoiceFile)}>{text}</a>
  );
  const price = (text) => <a>${text}</a>;
  const date = (date) => <a>{date && date.toDate().toDateString()}</a>;

  const editInvoice = async (editData) => {
    if (editData.paymentStatus === "confirmationPending") {
      window.alert("Can't edit paid or pending invoice");
      return;
    }
    await setIsModalVisible(true);
    await setEditStatus(true);
    await setEditData(editData);
  };

  const confirm = (data) => {
    if (window.confirm("Confirm payment and set status to paid?")) {
      confirmPayment(data);
    }
  };

  const deleteInvoiceNumber = (data) => {
    if (data.paymentStatus === "notPaid") {
      if (window.confirm("Delete Invoice?")) {
        deleteInvoice(data);
      }
    } else window.alert("Can't delete paid or pending invoice");
  };

  const invoiceActions = (text) =>
    text.paymentStatus === "paid" ? (
      <div></div>
    ) : (
      <Space>
        <a onClick={() => editInvoice(text)}>Edit</a>
        <a onClick={() => deleteInvoiceNumber(text)}>Delete</a>
        <Button
          danger={true}
          style={{ background: "#449D44", color: "white", border: "none" }}
          onClick={() => confirm(text)}
        >
          Confirm Payment(s)
        </Button>
      </Space>
    );

  const paymentTag = (tag) => (
    <Tag
      color={
        tag === "paid"
          ? "green"
          : tag === "notPaid"
          ? "volcano"
          : tag === "confirmationPending"
          ? "processing"
          : "volcano"
      }
    >
      {tag === "paid"
        ? "Paid"
        : tag === "notPaid"
        ? "Not Paid"
        : tag === "confirmationPending"
        ? "Confirmation Pending"
        : ""}
    </Tag>
  );

  const invoiceColumns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "name",
      render: text,
    },
    {
      title: "Date Issued",
      key: "dateAdded",
      dataIndex: "dateIssued",
      sorter: (a, b) => a.dateIssued - b.dateIssued,
      defaultSortOrder: "descend",
      render: date,
    },

    {
      title: "Amount to Pay",
      dataIndex: "invoiceAmount",
      key: "name",
      render: price,
    },

    {
      title: "Payment Status",
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: paymentTag,
    },

    {
      title: "Actions",
      key: "actions",
      render: invoiceActions,
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        bordered
        columns={invoiceColumns}
        dataSource={invoiceData}
        pagination={false}
      />
    </>
  );
}
