import React, { useContext } from "react";
import { Row, Col, Spin, Card, Statistic } from "antd";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { AdminOrders } from "./AdminOrders";
import { UserContext } from "../../context/authContext";
import { useHistory } from "react-router-dom";

export function AdminDashboard(props) {
  const statsRef = useFirestore().collection("stats").doc("stats");
  const { data: statsData, status: statsDataStatus } =
    useFirestoreDocData(statsRef);
    const { userClaims } = useContext(UserContext);
  const history = useHistory();
  if(userClaims.agent){
    history.push('/admin/samples-forwarding');
  }

  if (statsDataStatus === "loading") {
    return (
      <div className="loader">
        <Spin></Spin>
      </div>
    );
  }
  return (
    <>
      {" "}
      <Row gutter={[16, 16]}>
        <Col span={6} xs={24} xl={8}>
          <Card bordered>
            <Statistic
              title="Total Sales"
              value={statsData?.totalSales}
              prefix="$"
            ></Statistic>
          </Card>
        </Col>
        <Col span={6} xs={24} xl={8}>
          <Card>
            <Statistic
              value={statsData?.totalCustomers}
              title="Total Customers"
            >
              {" "}
            </Statistic>
          </Card>
        </Col>
        <Col span={6} xs={24} xl={8}>
          <Card>
            <Statistic
              value={statsData?.activeOrders}
              title="Total Active Orders"
            ></Statistic>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6} xs={24} xl={24} xxl={8}>
          <Card
            title={"Samples Forwarding Orders"}
            bodyStyle={{ padding: "0" }}
            bordered
            style={{ border: "1px solid lightgrey" }}
          >
            <AdminOrders service="samplesForwarding" />
          </Card>
        </Col>

        <Col span={6} xs={24} xl={24} xxl={8}>
          <Card
            title={"Preshipment Inspection Orders"}
            bodyStyle={{ padding: "0" }}
            bordered
            style={{ border: "1px solid lightgrey" }}
          >
            <AdminOrders service="preShipmentInspection" />
          </Card>
        </Col>

        <Col span={6} xs={24} xl={24} xxl={8}>
          <Card
            title={"Shipping Orders"}
            bodyStyle={{ padding: "0" }}
            bordered
            style={{ border: "1px solid lightgrey" }}
          >
            <AdminOrders service="shipping" />
          </Card>
        </Col>
      </Row>
    </>
  );
}
