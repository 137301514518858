import React, { useState } from "react";
import { Form, Input, Select, Button, Upload, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage } from "reactfire";
import { countryCodes } from "../../../../utils/countryCodes";
import { Plans } from "../../components";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const { Option } = Select;

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] instanceof Object
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    China +86
  </Form.Item>
);

export function PreshipmentInspectionForm(props) {
  const [form] = Form.useForm();
  const { userData, edit, editData, setIsModalVisible, plan } = props;
  const uid = userData.user_id;
  const [uploads, setUploads] = useState([]);
  const [formValues, setformValues] = useState([]);
  const [contractUpload, setContractUpload] = useState();
  const [loading, setLoading] = useState();
  // const [plan, setPlan] = useState("");
  const fieldValue = useFirestore.FieldValue;
  const order_id = userData.services_ids.preShipmentInspection;
  const storage = useStorage();
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const firestore = useFirestore();

  form.setFieldsValue({ prefix: "+86" });

  console.log(plan);

  const showModal = (values) => {
    setformValues(values);

    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    await onFinish(formValues);
    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const orderRef = useFirestore()
    .collection("users")
    .doc(uid)
    .collection("orders");

  const userDetailsRef = useFirestore().collection("users").doc(uid);

  const handleContractUpload = (e) => {
    setContractUpload(e.file.originFileObj);
  };

  const handleUpload = (e) => {
    console.log(e.file.originFileObj);
    setUploads(e.file.originFileObj);
  };

  const uploadContract = async (e) => {
    console.log("uploading...");
    let downloadURL = "";
    let fileName = Date.now().toString() + "_" + contractUpload.name;

    await storage
      .ref(`fileUploads/${userData.user_id}` + fileName)
      .put(contractUpload)
      .then((snapshot) => {
        downloadURL = snapshot.ref.getDownloadURL();
        // return snapshot.ref.getDownloadURL();
      })

      .catch((error) => {
        console.log(error);
      });
    return downloadURL;
  };

  const uploadFile = async (e) => {
    console.log("uploading...");
    let downloadURL = "";
    let fileName = Date.now().toString() + "_" + uploads.name;

    await storage
      .ref(`fileUploads/${userData.user_id}` + fileName)
      .put(uploads)
      .then((snapshot) => {
        downloadURL = snapshot.ref.getDownloadURL();
        // return snapshot.ref.getDownloadURL();
      })

      .catch((error) => {
        console.log(error);
      });
    return downloadURL;
  };

  const generateOrder_id = async () => {
    let order_id = "INS210001";
    await firestore
      .collectionGroup("orders")
      .where("serviceType", "==", "preShipmentInspection")
      .orderBy("dateOrdered", "desc")
      .limit(1)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            console.log(doc.id, " => ", doc.data());
            order_id = incrementOrder_id(doc.data().order_id);
          }
        });
      });
    return order_id;
  };

  const incrementOrder_id = (order_id) => {
    const splitOrder_id = order_id.split(/([0-9]+)/);
    const incremented_id = parseInt(splitOrder_id[1]) + 1;
    return splitOrder_id[0] + incremented_id;
  };

  const onFinish = async (values) => {
    setLoading(true);
    let orderDetails = await clean(values);
    let newOrder_id = await generateOrder_id();
    const url = await uploadFile();
    let contractURL = "";
    if (contractUpload) {
      contractURL = await uploadContract();
    }
    await orderRef
      .add({
        order_id: newOrder_id,
        orderedBy_id: uid,
        orderedBy_name: userData.firstName + " " + userData.lastName,
        orderedBy_email: userData.email,
        dateOrdered: fieldValue.serverTimestamp(),
        orderStatus: "invoicePending",
        serviceType: "preShipmentInspection",
        pictureLink: url,
        contractLink: contractURL,
        contractFileName: contractUpload ? contractUpload.name : "",
        pictureFileName: uploads.name,
        orderDetails,
        isArchived: false,
        plan: plan,
      })
      .then(async (docRef) => {
        if (edit) {
          await orderRef.doc(editData.id).update({
            orderStatus: "edited",
          });
        }
        await userDetailsRef.update(
          {
            services_ids: {
              ...userData.services_ids,
              preShipmentInspection: docRef.id,
            },
          },
          { merge: true }
        );
        if (edit) setIsModalVisible(false);
        await window.alert("Form Submitted!");
        await form.resetFields();
        await setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (edit && editData) {
    form.setFieldsValue({
      productName: editData.orderDetails.productName,
      factoryAddress: editData.orderDetails.factoryAddress,
      productDetails: editData.orderDetails.productDetails,
      supplierContact: editData.orderDetails.supplierContact,
    });
  }
  return (
    <>
      <Form
        form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={"productName"}
          label="Product Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="picture"
          label="Picture"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload onChange={handleUpload}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="supplierContact"
          label="Factory Contact Info"
          rules={[
            {
              required: true,
              message: "Please input the Supplier Contact!",
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          name={"factoryAddress"}
          label="Factory Address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={"productDetails"}
          label="Product Details"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item name="orderDetails" label="Order Details (Contract Upload)">
          <Upload onChange={handleContractUpload}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name={"companyDetails"}
          label="Invoice - Company Details:"
          
        >
          <Input.TextArea placeholder="Input the details of the company for your invoice. Leave it blank if the invoice will be personal only" />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading ? true : false}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>

      {/* <Modal
        title="Select your plan"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
      >
        <Plans plan={plan} setPlan={setPlan} />
      </Modal> */}
    </>
  );
}
