import React, { useContext, useState } from "react";
import { Button, Card, Descriptions, Form, Input, Select, Space } from "antd";

import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
} from "reactfire";
import { UserContext } from "../../../context/authContext";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";

// const tag = (tag) => (
//   <Tag
//     color={
//       tag === "invoicePending"
//         ? "volcano"
//         : tag === "invoiceIssued"
//         ? "yellow"
//         : tag === "invoicePaid"
//         ? "green"
//         : tag === "inspectionScheduled"
//         ? "blue"
//         : "processing"
//     }
//   >
//     {tag === "invoicePending"
//       ? "Invoice Pending"
//       : tag === "invoiceIssued"
//       ? "Invoice(s) Issued"
//       : tag === "invoicePaid"
//       ? "Invoice(s) Paid"
//       : tag === "inspectionScheduled"
//       ? "Inspection Scheduled"
//       : "Done"}
//   </Tag>
// );

const { Option } = Select;

export function UserInfoCard(props) {
    const { orderData, tag } = props;
    const userid = orderData?.orderedBy_id;
    const orderid = orderData?.id;
    const userRef = useFirestore().collection("users").doc(userid);

    const userOrderRef = useFirestore()
        .collection("users")
        .doc(userid)
        .collection("orders")
        .doc(orderid);

    const agentsRef = useFirestore()
        .collection("admins")
        .where("role", "==", "agent");
    const { userClaims } = useContext(UserContext);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState(
        orderData.order_idExtension || "",
    );

    const { data: userData, status: userDataStatus } = useFirestoreDocData(
        userRef,
        {
            idField: "id",
        },
    );

    const { data: agentsData, status: agentsDataStatus } =
        useFirestoreCollectionData(agentsRef, {
            idField: "id",
        });

    const assignAgent = (value, event) => {
        userRef.collection("orders").doc(orderData.id).update({
            assignedTo: value,
            assignedToName: event.children,
        });
    };

    const selectAgent = (
        <Select
            onSelect={assignAgent}
            defaultValue={orderData?.assignedToName || null}
            placeholder="Select an agent to assign to"
        >
            {agentsData?.map((agent, index) => (
                <Option key={index} value={agent.id}>
                    {agent.name}
                </Option>
            ))}
            <Option value="">None</Option>
        </Select>
    );

    function toggleInput() {
        setIsInputVisible(!isInputVisible);
    }

    function handleChange(e) {
        setInputValue(e.target.value);
    }

    function handleSubmit() {
        userOrderRef.update({ order_idExtension: inputValue });
        toggleInput();
    }
    console.log(orderData?.dateOrdered);

    const date = moment(orderData?.dateOrdered.toDate()).format("MMMM Do YYYY");

    return (
        <>
            <Card
                title={
                    <Space style={{ maxHeight: "25px" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            Order #{" "}
                            {orderData &&
                                `${orderData.order_id}${
                                    orderData?.order_idExtension || ""
                                }`}
                        </div>
                        <AiFillEdit
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                            }}
                            onClick={toggleInput}
                        />
                        {isInputVisible ? (
                            <Space
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                                align="center"
                            >
                                <Input
                                    onChange={handleChange}
                                    value={inputValue}
                                ></Input>
                                <Button onClick={handleSubmit} type="primary">
                                    Save
                                </Button>
                            </Space>
                        ) : (
                            <></>
                        )}
                    </Space>
                }
                extra={userClaims.agent ? "" : selectAgent}
            >
                <Descriptions>
                    <Descriptions.Item>
                        Customer Name: {orderData?.orderedBy_name || ""}
                    </Descriptions.Item>

                    {tag ? (
                        <Descriptions.Item>
                            Order Status: {tag(orderData?.orderStatus) || ""}
                        </Descriptions.Item>
                    ) : (
                        ""
                    )}
                    <Descriptions.Item>
                        Reference Code: {userData?.referenceCode || ""}
                    </Descriptions.Item>

                    <Descriptions.Item>Date Ordered: {date}</Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
