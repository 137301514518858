import React, { useEffect, useState } from "react";
import { Form, Input, Upload, Button, Space, InputNumber, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage, useAuth, useFirebaseApp } from "reactfire";
import "firebase/functions";

const { Option } = Select;

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: "${label} is required!",
};

export default function AdminAddAdminForm(props) {
    const { edit, orderData, editData, setIsModalVisible, orderid } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const storage = useStorage();
    const firestore = useFirestore();
    const timestamp = useFirestore.FieldValue.serverTimestamp();
    const [uploads, setUploads] = useState([]);
    const firebase = useFirebaseApp();

    const userInvoiceRef = firestore
        .collection("users")
        .doc(orderData?.orderedBy_id)
        .collection("orders")
        .doc(orderid)
        .collection("invoices");

    const assignAdmin = async (values) => {
        setLoading(true);
        const addAdminRole = firebase.functions().httpsCallable("addAdminRole");

        await addAdminRole(values)
            .then(async (result) => {
                // Read result of the Cloud Function.
                // await firestore.collection("admins").doc(uid).set({
                //   name: values.adminName,
                //   email: values.email,
                //   dateAssigned: timestamp,
                //   admin_id: uid,
                // });
                window.alert("Admin Assignment Successful");
            })
            .catch((error) => {
                // Getting the Error details.

                var code = error.code;
                var message = error.message;
                var details = error.details;
                window.alert(message);
                // ...
            });
        // await auth
        //   .createUserWithEmailAndPassword(values.email, values.password)
        //   .then(async (userCredential) => {
        //     var user = userCredential.user;
        // await firestore.collection("admins").doc(user.uid).set({
        //   name: values.adminName,
        //   email: values.email,
        //   dateAssigned: timestamp,
        //   admin_id: user.uid,
        // });

        //     window.alert("Admin Assignment Successful");
        //     setLoading(false);
        //   })
        //   .catch((error) => {
        //     setLoading(false);
        //     form.resetFields();
        //     var errorCode = error.code;
        //     window.alert(error.message);
        //     var errorMessage = error.message;
        //     console.log(errorCode, errorMessage);
        //   });
    };

    const onFinish = async (values) => {
        await assignAdmin(values);
        await form.resetFields();
        await setLoading(false);
        await setIsModalVisible(false);
    };
    if (edit && editData) {
        // console.log(editData);
    }
    return (
        <>
            <Form
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"name"}
                    label="Admin Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input your email!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your password!",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name={"role"}
                    label="Role"
                >
                    <Select>
                        <Option value="admin">Admin</Option>
                        <Option value="superadmin">Superadmin</Option>
                        <Option value="agent">Agent</Option>
                    </Select>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 8, offset: 0 }}>
                    <Space>
                        {" "}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading ? true : false}
                        >
                            Assign Admin
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
}
