import React from "react";
import { Descriptions, Card } from "antd";

export default function ShippingDescriptions({ orderData }) {
    const orderDetails = orderData?.orderDetails;
    return (
        <>
            <Card
                bordered
                style={{ marginTop: "20px" }}
                bodyStyle={{ padding: 0 }}
                title={"Order Details"}
            >
                <Descriptions bordered>
                    <Descriptions.Item label="Goods Description">
                        {orderDetails?.goodsDescription}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product Photo">
                        <a
                            href={orderData?.pictureLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {orderData?.pictureFilename}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label=" HS Code/Tariff Code">
                        {orderDetails?.HSCode}
                    </Descriptions.Item>
                    <Descriptions.Item label="Departure Address">
                        {orderDetails?.departureAddress}
                    </Descriptions.Item>
                    <Descriptions.Item label="Delivery Address">
                        {orderDetails?.deliveryAddress}
                    </Descriptions.Item>

                    <Descriptions.Item label="Supplier Contact">
                        {orderDetails?.supplierContact}
                    </Descriptions.Item>
                    <Descriptions.Item label="Incoterms with the supplier(EXW or FOB)">
                        {orderDetails?.incoterms}
                    </Descriptions.Item>
                    <Descriptions.Item label="Preferred Transit type">
                        {orderDetails?.transitType}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estimated dimensions of each Carton">
                        {orderDetails?.cartonWidth} x{" "}
                        {orderDetails?.cartonLength} x{" "}
                        {orderDetails?.cartonHeight}{" "}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estimated Cubic Meters (CBM)">
                        {orderDetails?.estimatedCubicMeters.toFixed(2)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estimated Weight of each Carton (kg)">
                        {orderDetails?.estimatedWeight}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estimated Total Cartons">
                        {orderDetails?.estimatedTotalCartons}
                    </Descriptions.Item>
                    <Descriptions.Item label="Estimated Total Weight (kg)">
                        {orderDetails?.estimatedTotalWeight}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shipment Value (USD)">
                        {orderDetails?.shipmentValue}
                    </Descriptions.Item>
                    <Descriptions.Item label="Expected Ready-to-ship date">
                        {orderDetails?.readyToShipDate}
                    </Descriptions.Item>
                    <Descriptions.Item label="Container Van Shipment">
                        {orderDetails?.containerShipment}
                    </Descriptions.Item>
                    <Descriptions.Item label="Notes">
                        {orderDetails?.notes}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </>
    );
}
