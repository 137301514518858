import React, { useState } from "react";
import {
    Form,
    Input,
    Select,
    Space,
    Button,
    Upload,
    DatePicker,
    InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useFirestore, useStorage } from "reactfire";

const layout = {
    labelCol: {
        span: 14,
    },
    wrapperCol: {
        span: 10,
    },
};

const { Option } = Select;

const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

function clean(obj) {
    for (var propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] instanceof Object
        ) {
            delete obj[propName];
        }
        if (obj["readyToShipDate"]) {
            let date = Date(obj["readyToShipDate"]);
            obj["readyToShipDate"] = date;
        }
        // obj[propName] = obj[propName].toDate().toString();
    }
    return obj;
}

export function SourcingForm(props) {
    const { userData, edit, editData, setIsModalVisible } = props;
    const [form] = Form.useForm();
    // const [width, setWidth] = useState(0);
    // const [length, setLength] = useState(0);
    // const [height, setHeight] = useState(0);
    const uid = userData.user_id;
    const [uploads, setUploads] = useState();
    const [sketchUpload, setSketchUpload] = useState();
    const [loading, setLoading] = useState();
    const fieldValue = useFirestore.FieldValue;
    const order_id = userData.services_ids.preShipmentInspection;
    const storage = useStorage();
    const firestore = useFirestore();

    form.setFieldsValue({ transitType: "sea" });
    const orderRef = useFirestore()
        .collection("users")
        .doc(uid)
        .collection("orders");

    const userDetailsRef = useFirestore().collection("users").doc(uid);

    const handleSketchUpload = (e) => {
        console.log(e.file.originFileObj);
        setSketchUpload(e.file.originFileObj);
    };

    const handleUpload = (e) => {
        console.log(e.file.originFileObj);
        setUploads(e.file.originFileObj);
    };

    const uploadSketch = async (e) => {
        console.log("uploading...");
        let downloadURL = "";
        if (uploads) {
            let fileName = Date.now().toString() + "_" + uploads.name;

            await storage
                .ref(`fileUploads/${userData.user_id}` + fileName)
                .put(uploads)
                .then((snapshot) => {
                    downloadURL = snapshot.ref.getDownloadURL();
                    // return snapshot.ref.getDownloadURL();
                })

                .catch((error) => {
                    console.log(error);
                });
        }

        return downloadURL;
    };

    const uploadFile = async (e) => {
        console.log("uploading...");
        let downloadURL = "";
        if (uploads) {
            let fileName = Date.now().toString() + "_" + uploads.name;

            await storage
                .ref(`fileUploads/${userData.user_id}` + fileName)
                .put(uploads)
                .then((snapshot) => {
                    downloadURL = snapshot.ref.getDownloadURL();
                    // return snapshot.ref.getDownloadURL();
                })

                .catch((error) => {
                    console.log(error);
                });
        }

        return downloadURL;
    };

    const generateOrder_id = async () => {
        let order_id = "SOU210001";
        await firestore
            .collectionGroup("orders")
            .where("serviceType", "==", "sourcing")
            .orderBy("dateOrdered", "desc")
            .limit(1)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    if (doc.exists) {
                        console.log(doc.id, " => ", doc.data());
                        order_id = incrementOrder_id(doc.data().order_id);
                    }
                });
            });
        return order_id;
    };

    const incrementOrder_id = (order_id) => {
        const splitOrder_id = order_id.split(/([0-9]+)/);
        const incremented_id = parseInt(splitOrder_id[1]) + 1;
        return splitOrder_id[0] + incremented_id;
    };

    const onFinish = async (values) => {
        setLoading(true);
        let orderDetails = await clean(values);
        let newOrder_id = await generateOrder_id();
        let url = "";
        let sketchURL = "";
        if (uploads) url = await uploadFile();
        if (sketchUpload) sketchURL = await uploadSketch();
        await orderRef
            .add({
                order_id: newOrder_id,
                orderedBy_id: uid,
                orderedBy_name: userData.firstName + " " + userData.lastName,
                orderedBy_email: userData.email,
                dateOrdered: fieldValue.serverTimestamp(),
                orderStatus: "quotationPending",
                serviceType: "sourcing",
                pictureLink: url !== "" ? url : "",
                sketchLink: sketchURL !== "" ? sketchURL : "",
                sketchFilename: uploads ? uploads.name : "",
                pictureFilename: sketchUpload ? sketchUpload.name : "",
                orderDetails,
                isArchived: false,
            })
            .then(async (docRef) => {
                if (edit) {
                    await orderRef.doc(editData.id).update({
                        orderStatus: "edited",
                    });
                }
                await userDetailsRef.set(
                    {
                        services_ids: {
                            sourcing: docRef.id,
                        },
                    },
                    { merge: true },
                );
                window.alert("Form Submitted!");
                if (edit) setIsModalVisible(false);
                setLoading(false);
                form.resetFields();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (edit && editData) {
        const editDetails = editData.orderDetails;
        form.setFieldsValue({
            productName: editDetails?.productName,
            orderQuantity: editDetails?.orderQuantity,
            customizeRequirements: editDetails?.customizeRequirements,
            others: editDetails?.others,
            // readyToShipDate: editData?.readyToShipDate,
        });
    }

    return (
        <>
            <Form
                form={form}
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={"productName"}
                    label="Product Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="productPicture"
                    label="Product Photo (or similar photo)"
                >
                    <Upload onChange={handleUpload}>
                        <Button icon={<UploadOutlined />}>
                            Click to upload
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="sketchDiagram"
                    label="Customize-detailed sketch diagram (with measurement and weight if possible)"
                >
                    <Upload onChange={handleSketchUpload}>
                        <Button icon={<UploadOutlined />}>
                            Click to upload
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name={"orderQuantity"}
                    label="Order Quantity"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                    name={"customizeRequirements"}
                    label="Customize Requirements"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"referenceListingLink"}
                    label="Reference Listing Link"
                >
                    <Input />
                </Form.Item>

                <Form.Item name={"others"} label="Others">
                    <Input />
                </Form.Item>

                <Form.Item
                    wrapperCol={{ ...layout.wrapperCol, offset: 14 }}
                    loading={loading ? true : false}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
