import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../context/authContext";
import { ServiceTag } from "../../../../components/ServiceTag";

export function DashboardTable(props) {
    const history = useHistory();
    const { service } = props;
    const { user, userData } = useContext(UserContext);

    const firestore = useFirestore();

    const ordersRef = useFirestore()
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .where("serviceType", "==", service)
        .limit(5);

    const { data: orderData, status: orderStatus } = useFirestoreCollectionData(
        ordersRef,
        {
            idField: "id",
        },
    );

    const redirect = () => {
        if (service === "samplesForwarding") return "samples-forwarding";
        if (service === "preShipmentInspection")
            return "pre-shipment-inspection";
        return "shipping";
    };
    const swapOrder = async (data) => {
        if (service !== "samplesForwarding") {
            await firestore
                .collection("users")
                .doc(userData.user_id)
                .set(
                    {
                        services_ids: {
                            [service]: data.id,
                        },
                    },
                    { merge: "true" },
                );
        }

        const link = redirect();
        history.push(`/${link}`);
    };

    //   useEffect(() => {
    //     const unsubscribe = firestore
    //       .collection("users")
    //       .doc(user.uid)
    //       .collection("orders")
    //       .where("serviceType", "==", service)
    //       .onSnapshot((querySnapshot) => {
    //         const data = querySnapshot.docs.map((doc) => doc.data());
    //         setOrders(data);
    //       });
    //     return unsubscribe;
    //   }, [firestore, orders, service, user.uid, userData.id]);

    const text = (text, data) => <a onClick={() => swapOrder(data)}>{text}</a>;
    const date = (date) => <a>{date && date.toDate().toDateString()}</a>;
    const tag = (tag) => <ServiceTag service={service} tag={tag}></ServiceTag>;

    const columns = [
        {
            title: "Order Number",
            dataIndex: "order_id",
            key: "name",
            render: text,
        },
        {
            title: "Date Ordered",
            key: "dateAdded",
            dataIndex: "dateOrdered",
            sorter: (a, b) => a.dateOrdered - b.dateOrdered,
            defaultSortOrder: "descend",
            render: date,
        },

        {
            title: "Order Status",
            dataIndex: "orderStatus",
            key: "name",
            render: tag,
        },
    ];
    return (
        <>
            {" "}
            <Table
                scroll={{ x: true }}
                pagination={{
                    defaultPageSize: 5,
                    pageSize: 5,
                    total: orderData?.length,
                }}
                columns={columns}
                loading={orderStatus === "loading" ? true : false}
                dataSource={orderData}
            ></Table>
        </>
    );
}
